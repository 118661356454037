// link to this in account dropdown, under setting/manage data
import { useRef, useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { useLocation } from 'react-router-dom';

import { todaysDate, formatDateDashes } from '../../lib/helpers/formattingHelpers.js';
import { formatServerData, formatReactableColumns, ReactTableBasic } from '../../lib/reactableBuilder.js';

// context
import { authContext } from '../authContext.js'

// bootstrap
import { Tab, Button, Nav, Modal, Spinner } from 'react-bootstrap';

// icons
import x from '../../svg/x.svg';
import download from '../../svg/download.svg';

// downloading data as CSV
import { CSVLink } from 'react-csv';

export default function ManageData(props) {
  const auth = useContext(authContext)

  const [dataType, setDataType] = useState("cashflow")
  const [dataLoaded, setDataLoaded] = useState(false)
  const location = useLocation()

  const [tableView, setTableView] = useState(false)

  const [processingCashflow, setProcessingCashflow] = useState(false)
  const [processingUtility, setProcessingUtility] = useState(false)
  const [processingInvestments, setProcessingInvestments] = useState(false)

  const [cashflowFiles, setCashflowFiles] = useState(null)
  const [utilityFiles, setUtilityFiles] = useState(null)
  const [investmentData, setInvestmentData] = useState(null)

  const [currentData, setCurrentData] = useState(null) 
  const [currentDataColumns, setCurrentDataColumns] = useState(null)
  const [currentAmountRTHeaders, setCurrentRTAmountHeaders] = useState([])
  const [currentDateRTHeaders, setCurrentRTDateHeaders] = useState([])
  
  const [currentFile, setCurrentFile] = useState(null)

  const [modalShow, setModalShow] = useState(false);
  const [pageSource, setPageSource] = useState("cashflow")

  //------------------------------------------------------------------------------------------------------------------
  // Component Hooks
  //------------------------------------------------------------------------------------------------------------------

  // necessary to update the data type to what should be uploaded
  useEffect(() => {
    if (location.state)
    {
      setDataType(location.state.pageSource)
      setPageSource(location.state.pageSource)
    }
    else if (props)
    {
      setDataType(props.pageSource)
      setPageSource(props.pageSource)
    }
    auth.setTourStatus("Hidden")
  }, [])

  // pre-loading data
  useEffect(() => {
    if (auth.isContextSecured)
    {
      let url_query
      let body = { "email": auth.email, "building_short_name": auth.selectedBuilding }

      console.log("fetching rf studies summary")
      url_query = `${process.env.REACT_APP_FLASK_IP}/data/rfstudies`
      axios.defaults.withCredentials = true
      axios.post(url_query, body)
        .then(function (resp) {
          let dataFromServer = resp.data
          if (!dataFromServer || dataFromServer["error"]) return
          if (dataFromServer.length === 0) return

          setCashflowFiles(dataFromServer)
        })
        .then(() => {
          console.log("fetching active utilities")
          url_query = `${process.env.REACT_APP_FLASK_IP}/data/utilities`
          axios.defaults.withCredentials = true
          axios.post(url_query, body)
            .then(function (resp) {
              let dataFromServer = resp.data
              if (!dataFromServer || dataFromServer["error"]) return
              if (dataFromServer.length === 0) return //unsure of this one, do we really want to return if no utility data?

              setUtilityFiles(dataFromServer)
            })
        })
        .then(() => {
          console.log("fetching investments")
          url_query = `${process.env.REACT_APP_FLASK_IP}/data/investments`
          axios.defaults.withCredentials = true
          axios.post(url_query, body)
            .then(function (resp) {
              let dataFromServer = resp.data
              if (!dataFromServer || dataFromServer["error"]) return
              if (dataFromServer.length === 0 || dataFromServer["amount"].length === 0) return //unsure of this one, do we really want to return if no utility data?

              setInvestmentData({ rows: dataFromServer["amount"].length }) //TODO: figure out proper data to be put here
            })
        })
        .then(() => {
          setDataLoaded(true)
        })
    }
  }, [auth.isContextSecured]);

  // updates data after upload (after a given amount of time)
  useEffect(() => {
    if (processingCashflow)
    {
      let url_query = `${process.env.REACT_APP_FLASK_IP}/data/rfstudies`
      let body = { "email": auth.email, "building_short_name": auth.selectedBuilding }
      setTimeout(function () {
        console.log("fetching rf studies summary")
        axios.defaults.withCredentials = true
        axios.post(url_query, body)
          .then(function (resp) {
            let dataFromServer = resp.data
            if (!dataFromServer || dataFromServer["error"]) return
            if (dataFromServer.length === 0) return

            setProcessingCashflow(false)
            setCashflowFiles(dataFromServer)
          })
      }, 140000)
    }
  }, [processingCashflow])

  useEffect(() => {
    if (processingUtility)
    {
      let url_query = `${process.env.REACT_APP_FLASK_IP}/data/utilities`
      let body = { "email": auth.email, "building_short_name": auth.selectedBuilding }
      setTimeout(function () {
        console.log("fetching active utilities")
        axios.defaults.withCredentials = true
        axios.post(url_query, body)
          .then(function (resp) {
            //console.log(resp.data)
            let dataFromServer = resp.data

            if (!dataFromServer || dataFromServer.length === 0 || dataFromServer["error"]) return //unsure of this one, do we really want to return if no utility data?

            setProcessingUtility(false)
            setUtilityFiles(dataFromServer)
          })
      }, 10000)
    }
  }, [processingUtility])

  useEffect(() => {
    if (processingInvestments)
    {
      let url_query = `${process.env.REACT_APP_FLASK_IP}/data/investments`
      let body = { "email": auth.email, "building_short_name": auth.selectedBuilding }
      setTimeout(function () {
        console.log("fetching investments")
        axios.defaults.withCredentials = true
        axios.post(url_query, body)
          .then(function (resp) {
            //console.log(resp.data)
            let dataFromServer = resp.data

            if (!dataFromServer || dataFromServer.length === 0 || dataFromServer["error"]) return

            setProcessingInvestments(false)
            setInvestmentData({ rows: dataFromServer["amount"].length })
          })
      }, 2000)
    }
  }, [processingInvestments])

  function handleFileSelect(data) {

    function retrieveFileData(year = 0, utilityType = "") {
      if (dataType === "cashflow")
      {
        console.log("fetching cashflow data")
  
        let url_query = `${process.env.REACT_APP_FLASK_IP}/data/cashflow1`
        let body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "year": year }
  
        axios.defaults.withCredentials = true
        axios.post(url_query, body)
          .then(function (resp) {
            //console.log(resp.data)
            const desiredCashflowHeaders = {
              "year": "Year", "openingbalance": "Opening Balance", "contribution": "Contribution", "expenditureia": "Expenditure (IA)",
              "interestincome": "Interest Income", "closingbalance": "Closing Balance", "inflationrate": "Inflation Rate", "interestrate": "Interest Rate"
            }
            const desiredAmountHeaders = ["Opening Balance", "Contribution", "Expenditure (IA)", "Interest Income", "Closing Balance"]
            const dataFromServer = formatServerData(resp.data, desiredCashflowHeaders);
  
            if (!dataFromServer) return
  
            setCurrentData(dataFromServer)
            setCurrentDataColumns(formatReactableColumns(dataFromServer[0], [], desiredAmountHeaders))
            setCurrentRTAmountHeaders(desiredAmountHeaders)
            setCurrentFile("cashflow_" + year + ".csv")

            setTableView(true)
          })
      }
      else if (dataType === "energy")
      {
        console.log("fetching " + utilityType + " data")
  
        let url_query = `${process.env.REACT_APP_FLASK_IP}/data/utility`
        // NOTE: backend returns "natural gas" if available in utilities, requires "naturalgas" when getting data
        let body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "utility_type": utilityType.replaceAll(' ', '') }
  
        axios.defaults.withCredentials = true
        axios.post(url_query, body)
          .then(function (resp) {
            //console.log("/data/utility:", resp.data)
            const desiredEnergyHeaders = { "startdate": "Start Date", "enddate": "End Date", "consumption": "Consumption", "cost": "Cost" }
            const desiredAmountHeaders = ["Cost"]
            const desiredDateHeaders = ["Start Date", "End Date"]
            const dataFromServer = formatServerData(resp.data, desiredEnergyHeaders);
  
            if (!dataFromServer) return
  
            setCurrentData(dataFromServer)
            setCurrentDataColumns(formatReactableColumns(dataFromServer[0], desiredDateHeaders, desiredAmountHeaders))
            setCurrentRTAmountHeaders(desiredAmountHeaders)
            setCurrentRTDateHeaders(desiredDateHeaders)
  
            for (let i in utilityFiles)
            {
              if (utilityFiles[i]["type"] === utilityType)
              {
                setCurrentFile(utilityType + "_" + utilityFiles[i]["latest_date"] + ".csv")
                
                setTableView(true)
                break
              }
            }
          })
      }
      else if (dataType === "investments")
      {
        console.log("fetching investments data")
  
        let url_query = `${process.env.REACT_APP_FLASK_IP}/data/investments`
        let body = { "email": auth.email, "building_short_name": auth.selectedBuilding }
  
        axios.defaults.withCredentials = true
        axios.post(url_query, body)
          .then(function (resp) {
            //console.log("/data/investments:", resp.data)
            const desiredInvestmentHeaders = {
              "issuer": "Issuer", "amount": "Amount", "rate": "Rate", "issuedate": "Issue Date", "maturitydate": "Maturity Date",
              "type": "Type", "description": "Description", "custodian": "Custodian"
            }
            const desiredAmountHeaders = ["Amount"]
            const desiredDateHeaders = ["Issue Date", "Maturity Date"]
            
            const dataFromServer = formatServerData(resp.data, desiredInvestmentHeaders);
  
            if (!dataFromServer || dataFromServer.length === 0) return
            
            setCurrentData(dataFromServer)
            setCurrentDataColumns(formatReactableColumns(dataFromServer[0], desiredDateHeaders, desiredAmountHeaders))
            setCurrentRTAmountHeaders(desiredAmountHeaders)
            setCurrentRTDateHeaders(desiredDateHeaders) 
            setCurrentFile("investments_" + todaysDate() + ".csv")

            setTableView(true)
          })
      }
    }
  
    setCurrentData(null)
    setCurrentFile(null)
    if (dataType === "cashflow")
    {
      retrieveFileData(data)
    }
    else if (dataType === "energy")
    {
      retrieveFileData(0, data)
    }
    else if (dataType === "investments")
    {
      retrieveFileData()
    }
  }

  function UploadDataModal(props) {
    const [dragActive, setDragActive] = useState(false)
    const [formData, setFormData] = useState(null)

    const [newFileName, setNewFileName] = useState(null)

    const newInputRef = useRef(null)

    function handleDrag(e) {
      e.preventDefault()
      e.stopPropagation()
      if (e.type === "dragenter" || e.type === "dragover")
      {
        setDragActive(true)
      }
      else if (e.type === "dragleave")
      {
        setDragActive(false)
      }
    }

    function handleDrop(e) {
      e.preventDefault()
      e.stopPropagation()
      setDragActive(false)

      if (e.dataTransfer.files[0].type !== "text/csv")
      {
        console.log("not the right type!")
        return
      }

      if (e.dataTransfer.files && e.dataTransfer.files[0])
      {
        setNewFileName(e.dataTransfer.files[0].name)
        setFormData({
          ...formData,
          file: e.dataTransfer.files[0]
        })
      }
    }

    function handleFileUpload(e) {
      e.preventDefault()
      if (e.target.files && e.target.files[0])
      {
        setNewFileName(e.target.files[0].name)
        setFormData({
          ...formData,
          file: e.target.files[0]
        })
      }
    }

    function handleChange(e) {
      setFormData({
        ...formData,
        [e.target.id]: e.target.value
      });
    };

    function handleDelete() {
      setNewFileName(null)
      setFormData({
        ...formData,
        file: null
      })
    }

    function handleSubmit(e) {
      // handle no data
      e.preventDefault()
      if (!formData || !formData["file"]) return

      const file = formData["file"]
      let url_query = ""

      const postData = new FormData()
      postData.append('myFile', file)
      postData.append('email', auth.email)
      postData.append('building_short_name', auth.selectedBuilding)

      if (dataType === "cashflow")
      {
        if (!formData["engineer"]) return
        if (!formData["publish_date"]) formData["publish_date"] = todaysDate()

        url_query = `${process.env.REACT_APP_FLASK_IP}/fileupload/cashflow`
        const publish_date = formData["publish_date"].replaceAll("-", "")
        postData.append('engineer', formData["engineer"])
        postData.append('publish_date', publish_date)
      }
      else if (dataType === "energy")
      {
        if (!formData["company"]) return
        if (!formData["utility_type"]) formData["utility_type"] = "electricity"

        url_query = `${process.env.REACT_APP_FLASK_IP}/fileupload/utility`
        postData.append('utility_type', formData["utility_type"])
        postData.append('company', formData["company"])
      }
      else if (dataType === "investments")
      {
        url_query = `${process.env.REACT_APP_FLASK_IP}/fileupload/investments`
      }

      // send POST w file to backend
      axios.defaults.withCredentials = true
      axios.post(url_query, postData)
        .then(function (resp) {
          if (dataType === "cashflow") setProcessingCashflow(true)
          else if (dataType === "energy") setProcessingUtility(true)
          else if (dataType === "investments") setProcessingInvestments(true)
          props.onHide()
        }).catch((error) => {
          console.log(error);
        })
    }

    return (
      <Modal {...props} backdrop="static" size="md" dialogClassName="modal-20">
        <Modal.Header closeButton>
          <Modal.Title>File Upload</Modal.Title>
        </Modal.Header>
        <form id='form-file-upload' onSubmit={handleSubmit} >
          <Modal.Body>
            {!newFileName && (
              <>
                <input ref={newInputRef} type="file" id='input-file-upload' accept=".csv" onChange={handleFileUpload} />
                <label id='label-file-upload' htmlFor='input-file-upload' className={dragActive ? "drag-active" : ""} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
                  <div className='modal-button-container'>
                    <p>Drag and drop your CSV file here or</p>
                    <Button variant={`${newFileName ? "success" : "primary"}`} onClick={() => newInputRef.current.click()}>Upload a file</Button>
                  </div>
                </label>
              </>
            )}

            {newFileName && (
              <>
                <div className='file-uploaded space-between'>
                  <div><b>File Name:</b> {newFileName}</div>
                  <input type="image" src={x} alt="x" onClick={handleDelete} />
                </div>
              </>
            )}

            {dataType === "cashflow" && (
              <div className='additional-data' style={{'flexWrap':'wrap'}}>
                <input type="text" placeholder="Engineer" id="engineer" onChange={handleChange} />
                <div style={{'display':'flex'}}>
                  <h6 style={{'marginRight':'10px', 'marginTop':'4px'}}>Publish date:</h6>
                  <input type="date" id="publish_date" defaultValue={todaysDate()} onChange={handleChange} />
                </div>
              </div>
            )}

            {dataType === "energy" && (
              <div className='additional-data' style={{'flexWrap':'wrap'}}>
                <h6>Utility type: </h6>
                <select id="utility_type" onChange={handleChange}>
                  <option value="electricity">Electricity</option>
                  <option value="water">Water</option>
                  <option value="steam">Steam</option>
                  <option value="naturalgas">Natural Gas</option>
                </select>
                <input type="text" placeholder="Company" id="company" onChange={handleChange} />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" type="submit">Submit</Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  // component for displaying data as a table if user clicks into a study, investment file or utility bill
  function TableView() {
    console.log(currentDateRTHeaders)
    return (
      <div className='table-view'>
        <div className='buttons space-between'>
          <Button onClick={() => setTableView(false)}>Back</Button>
          <CSVLink data={currentData ? currentData : []} enclosingCharacter={''}
            filename={currentFile ? currentFile : ""}>
            <img src={download} />
          </CSVLink>
        </div>
        <div className='react-table' style={{'whiteSpace':'nowrap'}}>
          {currentData && currentDataColumns && <ReactTableBasic data={currentData} columns={currentDataColumns} formatDateHeaders={currentDateRTHeaders} formatAmountHeaders={currentAmountRTHeaders} />}
        </div>
      </div>
    )
  }

  return (
    <>
      {dataLoaded && (
        <div className='manage-data'>
          <UploadDataModal show={modalShow} onHide={() => setModalShow(false)} />
          <div className='content'>
            <Tab.Container defaultActiveKey={pageSource}>
              <Nav variant="tabs" onSelect={(key) => {
                setTableView(false)
                setDataType(key)
              }}>
                <Nav.Item>
                  <Nav.Link eventKey={"cashflow"}>Reserve Fund</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={"investments"}>Investments</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={"energy"}>Energy Analytics</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey={"cashflow"}>
                  {processingCashflow && (
                    <div className='processing flex-center'>
                      <h3>Your Reserve Fund data is being uploaded.</h3>
                      <Spinner animation="border" />
                    </div>
                  )}
                  {!processingCashflow && (
                    <>
                      {!tableView && (
                        <div className='data-overview'>
                          <h3>{cashflowFiles === null ? "No Studies Uploaded" : "Your Building's Studies"}</h3>
                          {cashflowFiles && cashflowFiles.map(({ engineer, year, publish_date }, index) => {
                            return (
                              <div className='component'>
                                <button className='component-container' key={index} onClick={() => handleFileSelect(year)}>
                                  <div><b>Year: </b>{year}</div>
                                  <div><b>Publish Date: </b>{formatDateDashes(publish_date)}</div>
                                  <div><b>Engineer: </b>{engineer}</div>
                                </button>
                              </div>
                            );
                          })}
                          <Button className='upload-button' onClick={() => setModalShow(true)}>Upload</Button>
                        </div>
                      )}
                      {tableView && <TableView />}
                    </>
                  )}
                </Tab.Pane>

                <Tab.Pane eventKey={"investments"}>
                  {processingInvestments && (
                    <div className='processing flex-center'>
                      <h3>Your Investments data is being uploaded.</h3>
                      <Spinner animation="border" />
                    </div>
                  )}
                  {!processingInvestments && (
                    <>
                      {!tableView && (
                        <div className='data-overview'>
                          <h3>{investmentData === null ? "No Investment Data Uploaded" : "Your Building's Investment Data"}</h3>
                          {investmentData &&
                            <div className='component'>
                              <button className='component-container' onClick={() => handleFileSelect()}>
                                <div><b>Number of investments: </b>{investmentData["rows"]}</div>
                              </button>
                            </div>
                          }
                          <Button className='upload-button' onClick={() => setModalShow(true)}>Upload</Button>
                        </div>
                      )}
                      {tableView && <TableView />}
                    </>
                  )}
                </Tab.Pane>

                <Tab.Pane eventKey={"energy"}>
                  {processingUtility && (
                    <div className='processing flex-center'>
                      <h3>Your Energy data is being uploaded.</h3>
                      <Spinner animation="border" />
                    </div>
                  )}
                  {!processingUtility && (
                    <>
                      {!tableView && (
                        <div className='data-overview'>
                          <h3>{utilityFiles === null ? "No Utility Bills Uploaded" : "Your Building's Utility Bills"}</h3>
                          {utilityFiles && utilityFiles.map(({ type, latest_date, company }, index) => {
                            return (
                              <div className='component'>
                                <button className='component-container' key={index} onClick={() => handleFileSelect(type)}>
                                  <div><b>Utility Type: </b>{type}</div>
                                  <div><b>Date: </b>{formatDateDashes(latest_date)}</div>
                                  <div><b>Company: </b>{company}</div>
                                </button>
                              </div>
                            );
                          })}
                          <Button className='upload-button' onClick={() => setModalShow(true)}>Upload</Button>
                        </div>
                      )}
                      {tableView && <TableView />}
                    </>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      )}
    </>
  );
}