// bank icon svgs
import cibc from './cibc-logo.svg'
import b2b from './b2b-bank-logo.png'
import bmo from './bank-of-montreal-logo.svg'
import concentra from './concentra-financial-logo.svg'
import equitable from './equitable-bank-logo.svg'
import homeequity from './homeequity-bank-logo.png'
import icici from './icici-bank-logo.svg'
import manulifebank from './manulife-bank-of-canada-logo.svg'
import manulife from './manulife-second-logo.svg'
import nationalbank from './national-bank-of-canada-logo.svg'
import scotiabank from './scotiabank-logo.svg'
import versabank from './versabank-logo.svg'
import gbc from './general-bank-of-canada-logo.png'
import hometrust from './home-trust-logo.png'
import cwb from './canadian-western-bank-logo.png'
import ctirebank from './canadian-tire-bank-logo.png'
import nationaltrust from './national-trust-logo.png'
import rbc1 from './rbc-logo-3.png'

// bank icons and custom dimensions
const mapIconDimensions = {
  "cibc": {
    "img": cibc, 
    "width":'131px', 
    "height": '41px'
  },
  "cibc bank": {
    "img": cibc, 
    "width":'131px', 
    "height": '41px'
  },
  "b2b": {
    "img": b2b, 
    "width":'140px', 
    "height": '45px'
  },
  "b2b bank": {
    "img": b2b, 
    "width":'140px', 
    "height": '45px'
  },
  "bmo": {
    "img": bmo, 
    "width":'150px', 
    "height": '45px'
  },
  "bank of montreal": {
    "img": bmo, 
    "width":'150px', 
    "height": '45px'
  },
  "concentra": {
    "img": concentra, 
    "width":'140px', 
    "height": '40px'
  },
  "concentra bank": {
    "img": concentra, 
    "width":'140px', 
    "height": '40px'
  },
  "general bank of canada": {
    "img": gbc, 
    "width":'180px', 
    "height": '40px'
  },
  "home trust": {
    "img": hometrust, 
    "width":'130px', "height": '45px'
  },
  "equitable": {
    "img": equitable, 
    "width":'140px', 
    "height": '40px'
  },
  "equitable bank": {
    "img": equitable, 
    "width":'140px', 
    "height": '40px'
  },
  "homeequity": {
    "img": homeequity, 
    "width":'200px', 
    "height": '40px'
  },
  "homeequty bank": {
    "img": homeequity, 
    "width":'200px', 
    "height": '40px'
  },
  "manulife bank": {
    "img": manulifebank, 
    "width":'200px', 
    "height": '40px'
  },
  "manulife": {
    "img": manulife, 
    "width":'160px', 
    "height": '38px'
  },
  "manulife trust": {
    "img": manulife, 
    "width":'160px', 
    "height": '38px'
  },
  "canadian western": {
    "img": cwb, 
    "width":'150px', 
    "height": '40px'
  },
  "canadian tire": {
    "img": ctirebank, 
    "width":'170px', 
    "height": '45px'
  },
  "national bank of canada": {
    "img": nationalbank, 
    "width":'150px', 
    "height": '45px'
  },
  "royal bank": {
    "img": rbc1, 
    "width":'140px', 
    "height": '40px'
  },
  "scotia bank": {
    "img": scotiabank, 
    "width":'195px', 
    "height": '27px'
  },
  "bank of nova scotia": {
    "img": scotiabank, 
    "width":'195px', 
    "height": '27px'
  },
  "scotia": {
    "img": scotiabank, 
    "width":'195px', 
    "height": '27px'
  },
  "scotia mtge": {
    "img": scotiabank, 
    "width":'195px', 
    "height": '27px'
  },
  "versabank": {
    "img": versabank, 
    "width":'180px', 
    "height": '40px'
  },
  "icici": {
    "img": icici, 
    "width":'180', 
    "height": '40px'
  },
  "icici bank": {
    "img": icici, 
    "width":'180', 
    "height": '40px'
  },
  "national trust": {
    "img": nationaltrust, 
    "width":'105px', 
    "height": '40px'
  },
}

export default function getBankIcon(key) {
  return mapIconDimensions[key]
}

