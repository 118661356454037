import { useEffect, useState, useRef, useCallback, useContext } from 'react'
import axios from 'axios'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { Dropdown, DropdownButton, Form, OverlayTrigger, Popover, PopoverBody } from 'react-bootstrap'

// context
import { authContext } from '../authContext.js'

// Components
import MissingData from '../MissingData.js'
import OffCanvasBudget from '../OffCanvasBudget.js'

// Helper Functions
import { formatAmount, formatAmountKM, getPercent } from '../../lib/helpers/formattingHelpers.js'

// logo svgs
import logoBlue from '../../svg/mini-logo-blue.svg'
import logoGreen from '../../svg/mini-logo-green.svg'
import logoYellow from '../../svg/mini-logo-yellow.svg'
import greyQuestion from '../../svg/grey-question.svg'

export default function Budget() {
  // context and navigation
  const auth = useContext(authContext)

  const currentYear = new Date().getFullYear()

  //------------------------------------------------------------------------------------------------------------------
  // Component State
  //------------------------------------------------------------------------------------------------------------------

  // data flags
  const [showMissingData, setShowMissingData] = useState(false)
  const [dataExists, setDataExists] = useState(false)

  const [categories, setCategories] = useState({"1": "Service Contracts", "2": "Repairs & Maintenance", "3": "Recreational Facilities", "4": "Shared Facilities", "5": "On-Site Personnel", "6": "Utilities", "7": "Guest Suites", "8": "Administrative Expenses"})
  const [legendCategories, setLegendCategories] = useState({"Service Contracts": 1, "Repairs & Maintenance": 1, "Recreational Facilities": 1, "Shared Facilities": 1, "On-Site Personnel": 1, "Utilities": 1, "Guest Suites": 1, "Administrative Expenses": 1})
  const [selectedItemNumber, setSelectedItemNumber] = useState("1")
  const prevSelectedItemNumber = useRef('')

  const [subCategories, setSubCategories] = useState({
    "1": {
      "1A": "Air Conditioning & Heating",
      "1B": "Waste Disposal",
      "1C": "Elevator Maintenance",
      "1D": "Grounds Maintenance",
      "1E": "Access Control",
      "1F": "Superintendent Services",
      "1G": "Window Cleaning",
      "1H": "Power Sweep / Wash",
      "1I": "Housekeeping",
      "1J": "Recreation Management",
      "1K": "Management Fees"
    },
    "2": {
      "2A": "HVAC Repairs",
      "2B": "Plumbing",
      "2C": "Electrical",
      "2D": "General Landscaping",
      "2E": "General Access Control Expenses",
      "2F": "Waste Disposal",
      "2G": "Elevator",
      "2H": "Fire Equipment Maintenance",
      "2I": "Paint, Plaster and Wall paper repairs",
      "2J": "Cleaning and Sanitary Supplies",
      "2K": "Emergency Cleanup",
      "2L": "General Repairs & Supplies",
      "2M": "Carpet Cleaning & Repairs"
    },
    "3": {
      "3A": "Pool & Whirlpool Repairs",
      "3B": "Social Committee Activities",
      "3C": "Other Recreational Facilities",
      "3D": "Cinema Projector Expenses"
    },
    "4": {
      "4A": "Payments from ROA Partners",
      "4B": "ROA Shared Expenses",
      "4C": "Payments to ROA Partners"
    },
    "5": {
      "5A": "Vacation Relief",
      "5B": "Employee Benefits:",
      "5C": "Club Suite 6"
    },
    "6": {
      "6A": "Steam",
      "6B": "Hydro",
      "6C": "Water"
    },
    "7": {
      "7A": "Special Projects for Consideration",
      "7B": "Reserve Fund Expenditures",
      "7C": "Guest Suite Expenses"
    },
    "8": {
      "8A": "Telephone",
      "8B": "Office Expense",
      "8C": "Photocopier Expenses/Computer",
      "8D": "Meeting Costs",
      "8E": "Building Communication Systems",
      "8F": "Condominium Association Fees",
      "8G": "Insurance",
      "8H": "Audit Fees",
      "8I": "Bank Service Charges - TD Bank",
      "8J": "Consulting & Appraisal",
      "8K": "Legal Fees"
    }
  })
  const [selectedSubItemNumber, setSelectedSubItemNumber] = useState("1A")

  // data
  const [dataItem, setDataItem] = useState([])
  const [dataItemNumber, setDataItemNumber] = useState([])
  const [dataFiscal, setDataFiscal] = useState([])
  const [dataYear, setDataYear] = useState([])
  const [dataApproved, setDataApproved] = useState([])
  const [dataActual, setDataActual] = useState([])
  const [dataSurplus, setDataSurplus] = useState([])

  const [dataSeriesSubCategoryActual, setDataSeriesSubCategoryActual] = useState([])

  const [dataCategoryActual, setDataCategoryActual] = useState({})
  const [dataCategoryApproved, setDataCategoryApproved] = useState({})
  const [dataSubCategoryActual, setDataSubCategoryActual] = useState({})
  const [dataSubCategoryApproved, setDataSubCategoryApproved] = useState({})
  const [dataYearlySums, setDataYearlySums] = useState({})

  // mouse over
  const [mouseOver, setMouseOver] = useState(false)
  const [mouseOverPoint, setMouseOverPoint] = useState({})
  const [mouseOverPointTracking, setMouseOverPointTracking] = useState({})
  const [mouseOverIndex, setMouseOverIndex] = useState(null)
  const [firstRender, setFirstRender] = useState(true)

  // charts
  const [chartOptionsActualBudget, setChartOptionsActualBudget] = useState({})
  const [chartOptionsActualBudgetArea, setChartOptionsActualBudgetArea] = useState({})
  const [chartOptionsTracking, setChartOptionsTracking] = useState({})
  const [chartOptionsDetailsActual, setChartOptionsDetailsActual] = useState({})
  const [chartOptionsDetailsTracking, setChartOptionsDetailsTracking] = useState({})

  // show flags
  const [showOffCanvas, setShowOffCanvas] = useState(false)
  const [showBudgetAreaChart, setShowBudgetAreaChart] = useState(false)
  const [showHeaderModal, setShowHeaderModal] = useState(0)
  const [showHeaderModalTracking, setShowHeaderModalTracking] = useState(0)

  // for mobile screens
  const [mobileScreen, setMobileScreen] = useState(false)

  //------------------------------------------------------------------------------------------------------------------
  // Helper Functions
  //------------------------------------------------------------------------------------------------------------------

  // for updating all state variables for storing chart data (this is only meant to reduce lines of code because it's called multiple times)
  function helperUpdateChartData(dataFromServer) {
    setDataItem(dataFromServer['item'])
    setDataItemNumber(dataFromServer['itemnumber'])
    setDataFiscal(dataFromServer['fiscalperiod'])
    setDataYear(dataFromServer['year'])
    setDataApproved(dataFromServer['approved'])
    setDataActual(dataFromServer['actual'])
    setDataSurplus(dataFromServer['surplusdeficit'])

    const tempDataCategoryApproved = {}
    const tempDataCategoryActual = {}
    const tempDataSubCategoryApproved = {}
    const tempDataSubCategoryActual = {}

    //const trackItemRoot = 0
    // const tempCategories TODO
    //const tempSubCategories = {}
    for (var i = 0; i < dataFromServer["itemnumber"].length; i++)
    {
      if (dataFromServer["itemnumber"][i].length == 1)
      {
        //trackItemRoot = dataFromServer["itemnumber"][i]
        //tempCategories.push(dataFromServer["item"][i]) TODO
        if (tempDataCategoryApproved[dataFromServer["itemnumber"][i]])
        {
          tempDataCategoryApproved[dataFromServer["itemnumber"][i]].push([dataFromServer["year"][i], dataFromServer["approved"][i]])
          if (dataFromServer["year"][i] != 2023) tempDataCategoryActual[dataFromServer["itemnumber"][i]].push([dataFromServer["year"][i], dataFromServer["actual"][i]])
        }
        else 
        {
          tempDataCategoryApproved[dataFromServer["itemnumber"][i]] = [[dataFromServer["year"][i], dataFromServer["approved"][i]]]
          if (dataFromServer["year"][i] != 2023) tempDataCategoryActual[dataFromServer["itemnumber"][i]] = [[dataFromServer["year"][i], dataFromServer["actual"][i]]]
        }
      }
      else
      {
        // create subCategories TODO
        // if (tempSubCategories[trackItemRoot])
        // {
        //   tempSubCategories[trackItemRoot][dataFromServer["itemnumber"][i]] = dataFromServer["item"][i]
        // }
        // else tempSubCategories[trackItemRoot] = {dataFromServer["itemnumber"][i]: dataFromServer["item"][i]}

        if (tempDataSubCategoryApproved[dataFromServer["itemnumber"][i]])
        {
          tempDataSubCategoryApproved[dataFromServer["itemnumber"][i]].push([dataFromServer["year"][i], dataFromServer["approved"][i]])
          if (dataFromServer["year"][i] != 2023) tempDataSubCategoryActual[dataFromServer["itemnumber"][i]].push([dataFromServer["year"][i], dataFromServer["actual"][i]])
        }
        else 
        {
          tempDataSubCategoryApproved[dataFromServer["itemnumber"][i]] = [[dataFromServer["year"][i], dataFromServer["approved"][i]]]
          if (dataFromServer["year"][i] != 2023) tempDataSubCategoryActual[dataFromServer["itemnumber"][i]] = [[dataFromServer["year"][i], dataFromServer["actual"][i]]]
        }
      }
    }

    const filteredObj = Object.keys(tempDataSubCategoryActual)
      .filter(key => key.startsWith(selectedItemNumber))
      .reduce((acc, key) => {
        acc[key] = tempDataSubCategoryActual[key]
        return acc
      }, {})
    //console.log('filteredObj-helperUpdateChartData()', filteredObj)

    var transformedData = Object.keys(filteredObj).map(key => {
      if (key.charAt(0) == selectedItemNumber)
      {
        return {
          name: subCategories[selectedItemNumber][key],
          type: 'spline',
          data: filteredObj[key],
          marker: {
            symbol: 'circle',
            enabled: false
          }
        }
      }
    })
    setDataSeriesSubCategoryActual(transformedData)

    setDataCategoryActual(tempDataCategoryActual)
    setDataCategoryApproved(tempDataCategoryApproved)
    setDataSubCategoryActual(tempDataSubCategoryActual)
    setDataSubCategoryApproved(tempDataSubCategoryApproved)
    
    // Yearly Sums
    let yearSums = {}
    // Iterate over each category in the object
    for (let category in tempDataCategoryActual) {
      // Iterate over each entry in the category
      for (let entry in tempDataCategoryActual[category]) {
        let year = tempDataCategoryActual[category][entry][0] // The year
        let value = tempDataCategoryActual[category][entry][1] // The value to add

        // If the year is already in the yearSums object, add to its value; otherwise, initialize it
        if (yearSums[year]) {
          yearSums[year] += value
        } else {
          yearSums[year] = value
        }
      }
    }
    setDataYearlySums(yearSums)
  }

  //------------------------------------------------------------------------------------------------------------------
  // Callback Functions
  //------------------------------------------------------------------------------------------------------------------

  // OffCanvas Callbacks
  const handleSyncCharts = useCallback((e, chartNames, all=false) => {
    onMouseOverSyncCharts(e, chartNames, all)
  }, [])
  const handleCloseOffCanvas = useCallback(() => {
    setShowOffCanvas(false)
  }, [])
  
  //------------------------------------------------------------------------------------------------------------------
  // Component Hooks
  //------------------------------------------------------------------------------------------------------------------

  // fire once on page load
  useEffect(() => {
    if (auth.isContextSecured)
    {
      console.log("fetching data")
      let url_query = `${process.env.REACT_APP_FLASK_IP}/data/budget`
      let body = { "email": auth.email, "building_short_name": auth.selectedBuilding }

      axios.defaults.withCredentials = true
      axios.post(url_query, body)
        .then(function (resp) {
          // handle no RF studies - show MissingData Component
          if (Object.keys(resp.data).length === 0 || resp.data["error"])
          {
            setShowMissingData(true)
            return
          }
          setDataExists(true)

          //console.log(resp.data)
          helperUpdateChartData(resp.data)
      })
    }
  }, [auth.isContextSecured])

  useEffect(() => {
    // update selectedSubItemNumber for dropdown
    setSelectedSubItemNumber(selectedItemNumber + "A")

    // update dataSeriesSubCategoryActual
    const filteredObj = Object.keys(dataSubCategoryActual)
    .filter(key => key.startsWith(selectedItemNumber))
    .reduce((acc, key) => {
      acc[key] = dataSubCategoryActual[key]
      return acc
    }, {})
    //console.log('filteredObj', filteredObj)
    
    var transformedData = Object.keys(filteredObj).map(key => {
      if (key.charAt(0) == selectedItemNumber)
      {
        return {
          name: subCategories[selectedItemNumber][key],
          type: 'spline',
          data: filteredObj[key],
          marker: {
            symbol: 'circle',
            enabled: false
          }
        }
      }
    })
    setDataSeriesSubCategoryActual(transformedData)

    // update prevSelectedItemNumber
    prevSelectedItemNumber.current = selectedItemNumber
  }, [selectedItemNumber])

  // Highcharts Chart Options Hook 1
  useEffect(() => {
    if (Object.keys(dataCategoryApproved).length > 0)
    {
      setChartOptionsActualBudget({
        chart: {
          type: "column",
          height: '40%',
          borderRadius: 10, // same as other divs in index.css  
          backgroundColor: "#ffffff",
          spacing: [20, 20, 20, 20],
          events: {
            // update totals header on chart render
            render: function () {
              //----------------------------------------------------
              // Set Default Mouse Over Data
              //----------------------------------------------------

              var iSeries = 0
              var lastIndex = this.series[iSeries].points.length - 1
              var point = this.series[iSeries].points[lastIndex]
              setMouseOverPoint(point)
              setMouseOverIndex(point.index)

              //----------------------------------------------------
              // Draw Legend (above columns)
              //----------------------------------------------------

              // destory markers if already exists
              if (point.series.chart['legendLabel'] != undefined)
              {
                if (Object.keys(point.series.chart['legendLabel']).length > 0) point.series.chart['legendLabel'].destroy()
              }
              
              var html = `<div class='legend-label'>
                            <div>Labels show the relative change from last year:</div>
                            <div class='flex-center small-gap'>
                              <div class='data-label' id='green'>%</div>
                              <div>- increase since last year</div>
                            </div>
                            <div class='flex-center small-gap'>
                              <div class='data-label' id='red'>%</div>
                              <div>- decrease since last year</div>
                            </div>
                          </div>`

              point.series.chart['legendLabel'] = point.series.chart.renderer.label(
                mobileScreen ? null:html,
                -5,
                -5,
                null, null, null, true
              ).attr({
                zIndex: 4
              }).add()
            }
          }
        },
        title: {
          text: "actual budget by category",
          style: {
            display: 'none'
          }
        },
        tooltip: {
          shared: true,
          formatter: function () {
            return false
          }
        },
        series: [
          {
            name: 'Service Contracts',
            // type: 'spline',
            data: dataCategoryActual["1"],
            //color: '#a7b661',
            fillOpacity: 0.3,
            marker: {
              enabled: true,
              symbol: 'circle',
            }
          },
          {
            name: 'Repairs & Maintenance',
            // type: 'spline',
            data: dataCategoryActual["2"],
            //color: '#a7b661',
            fillOpacity: 0.3,
            marker: {
              enabled: true,
              symbol: 'circle',
            }
          },
          {
            name: 'Recreational Facilities',
            // type: 'spline',
            data: dataCategoryActual["3"],
            //color: '#a7b661',
            fillOpacity: 0.3,
            marker: {
              enabled: true,
              symbol: 'circle',
            }
          },
          {
            name: 'Shared Facilities',
            // type: 'spline',
            data: dataCategoryActual["4"],
            //color: '#a7b661',
            fillOpacity: 0.3,
            marker: {
              enabled: true,
              symbol: 'circle',
            }
          },
          {
            name: 'On-Site Personnel',
            // type: 'spline',
            data: dataCategoryActual["5"],
            //color: '#a7b661',
            fillOpacity: 0.3,
            marker: {
              enabled: true,
              symbol: 'circle',
            }
          },
          {
            name: 'Utilities',
            // type: 'spline',
            data: dataCategoryActual["6"],
            //color: '#a7b661',
            fillOpacity: 0.3,
            marker: {
              enabled: true,
              symbol: 'circle',
            }
          },
          {
            name: 'Guest Suites',
            // type: 'spline',
            data: dataCategoryActual["7"],
            //color: '#a7b661',
            fillOpacity: 0.3,
            marker: {
              enabled: true,
              symbol: 'circle',
            }
          },
          {
            name: 'Administrative Expenses',
            // type: 'spline',
            data: dataCategoryActual["8"],
            //color: '#a7b661',
            fillOpacity: 0.3,
            marker: {
              enabled: true,
              symbol: 'circle',
            }
          }
        ],
        xAxis: [{
          crosshair: false,
          visible: true,
          maxPadding: 0.03,
          tickInterval: 1
        }],
        yAxis: [{
          visible: true,
          title: { enabled: false },
          startOnTick: false,
          endOnTick: false,
          labels: {
            formatter: function () {
              if (this.value != null)
              {
                return formatAmountKM(this.value)
              }
            }
          },
        }],
        plotOptions: {
          series: {
            // mouse over chart event
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncCharts(e, ['actual vs approved'])
                  setMouseOverPoint(this)
                  setMouseOverIndex(this.index)
                },
              }
            },
            events: {
              legendItemClick: function (e) {
                var tempLegend = legendCategories
                if (tempLegend[e.target.name] == 1) tempLegend[e.target.name] = 0
                else tempLegend[e.target.name] = 1 
                setLegendCategories(tempLegend)
              }
            }
          },
        },
        credits: {
          enabled: false
        }
      })

      setChartOptionsActualBudgetArea({
        chart: {
          type: "area",
          height: '40%',
          borderRadius: 10, // same as other divs in index.css  
          backgroundColor: "#ffffff",
          spacing: [20, 20, 20, 20],
          events: {
            // update totals header on chart render
            render: function () {
              var iSeries = 0
              var lastIndex = this.series[iSeries].points.length - 1
              var point = this.series[iSeries].points[lastIndex]
              setMouseOverPoint(point)
              setMouseOverIndex(point.index)
            }
          }
        },
        title: {
          text: "actual budget by category area",
          style: {
            display: 'none'
          }
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          split: false,
          formatter: function () {
            return false
          }
        },
        series: [
          {
            name: 'Service Contracts',
            data: dataCategoryActual["1"],
            fillOpacity: 0.3,
            marker: {
              symbol: 'circle',
            }
          },
          {
            name: 'Repairs & Maintenance',
            data: dataCategoryActual["2"],
            fillOpacity: 0.3,
            marker: {
              symbol: 'circle',
            }
          },
          {
            name: 'Recreational Facilities',
            data: dataCategoryActual["3"],
            fillOpacity: 0.3,
            marker: {
              symbol: 'circle',
            }
          },
          {
            name: 'Shared Facilities',
            data: dataCategoryActual["4"],
            fillOpacity: 0.3,
            marker: {
              symbol: 'circle',
            }
          },
          {
            name: 'On-Site Personnel',
            data: dataCategoryActual["5"],
            fillOpacity: 0.3,
            marker: {
              symbol: 'circle',
            }
          },
          {
            name: 'Utilities',
            data: dataCategoryActual["6"],
            fillOpacity: 0.3,
            marker: {
              symbol: 'circle',
            }
          },
          {
            name: 'Guest Suites',
            data: dataCategoryActual["7"],
            fillOpacity: 0.3,
            marker: {
              symbol: 'circle',
            }
          },
          {
            name: 'Administrative Expenses',
            data: dataCategoryActual["8"],
            fillOpacity: 0.3,
            marker: {
              symbol: 'circle',
            }
          }
        ],
        xAxis: [{
          crosshair: true,
          visible: true,
          maxPadding: 0.03,
          tickInterval: 1
        }],
        yAxis: [{
          visible: true,
          title: { enabled: false },
          startOnTick: false,
          endOnTick: false,
          labels: {
            format: '{value}%'
          }
        }],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncCharts(e, ['actual vs approved'])
                  setMouseOverPoint(this)
                  setMouseOverIndex(this.index)
                },
              }
            }
          },
          area: {
              stacking: 'percent',
              marker: {
                  enabled: false
              }
          }
        },
        credits: {
          enabled: false
        }
      })
    }
  }, [dataSubCategoryApproved])

  // Highcharts Chart Options Hook 2
  useEffect(() => {

    const tempActual = dataCategoryActual[selectedItemNumber]
    const tempApproved = dataCategoryApproved[selectedItemNumber]

    const tempActualSub = dataSubCategoryActual[selectedSubItemNumber]
    const tempApprovedSub = dataSubCategoryApproved[selectedSubItemNumber]

    if (Object.keys(dataCategoryApproved).length > 0)
    {
      setChartOptionsTracking({
        chart: {
          type: "line",
          height: '40%',
          borderRadius: 10, // same as other divs in index.css  
          backgroundColor: "#ffffff",
          spacing: [20, 20, 20, 20],
          events: {
            // update totals header on chart render
            render: function () {
              var iSeries = 0
              var lastIndex = this.series[iSeries].points.length - 1
              var point = this.series[iSeries].points[lastIndex]
              setMouseOverPointTracking(point)
            }
          }
        },
        title: {
          text: "actual vs approved",
          style: {
            display: 'none'
          }
        },
        tooltip: {
          shared: true,
          formatter: function () {
            return false
          }
        },
        series: [
          {
            name: 'Approved',
            type: 'spline',
            data: tempApproved,
            color: '#5d98d1',
            fillOpacity: 0.3,
            marker: {
              enabled: false,
              symbol: 'circle',
            }
          },
          {
            name: 'Actual',
            type: 'spline',
            data: tempActual,
            color: '#f1c31a',
            fillOpacity: 0.3,
            marker: {
              enabled: false,
              symbol: 'circle',
            }
          },
        ],
        xAxis: [{
          crosshair: true,
          visible: true,
          maxPadding: 0.03,
          tickInterval: 1
        }],
        yAxis: [{
          visible: true,
          title: { enabled: false },
          startOnTick: false,
          endOnTick: false,
          labels: {
            formatter: function () {
              if (this.value != null)
              {
                return formatAmountKM(this.value)
              }
            }
          },
        }],
        plotOptions: {
          series: {
            // mouse over chart event
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncCharts(e, ['actual budget by category', 'actual budget by category area'])
                  setMouseOverPointTracking(this)
                  setMouseOverIndex(this.index)
                },
              }
            },
          }
        },
        credits: {
          enabled: false
        }
      })

      setChartOptionsDetailsActual({
        chart: {
          type: "line",
          borderRadius: 10, // same as other divs in index.css 
          backgroundColor: "#ffffff",
          height: '44%'
        },
        title: {
          text: "subcategory actual",
          style: {
            display: 'none'
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          shared: true,
        },
        series: dataSeriesSubCategoryActual,
        xAxis: [{
          crosshair: true,
          visible: true,
          maxPadding: 0.03,
          tickInterval: 1
        }],
        yAxis: [{
          visible: true,
          title: { enabled: false },
          startOnTick: false,
          endOnTick: false,
          labels: {
            formatter: function () {
              if (this.value != null)
              {
                return formatAmountKM(this.value)
              }
            }
          },
        }],
        credits: {
          enabled: false
        }
      })

      setChartOptionsDetailsTracking({
        chart: {
          type: "line",
          borderRadius: 10, // same as other divs in index.css 
          backgroundColor: "#ffffff",
          height: '44%'
        },
        title: {
          text: "subcategory actual",
          style: {
            display: 'none'
          }
        },
        tooltip: {
          shared: true,
        },
        series: [
          {
            name: 'Approved',
            type: 'spline',
            data: tempApprovedSub,
            color: '#5d98d1',
            fillOpacity: 0.3,
            marker: {
              enabled: false,
              symbol: 'circle',
            }
          },
          {
            name: 'Actual',
            type: 'spline',
            data: tempActualSub,
            color: '#f1c31a',
            fillOpacity: 0.3,
            marker: {
              enabled: false,
              symbol: 'circle',
            }
          },
        ],
        xAxis: [{
          crosshair: true,
          visible: true,
          maxPadding: 0.03,
          tickInterval: 1
        }],
        yAxis: [{
          visible: true,
          title: { enabled: false },
          startOnTick: false,
          endOnTick: false,
          labels: {
            formatter: function () {
              if (this.value != null)
              {
                return formatAmountKM(this.value)
              }
            }
          },
        }],
        credits: {
          enabled: false
        }
      })
    }
  }, [dataSubCategoryApproved, selectedItemNumber, selectedSubItemNumber])

  // Mouse Over X-Axis Tick Hook - triggered when mouse over main chart point changes
  useEffect(() => {
    if (showBudgetAreaChart) return
    if (Object.keys(mouseOverPoint).length > 0)
    {
      //----------------------------------------------------
      // Draw Labels (above columns)
      //----------------------------------------------------
      
      if (firstRender)
      {
        setFirstRender(false)
        return
      }

      // count total number of data points clicked on in legend
      var sumLegendClicked = 0
      for (let key in legendCategories) sumLegendClicked += legendCategories[key]

      var drawingLabelNum = 1

      var numPoints = mouseOverPoint.series.chart.series.length
      for (var i = 0; i < numPoints; i++)
      {
        // destory markers if already exists
        if (mouseOverPoint.series.chart['dataLabel' + i] != undefined)
        {
          if (Object.keys(mouseOverPoint.series.chart['dataLabel' + i]).length > 0) mouseOverPoint.series.chart['dataLabel' + i].destroy()
        }

        // exit if hovering over first point (inside loop because we want the above code to delete existing labels)
        if (mouseOverPoint.index == 0) continue

        if (legendCategories[mouseOverPoint.series.chart.series[i].name] == 0) continue

        var y = mouseOverPoint.series.chart.series[i].data[mouseOverPoint.index].plotY
        
        var value = (mouseOverPoint.series.chart.series[i].data[mouseOverPoint.index].y / mouseOverPoint.series.chart.series[i].data[mouseOverPoint.index-1].y) - 1
        
        var color = 'green'
        if (value < 0)
        {
          var color = 'red'
          value = value * -1
        }

        value = Math.round(value * 100)

        var html = `<div class='data-label' id=${color}>${value}%</div>`

        var htmlWidthPadding = 0
        if (value > 9) htmlWidthPadding = 5

        // margin between columns
        var marginPadding = 6

        // width of each column
        var columnWidth = 12 // sumLegendClicked == 8
        if (sumLegendClicked == 7) columnWidth = 14
        else if (sumLegendClicked == 6) columnWidth = 17
        else if (sumLegendClicked == 5) columnWidth = 21
        else if (sumLegendClicked == 4) columnWidth = 27
        else if (sumLegendClicked == 3) columnWidth = 37
        else if (sumLegendClicked == 2) columnWidth = 59

        // TODO model columns more precisely so we don't need to conditionally position label like this
        var extraXPadding = 0
        if (sumLegendClicked == 8) extraXPadding = (drawingLabelNum * -2)
        else if (sumLegendClicked == 7) extraXPadding = (drawingLabelNum * -2)
        else if (sumLegendClicked == 6)
        {
          if (drawingLabelNum > 3) extraXPadding = -10
        }
        else if (sumLegendClicked == 5)
        {
          if (drawingLabelNum == 2) extraXPadding = 0
          else if (drawingLabelNum == 3) extraXPadding = -14
          else if (drawingLabelNum == 4) extraXPadding = -18
          else if (drawingLabelNum == 5) extraXPadding = -12
        }
        else if (sumLegendClicked == 4) 
        {
          if (drawingLabelNum < 3) extraXPadding = 8
          else if (drawingLabelNum == 3) extraXPadding = -13
          else if (drawingLabelNum == 4) extraXPadding = -13
        }
        else if (sumLegendClicked == 3) 
        {
          if (drawingLabelNum == 1) extraXPadding = 10
          else if (drawingLabelNum == 2) extraXPadding = 0
          else if (drawingLabelNum == 3) extraXPadding = -15
        }
        else if (sumLegendClicked == 2)
        {
          if (drawingLabelNum == 1) extraXPadding = 20
          else if (drawingLabelNum == 2) extraXPadding = 25
        }
        

        mouseOverPoint.series.chart['dataLabel' + i] = mouseOverPoint.series.chart.renderer.label(
          mobileScreen ? null:html,
          mouseOverPoint.plotX + (i * marginPadding) + (columnWidth * (drawingLabelNum-1)) + extraXPadding - htmlWidthPadding,
          y - 5,
          null, null, null, true
        ).attr({
          zIndex: 4
        }).add()

        drawingLabelNum += 1
      }

      //----------------------------------------------------
      // Draw X Axis Tick
      //----------------------------------------------------

      // const tempReserveOptions = {
      //   xAxis: [{
      //     tickPositions: [dataMinYear, currentYear, mouseOverYear, dataMaxYear]
      //   }],
      // }
      // setChartOptionsReserve(tempReserveOptions)      
    }
  }, [mouseOverIndex, auth.isContextSecured])
  
  // Mouse Out Chart Component Hook
  useEffect(() => {
    if (Object.keys(mouseOverPoint).length == 0) return
    if (mouseOverPoint.index == null) return
    if (mouseOver == false)
    {      
      //----------------------------------------------------
      // Destroy Custom Drawings
      //----------------------------------------------------
      
      // destroy dataLabel
      var numPoints = mouseOverPoint.series.chart.series.length
      for (var i = 0; i < numPoints; i++)
      {
        // destory markers if already exists
        if (mouseOverPoint.series.chart['dataLabel' + i] != undefined)
        {
          if (Object.keys(mouseOverPoint.series.chart['dataLabel' + i]).length > 0) mouseOverPoint.series.chart['dataLabel' + i].destroy()
        }
      }

      //----------------------------------------------------
      // Reset Main Chart Label
      //----------------------------------------------------

      // onMouseOutSync(['rates', 'expenditures', 'failures'])

      //----------------------------------------------------
      // Remove X Tick
      //----------------------------------------------------

      // const tempReserveOptions = {
      //   xAxis: [{
      //     tickPositions: [dataMinYear, currentYear, dataMaxYear]
      //   }],
      // }
      // setChartOptionsReserve(tempReserveOptions)
    }
  }, [mouseOver])
  
  //------------------------------------------------------------------------------------------------------------------
  // Components
  //------------------------------------------------------------------------------------------------------------------

  // TODO make modals for headers
  function TotalsCategories() {
    if (Object.keys(mouseOverPoint).length === 0 ) return <></>
    
    // Deal with case where last point of Tracking chart is being hovered on (point does not exist in Categories chart)
    // if mouse over x axis that is greater than what exists in Categories chart, default mouse over to last point of Categories chart
    let index = mouseOverIndex
    if (mouseOverIndex == dataCategoryActual['1'].length) index = dataCategoryActual['1'].length - 1 // last point

    function Card(props) {
      let category = categories[props.itemnumber]

      let amount = mouseOverPoint.series.chart.series[Number(props.itemnumber)-1].data[index].y
      let color = mouseOverPoint.series.chart.series[Number(props.itemnumber)-1].color
      let percent = getPercent(amount, dataYearlySums[mouseOverPoint.x], false)

      return (
        <button className='component-container' style={{'padding': '10px 15px', 'marginBottom':'0px', 'flex': '1 0 23%'}} onClick={() => setShowHeaderModal(Number(props.itemnumber))}>
          <div className='flex original-container'>
            <h5 id='original' style={{'width':'100%', 'color': color}}>{category}</h5>
            <div className='vertical flex' style={{'alignItems': 'flex-end'}}>
              <div id='original' className='amount' style={{'margin': '-5px 0'}}>{formatAmount(amount, 0)}</div>
              <div className='smaller' style={{'marginBottom': '4px', 'marginLeft': '5px'}}>({percent}%)</div>
            </div>
          </div>
        </button>
      )
    }

    return (
      <div className='totals' style={{'display': 'flex', 'flexWrap': 'wrap', 'gridGap': '10px'}}>
        {Object.keys(categories).map((itemnumber) => <Card itemnumber={itemnumber} />)}
      </div>
    )
  }

  // TODO make modals for headers
  function TotalsTracking() {
    if (Object.keys(mouseOverPointTracking).length === 0 ) return <></>

    // get data from mouse over point
    let amountApproved = mouseOverPointTracking.series.chart.series[0].data[mouseOverIndex].y
    let amountActual
    let amountDifference
    let pos
    if (mouseOverPointTracking.series.chart.series[1].data.length == mouseOverIndex)
    {
      amountActual = "NA"
      amountDifference = "NA"
    }
    else 
    {
      amountActual = mouseOverPointTracking.series.chart.series[1].data[mouseOverIndex].y
      pos = (amountApproved - amountActual > 0)
      amountDifference = formatAmount(amountApproved - amountActual, 0)
      amountActual = formatAmount(amountActual, 0)
    }

    function Difference() {
      return (
        <button className='component-container' style={{'width':'100%', 'paddingTop': '0px', 'paddingBottom': '10px'}} onClick={() => setShowHeaderModalTracking(1)}>
          <div id='difference' className='flex-column-breakpoint'>
            <div style={{'width':'100%', 'marginBottom':"0px", 'whiteSpace':'nowrap'}}>
              <h5 id='difference'>Difference</h5>
              <p>(Actual - Approved)</p>
            </div>
            <div className='vertical' style={{'whiteSpace':'nowrap'}}>
              <div  className='flex'>
                <div className={'amount medium ' + (pos ? 'positive': (amountDifference == 'NA'?'':'negative'))}>{pos ? '+': ''}{amountDifference}</div>
              </div>
              <p id="difference" className='smaller right' style={{'marginTop': '-10px'}}>{mouseOverPointTracking.x}</p>
            </div>
          </div>
        </button>
      )
    }

    function Approved() {
      return (
        <button className='component-container' style={{'width':'100%', 'padding': '0px 10px 10px', 'marginRight':'10px'}} onClick={() => setShowHeaderModalTracking(2)}>
            <div className='flex-column-breakpoint' style={{'height':'100%'}}>
              <div style={{'width':'90%', 'whiteSpace':'nowrap'}}>
                <h5 id='simulated' style={{'color':'#5d98d1'}}>Approved</h5>
                <p id='portfolio-date' style={{'marginLeft': '2px', 'marginTop':'-5px'}}>As of {mouseOverPointTracking.x}</p>
              </div>
              <div className='vertical' style={{'height':'100%'}}>
                <div  className='flex' style={{'whiteSpace':'nowrap'}}>
                  <div className='circle-legend' id='true-bm' />
                  <div className='amount medium'>{formatAmount(amountApproved, 0)}</div>
                </div>
              </div>
            </div>
        </button>
      )
    }

    function Actual() {
      return (
        <button className='component-container' style={{'width':'100%', 'padding': '0px 10px 10px'}} onClick={() => setShowHeaderModalTracking(3)}>
          <div className='flex-column-breakpoint' style={{'height':'100%'}}>
            <div style={{'width':'90%', 'whiteSpace':'nowrap'}}>
              <h5 id='simulated'style={{'color':'#f1c31a'}}>Actual</h5>
              <p id='portfolio-date' style={{'marginLeft': '2px', 'marginTop':'-5px'}}>As of {mouseOverPointTracking.x}</p>
            </div>
            <div className='vertical' style={{'height':'100%'}}>
              <div  className='flex'>
                <div className='circle-legend' id='study-bm'/>
                <div className='amount medium'>{amountActual}</div>
              </div>
              <div >
                <p id='range' className='smaller right'></p>
              </div>
            </div>
          </div>
        </button>
      )
    }

    return (
      <div className='flex-column-mobile totals' style={{'marginBottom': '10px', 'marginTop': '5px', 'gridGap': '10px'}}>
        <div className='totals' style={{'width':'33%','display':'flex', 'marginTop':'0px', 'marginBottom':'0px'}}>
          <Difference />
        </div>
        <div className='totals' style={{'width':'66%','display':'flex', 'marginTop':'0px', 'marginBottom':'0px'}}>
          <Approved />
          <Actual />
        </div>
      </div>
    )
  }


  // syncs all charts onMouseOver
  function onMouseOverSyncCharts(e, chartNames, all=false) {
    // looping through all charts
    Highcharts.charts.forEach(chart => {
      // only apply code to given charts
      if (chart && (all || chartNames.includes(chart.title.textStr)))
      {
        // collects each point at current location on x-axis e.g. for rates chart, there would be 3 points (because there are 3 series)
        let pointsTooltip = []
        // looping through series
        chart.series.forEach(s => {
          // looping through points
          s.points.forEach(p => {
            // since mouseOut behavior is unpredictable and laggy for reserve chart, same functionality is included here
            // (if marker is currently over point, remove it)
            if (p.state == 'hover') p.setState('')

            // syncs points between the graphs
            if (p.index === e.target.index)
            {
              // sets point state to "hover" (dots appear on graph at given point)
              p.setState('hover')
              pointsTooltip.push(p)

              // refreshing crosshair of given charts
              chart.xAxis[0].drawCrosshair(e, p)
            }
          })
        })
        // refreshing tooltip of synced charts
        if (pointsTooltip.length > 0)
        {
          // this method takes an array of point objects that it uses to display the tooltip
          chart.tooltip.refresh(pointsTooltip)
        }
      }
    })
  }

  return (
    <div>
      {showOffCanvas && <OffCanvasBudget show={showOffCanvas} selectedBuilding={auth.selectedBuilding} onSyncCharts={handleSyncCharts} onClose={handleCloseOffCanvas} dataCategoryActual={dataCategoryActual} dataCategoryApproved={dataCategoryApproved} />}
      {showMissingData && <MissingData text="There is not budget data uploaded to your account." pageSource="cashflow" />}
      {dataExists && (
        <div className={'content' + (showOffCanvas?' show-offcanvas':'')}>
          <div className='reserve-fund'>
            <div className='intro'>
              <h1 className='title'>Budget Analysis</h1>
              <p className='information'>Visualize historical trends for your buildings budgets. Flag outliers and track spending.</p>
            </div>

            <div className='header' style={{'paddingTop': '0px'}}>
              <div className='left'>
                <img src={logoBlue} alt="Yellow Logo" width="60" height="60" />
                <p className='section-header'>Budget Categories</p>
              </div>
              <div className='divider-1'></div>
              <div className='switch'>
                <Form>
                  <Form.Check
                    type="switch"
                    id="blue"
                    label="See Percentage Area"
                    onClick={() => setShowBudgetAreaChart(!showBudgetAreaChart)}
                  />
                </Form>
              </div>
            </div>

            <div className='section-description'>This chart is showing the actual amount spent for each primary cateogry of the {auth.selectedBuilding} Operating Budget for the last 5 years.</div>

            <TotalsCategories />

            <div className='component' id='reserve-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
              {showBudgetAreaChart &&
              <div className="component-container" id='reserve-chart'>
                {dataCategoryActual && <HighchartsReact highcharts={Highcharts} options={chartOptionsActualBudgetArea} />}
              </div>}
              {!showBudgetAreaChart &&
              <div className="component-container" id='reserve-chart'>
                {dataCategoryActual && <HighchartsReact highcharts={Highcharts} options={chartOptionsActualBudget} />}
              </div>}
            </div>

            <div className='header'>
              <div className='left'>
                <img src={logoGreen} alt="Blue Logo" width="60" height="60" />
                  <p className='section-header'>Tracking - {categories[selectedItemNumber]}</p>
              </div>
              <div className='divider-2'></div>
              <DropdownButton variant={"success"} title={categories[selectedItemNumber]} size='sm' style={{'marginLeft': '10px'}}>
                {Object.keys(categories).map((itemnumber) => <Dropdown.Item onClick={() => setSelectedItemNumber(itemnumber)}>{categories[itemnumber]}</Dropdown.Item>)}
              </DropdownButton>
            </div>

            <div className='section-description'>This chart is showing the actual vs approved amount spent for the {categories[selectedItemNumber]} category of the {auth.selectedBuilding} Operating Budget for the last 5 years. To select a new category click on the dropdown button on the right side of this section.</div>
            
            <TotalsTracking />
            
            <div className='component' id='reserve-chart'>
              <div className="component-container" id='reserve-chart'>
                {dataCategoryActual && <HighchartsReact highcharts={Highcharts} options={chartOptionsTracking} />}
              </div>
            </div>

            <div className='header' id='details'>
              <div className='left'>
                <img src={logoYellow} alt="Green Logo" width="60" height="60" />
                <p className='section-header'>Category Detail</p>
              </div>
              <div className='divider-3'></div>
              <DropdownButton variant={"warning"} title={subCategories[selectedItemNumber][selectedSubItemNumber]} size='sm' style={{'marginLeft': '10px'}}>
                {Object.keys(subCategories[selectedItemNumber]).map((itemnumber) => <Dropdown.Item onClick={() => setSelectedSubItemNumber(itemnumber)}>{subCategories[selectedItemNumber][itemnumber]}</Dropdown.Item>)}
              </DropdownButton>
            </div>

            <div className='section-description'>These charts are showing details for the {subCategories[selectedSubItemNumber]} sub category of the {auth.selectedBuilding} Operating Budget for the last 5 years. To select a new sub category click on the dropdown button on the right side of this section. Left chart: breakdown of all subcategories. Right chart: actual vs approved for selected sub category.</div>
            <div className='component space-between detail-charts'>
              <div className='component-container' id='detail-chart'>
                <HighchartsReact highcharts={Highcharts} options={chartOptionsDetailsActual} />
              </div>
              <div className='component-container' id='detail-chart'>
                <HighchartsReact highcharts={Highcharts} options={chartOptionsDetailsTracking} />
              </div>
            </div>

            <OverlayTrigger placement="left" overlay={<Popover><PopoverBody>Learn more about this page</PopoverBody></Popover>}>
            <button onClick={() => setShowOffCanvas(!showOffCanvas)} className='understand-page-button' style={{position: 'fixed', bottom: '20px', right: '20px'}}>
              <img src={greyQuestion} alt="Blue Question Mark" width="50" height="50" />
            </button>
            </OverlayTrigger>

          </div>
        </div>
      )}
    </div>
  )
}
