import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import RangeSlider from 'react-bootstrap-range-slider';
import { ProgressBar } from 'react-bootstrap'

// context
import { authContext } from '../authContext.js'

// Components
import MissingData from '../MissingData.js'

// Helper Functions
import { onMouseOutSync, helperPlot, helperPlotRange, chartExtremes } from '../../lib/helpers/highchartsHelpers.js';
import { formatAmount, formatAmountKM, getWeight } from '../../lib/helpers/formattingHelpers.js';

export default function FreeReport() {
  // context
  const auth = useContext(authContext)

  const currentYear = new Date().getFullYear()

  //------------------------------------------------------------------------------------------------------------------
  // Component State
  //------------------------------------------------------------------------------------------------------------------

  // get available rf studies
  const [availableRFStudies, setAvailableRFStudies] = useState([]);
  // const [selectedYear, setSelectedYear] = useState(null);

  // all of the data
  const [dataCashflow, setDataCashflow] = useState([]); // array of 5 arrays, for each possible risk input

  // chart options
  const [chartOptionsClosing, setChartOptionsClosing] = useState({title: { text: ''}})
  const [chartOptionsNetFunding, setChartOptionsNetFunding] = useState({title: { text: ''}})
  const [chartOptionsReserve, setChartOptionsReserve] = useState({title: { text: ''}})

  // chart interactive drawing setMouseOver
  const [mouseOver, setMouseOver] = useState(true)
  const [mouseOverEvent, setMouseOverEvent] = useState({})
  const [mouseOverPoint, setMouseOverPoint] = useState({})
  const [mouseOverYear, setMouseOverYear] = useState(null)

  // data per line per chart - reserve fund chart
  const [dataVCRange, setDataVCRange] = useState([]); // single array of x,y pair arrays
  const [dataVC, setDataVC] = useState([]); // single array for chart
  const [dataStudyBenchmark, setDataStudyBenchmark] = useState([]); // single array
  const [dataTrueBenchmark, setDataTrueBenchmark] = useState([]); // single array
  const [dataTrueBenchmarkRange, setDataTrueBenchmarkRange] = useState([]); // single array
  const [dataExtremes, setDataExtremes] = useState({});
  const [dataExtremesClosing, setDataExtremesClosing] = useState({});
  const [dataExtremesFunding, setDataExtremesFunding] = useState({});
  const [dataMinYear, setDataMinYear] = useState(null);
  const [dataMaxYear, setDataMaxYear] = useState(null);
  
  // totals header
  const [fireUpdateHeader, setFireUpdateHeader] = useState(true);
  const [headerCustom, setHeaderCustom] = useState(0);
  const [headerCustomRange, setHeaderCustomRange] = useState(0);
  const [headerBenchmark, setHeaderBenchmark] = useState(0);
  const [headerBenchmarkRange, setHeaderBenchmarkRange] = useState(0);
  const [headerDiff, setHeaderDiff] = useState(0);
  const [headerYear, setHeaderYear] = useState(0);
  const [headerNumYears, setHeaderNumYears] = useState(0);

  // contributions chart
  // const [dataContribution, setDataContribution] = useState([])
  // const [dataExpenditures, setDataExpenditures] = useState([])

  const [dataNetFunding, setDataNetFunding] = useState([])
  const [dataClosingBalance, setDataClosingBalance] = useState([])

  // building settings
  const [riskLevel, setRiskLevel] = useState(1);

  // state for grade
  const [dataReportCard, setDataReportCard] = useState({})
  const [dataGrade, setDataGrade] = useState(["", ""]) // ["A", "Description"]
  const [dataGradeBreakdown, setDataGradeBreakdown] = useState([])
  const [dataTestBreakdown, setDataTestBreakdown] = useState([])
  // const [dataReportCard, setDataReportCard] = useState({});
  // const [dataGrade, setDataGrade] = useState(["", ""]); // ["A", "Description"]
  // const [dataGradeInfo, setDataGradeInfo] = useState("");
  // const [dataGradeBreakdown, setDataGradeBreakdown] = useState([])

  // data exists flags
  const [dataExists, setDataExists] = useState(false);
  const [showMissingData, setShowMissingData] = useState(false);

  // totals header
  const [labelPoint, setLabelPoint] = useState({})

  //------------------------------------------------------------------------------------------------------------------
  // Helper Functions
  //------------------------------------------------------------------------------------------------------------------

  function helperGradeBreakdown(gradeBreakdown) {
    const outputBreakdown = []
    gradeBreakdown.forEach(note => {
      const tempBreakdown = note.split(":")
      if (tempBreakdown[0] == "Max Cash Holdback Test") tempBreakdown.push("A Reserve Fund Study assumes that all cash is invested and earns interest yearly, but in reality, condo boards often hold some cash for emergencies. This results in the actual benchmark being lower than the study's projected benchmark, as less money is invested and earning interest than assumed.")
      else if (tempBreakdown[0] == "Inflation Adjustment Test") tempBreakdown.push("To obtain a more realistic view of how interest and inflation rates change, we model the probabilities using historical data and simulate the forecasts thousands of times using a technique called the Monte Carlo Method.")
      else if (tempBreakdown[0] == "Expense Variance (In Year) Test") tempBreakdown.push("The simulation will vary the scheduled expenses from reserve fund study from 5% to 25% across entire simulation.")
      else if (tempBreakdown[0] == "Unexpected One Time Expense Test") tempBreakdown.push("The simulation will insert a single random $100k - $500k expense dropped into a random year in the simulation.")
        
      outputBreakdown.push(tempBreakdown)
    })
    return outputBreakdown
  }

  // for updating all state variables for storing chart data (this is only meant to reduce lines of code because it's called multiple times)
  function helperUpdateChartData(dataFromServer) {
    setDataMinYear(dataFromServer['year'][0])
    setDataMaxYear(dataFromServer['year'][dataFromServer['year'].length-1])

    setDataCashflow(dataFromServer)

    // setDataContribution(helperPlot(dataFromServer['year'], dataFromServer['contribution']))
    // setDataExpenditures(helperPlot(dataFromServer['year'], dataFromServer['expenditureia']))

    var netFunding = []
    dataFromServer['contribution'].map((contribution, index) => {
      netFunding.push(contribution - dataFromServer['expenditureia'][index])
    })
    setDataNetFunding(helperPlot(dataFromServer['year'], netFunding))
    setDataClosingBalance(helperPlot(dataFromServer['year'], dataFromServer['closingbalance']))

    // Closing Balance
    setDataStudyBenchmark(helperPlot(dataFromServer['year'], dataFromServer['closingbalance']))

    // Benchmark Projections
    setDataTrueBenchmark(helperPlot(dataFromServer['year'], dataFromServer['base']))
    setDataTrueBenchmarkRange(helperPlotRange(dataFromServer['year'], dataFromServer['base_low'], dataFromServer['base_high']))

    // Vertical City Projections
    setDataVC(helperPlot(dataFromServer['year'], dataFromServer['scenario1']))
    setDataVCRange(helperPlotRange(dataFromServer['year'], dataFromServer['scenario1_low'], dataFromServer['scenario1_high']))
  }

  //------------------------------------------------------------------------------------------------------------------
  // Component Hooks
  //------------------------------------------------------------------------------------------------------------------

  // main hook for getting all data for page
  useEffect(() => {
    // on page initial load - fetch RF studies, cashflow performance and building settings (in that order)
    if (auth.isContextSecured)
    {
      // fetching rfstudies to find newest rfs year
      let url_query = `${process.env.REACT_APP_FLASK_IP}/data/rfstudies`
      let body = { "email": auth.email, "building_short_name": auth.selectedBuilding }

      axios.defaults.withCredentials = true
      axios.post(url_query, body)
        .then(function (resp) {
          let dataFromServer = resp.data

          // handle no RF studies - show MissingData Component
          if (Object.keys(dataFromServer).length === 0)
          {
            setShowMissingData(true)
            return
          }
          setDataExists(true)

          setAvailableRFStudies(dataFromServer)

          // most recent year
          var newestYear = dataFromServer[0]["year"]

          // fetch 5 fixed scenarios
          url_query = `${process.env.REACT_APP_FLASK_IP}/data/cashflow5`
          body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "year": newestYear }

          axios.defaults.withCredentials = true
          axios.post(url_query, body)
            .then(function (resp) {
              if (resp.data["error"])
              {
                setDataExists(false)
                return
              }

              helperUpdateChartData(resp.data)
            })

          // TODO the report card fetched should be processed with parameters specifically set for the Free Report Page
          // fetch report card for grading component
          url_query = `${process.env.REACT_APP_FLASK_IP}/data/reportcard`
          body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "year": newestYear }
          
          axios.defaults.withCredentials = true
          axios.post(url_query, body)
            .then(function (resp) {
              if (resp.data["error"])
              {
                setDataExists(false)
                return
              }
              
              // set default report card data from server
              setDataReportCard(resp.data)
              let strGrade = resp.data["base"]["grade"][0].split(":") // 'A+: 1000/1000 (0%) test simulations complete'
              setDataGrade(strGrade)
              setDataTestBreakdown(resp.data["base"]["subtest"])
              setDataGradeBreakdown(helperGradeBreakdown(resp.data["base"]["test"]["data"]))
            })
        })
    }
  }, [auth.isContextSecured]) // only trigger if user selects the year, otherwise this effect will be run twice on startup

  // Risk Slider Hook
  useEffect(() => {
    if (dataVC.length > 0)
    {
      setDataVC(helperPlot(dataCashflow['year'], dataCashflow['scenario' + riskLevel]))
      setDataVCRange(helperPlotRange(dataCashflow['year'], dataCashflow['scenario' + riskLevel + '_low'], dataCashflow['scenario' + riskLevel + '_high']))
    }
  }, [riskLevel])  

  // Highcharts Chart Options Hook
  useEffect(() => {
    //resetReserveChart()
    if (dataVCRange.length > 0)
    {
      const extremes = chartExtremes([dataCashflow['scenario1_low'], dataCashflow['scenario5_high'], dataCashflow['closingbalance']])
      setDataExtremes(extremes) // using for drawing on chart (coordinates of points change depending on y-axis label)

      const extremesClosing = chartExtremes([dataClosingBalance])
      setDataExtremesClosing(extremesClosing) // using for drawing on chart (coordinates of points change depending on y-axis label)

      const extremesFunding = chartExtremes([dataNetFunding])
      setDataExtremesFunding(extremesFunding) // using for drawing on chart (coordinates of points change depending on y-axis label)

      setChartOptionsClosing({
        chart: {
          borderRadius: 10, // same as other divs in index.css 
          backgroundColor: "#ffffff",
          height: '40%'
        },
        title: {
          text: 'closing',
          style: {
            display: 'none'
          }
        },
        legend: {
          itemStyle: {
            'cursor': 'default'
          }
        },
        tooltip: {
          split: false,
          crosshairs: true,
          shared: true,
          formatter: function () {
            const htmlTooltip = 
            `<div>\
              Year: <b>${this.points[0].x}</b>\
              <br/>\
              <span style='color:#5d98d1'>●</span> \
              Closing Balance: <b>${formatAmount(this.points[0].y, 0)}</b>\
              <br/>\
            </div>`
            return htmlTooltip
          }
        },
        xAxis: [{
          visible: true,
          // draws line for current year
          lineWidth: 0,
          plotLines: [{
            color: '#5d98d1',
            width: 1,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick to be on current year
          tickPositions: [dataMinYear, currentYear, dataMaxYear],
        }],
        yAxis: [{
          visible: true,
          tickPositions: [0],
          title: { enabled: false, text: "Canadian Dollars" },
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            formatter: function () {
              if (this.value != null)
              {
                return formatAmountKM(this.value)
              }
            }
          },
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [extremesClosing.min, 0, extremesClosing.max]
            }
          }
        }],
        series: [
          {
            name: 'Closing Balance',
            type: 'column',
            data: dataClosingBalance,
            color: '#5d98d1',
            marker: {
              enabled: false
            }
          }
        ],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncRF(e, ['funding'])
                },
                mouseOut() {
                  onMouseOutSync()
                }
              }
            },
            events: {
              legendItemClick: function () {
                return false;
              }
            },
            stickyTracking: true
          }
        },
        credits: {
          enabled: false
        }
      })

      setChartOptionsNetFunding({
        chart: {
          borderRadius: 10, // same as other divs in index.css 
          backgroundColor: "#ffffff",
          height: '40%'
        },
        title: {
          text: 'funding',
          style: {
            display: 'none'
          }
        },
        legend: {
          itemStyle: {
            'cursor': 'default'
          }
        },
        tooltip: {
          valuePrefix: '$',
          valueDecimals: 2,
          split: false,
          crosshairs: true,
          shared: true,
          formatter: function () {
            const htmlTooltip = 
            `<div>\
              Year: <b>${this.points[0].x}</b>\
              <br/>\
              <span style='color:#a7b661'>●</span> \
              Net Funding: <b>${formatAmount(this.points[0].y, 0)}</b>\
              <br/>\
            </div>`
            return htmlTooltip
          }
        },
        xAxis: [{
          visible: true,
          // draws line for current year
          lineWidth: 0,
          plotLines: [{
            color: '#5d98d1',
            width: 1,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick to be on current year
          tickPositions: [dataMinYear, currentYear, dataMaxYear],
        }],
        yAxis: [{
          visible: true,
          tickPositions: [0],
          title: { enabled: false, text: "Canadian Dollars" },
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            formatter: function () {
              if (this.value != null)
              {
                return formatAmountKM(this.value)
              }
            }
          },
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [extremesFunding.min, 0, extremesFunding.max]
            }
          }
        }],
        series: [
          {
            name: 'Net Funding',
            type: 'column',
            data: dataNetFunding,
            color: '#a7b661',
            marker: {
              enabled: false
            }
          }
        ],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncRF(e, ['closing'])
                },
                mouseOut() {
                  onMouseOutSync()
                }
              }
            },
            events: {
              legendItemClick: function () {
                return false;
              }
            },
            stickyTracking: true
          }
        },
        credits: {
          enabled: false
        }
      })

      // setting chart options - this is done to ensure that each chart is not rerendered when risk level is changed, improving efficiency + preventing page from snapping to top
      setChartOptionsReserve({
        chart: {
          type: "line",
          height: '40%',
          borderRadius: 10, // same as other divs in index.css  
          backgroundColor: "#ffffff",
          spacing: [20, 20, 20, 20],
          events: {
            // set label point on initial chart load
            render: function () {
              if (this.mainLabel == undefined)
              {
                var length = this.series[1].points.length
                var point = this.series[1].points[length - 1]
                const series = this.series
                setHeaderCustom(series[1].points[length-1].y)
                setHeaderCustomRange([series[0].points[length-1].options.low, series[0].points[length-1].options.high])
                setHeaderBenchmark(series[2].points[length-1].y)
                setHeaderBenchmarkRange([series[3].points[length-1].options.low, series[3].points[length-1].options.high])
                setHeaderDiff(series[1].points[length-1].y - series[2].points[length-1].y)
                setHeaderYear(series[1].points[length-1].x)
                setHeaderNumYears(series[1].points[length-1].x - currentYear)
                //setLabelPoint(point)
                //refreshReserveLabel(point)
                
                // draw label so condition does not fire again
                this.mainLabel = this.renderer.label(
                  '',
                  50,
                  60,
                  null, null, null, true
                ).attr({
                  zIndex: 4
                }).add()
              }
              // else
              // {
              //   if (!mouseOver)
              //   {
              //     var lastIndex = this.series[1].points.length - 1
              //     var point = this.series[1].points[lastIndex]
              //     setLabelPoint(point)  
              //   }
              // }
            }
          }
        },
        title: {
          text: "reserve-fund-comparison",
          style: {
            display: 'none'
          }
        },
        tooltip: {
          // crosshairs: true,
          shared: true,
          // split: false,
          formatter: function () {
            return false
          }
        },
        series: [
          {
            name: 'Vertical City Range',
            type: 'areasplinerange',
            data: dataVCRange,
            color: '#a7b661',
            lineWidth: 0,
            fillOpacity: 0.3,
            marker: {
              enabled: false
            }
          },
          {
            name: 'Vertical City',
            type: 'spline',
            data: dataVC,
            color: '#a7b661',
            lineWidth: 2.5,
            marker: {
              enabled: false,
            }
          },
          {
            name: 'True Benchmark',
            type: 'spline',
            data: dataTrueBenchmark,
            color: '#5d98d1',
            lineWidth: 2,
            marker: {
              enabled: false,
            }
          },
          {
            name: 'True Benchmark Range',
            type: 'areasplinerange',
            data: dataTrueBenchmarkRange,
            color: '#5d98d1',
            lineWidth: 0,
            fillOpacity: 0.3,
            marker: {
              enabled: false
            }
          }
        ],
        legend: {
          enabled: false
        },
        plotOptions: {
          // disable markers for all series
          series: {
            marker: {
              states: {
                hover: {
                  enabled: false
                }
              }
            },
            // mouse over chart event
            point: {
              events: {
                mouseOver(e) {
                  // mouseOverYear now triggers useEffect which does chart drawing
                  setMouseOverPoint(this)
                  setMouseOverEvent(e)
                  setMouseOverYear(e.target.category) // 2048
                }
              }
            },
            events: {  
              legendItemClick: function () {
                return false
              }
            }
          }
        },
        xAxis: [{
          // visible: false,
          crosshair: true,
          lineWidth: 0,
          visible: true,
          startOnTick: true,
          endOnTick: false,
          maxPadding: 0.03,
          // draws a line where the current year is
          plotLines: [{
            color: '#000000',
            width: 2,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick positions to be start of graph, end of graph, and current year
          tickPositions: [dataMinYear, currentYear, dataMaxYear],
        }],
        yAxis: [{
          visible: true,
          title: { enabled: false },
          max: extremes.max,
          // makes minimum always 0, unless minimum point is less than 0
          min: extremes.min > 0 ? 0 : extremes.min,
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            formatter: function () {
              if (this.value != null)
              {
                return formatAmountKM(this.value)
              }
            }
          },
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [extremes.min, 0, extremes.max]
            }
          }
        }],
        credits: {
          enabled: false
        }
      })
      
      // needed a state that only changes when risk slider moves AND chart options are finished updating
      setFireUpdateHeader(!fireUpdateHeader)
    }
  }, [dataVCRange])

  // risk slider changes, chart options updates, then this fires
  useEffect(() => {
    if (Highcharts.charts.length > 0)
    {
      const chartIndex = Highcharts.charts.length - 1 // on this page the chart is last of 3
      const lastIndex = Highcharts.charts[chartIndex].series[1].points.length - 1
      const series = Highcharts.charts[chartIndex].series

      setHeaderCustom(() => series[1].data[29].y)
      setHeaderCustomRange(() => [series[0].points[lastIndex].options.low, series[0].points[lastIndex].options.high])
      setHeaderBenchmark(() => series[2].points[lastIndex].y)
      setHeaderBenchmarkRange(() => [series[3].points[lastIndex].options.low, series[3].points[lastIndex].options.high])
      setHeaderDiff(() => series[1].points[lastIndex].y - series[2].points[lastIndex].y)
      setHeaderYear(() => series[1].points[lastIndex].x)
      setHeaderNumYears(() => series[1].points[lastIndex].x - currentYear)
    }
  }, [fireUpdateHeader])
  
  // Mouse Over X-Axis Tick Hook - triggered when mouse over main chart point changes
  useEffect(() => {
    if (mouseOverYear)
    {
      //----------------------------------------------------
      // Transparent box to right of hovered point
      //----------------------------------------------------

      var width = mouseOverPoint.series.chart.plotWidth - mouseOverPoint.plotX,
          height = mouseOverPoint.series.chart.plotHeight
      
      // destory box if already exists
      if (mouseOverPoint.series.chart.transparentBox != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.transparentBox).length > 0) mouseOverPoint.series.chart.transparentBox.destroy()
      }

      // adjust x coordinates of box and markers depending on starting position of chart (varies depending on y-axis ticks)
      var xAxisPadding = 0
      if (dataExtremes.max < 10000000)
      {
        if (dataExtremes.min < 0) xAxisPadding = 10
        else xAxisPadding = 5
      }
      else if (dataExtremes.min < 0)
      {
        if (dataExtremes.min < -10000000) xAxisPadding = 8
        else xAxisPadding = 10
      }
      
      // draw transparent box to right of hovered point
      mouseOverPoint.series.chart.transparentBox = mouseOverPoint.series.chart.renderer.rect(mouseOverPoint.plotX + 65+ xAxisPadding, 0, width + 15, height + 19, 1, 0)
        .attr({
          fill: "rgba(255,255,255,0.75)",
          zIndex: 3
        })
        .add()

      //----------------------------------------------------
      // Draw Markers
      //----------------------------------------------------
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.marker1 != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.marker1).length > 0)
        {
          mouseOverPoint.series.chart.marker1.destroy()
          mouseOverPoint.series.chart.marker2.destroy()
        }
      }

      // True Benchmark (blue marker)
      mouseOverPoint.series.chart.marker1 = mouseOverPoint.series.chart.renderer.rect(
        mouseOverPoint.plotX + 59.9 + xAxisPadding, 
        mouseOverPoint.series.chart.series[2].data[mouseOverPoint.index].plotY + 15.5, 
        10, 10, 5, 0
      ).attr({
        fill: "#5d98d1", 
        zIndex: 4
      }).add()

      // Custom
      mouseOverPoint.series.chart.marker2 = mouseOverPoint.series.chart.renderer.rect(
        mouseOverPoint.plotX + 59.9 + xAxisPadding, 
        mouseOverPoint.series.chart.series[1].data[mouseOverPoint.index].plotY + 15.5, 
        10, 10, 5, 0
      ).attr({
        fill: "#a7b661", 
        zIndex: 4
      }).add()        
      
      //----------------------------------------------------
      // Draw Data Label (next to points)
      //----------------------------------------------------
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.dataLabel != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.dataLabel).length > 0) mouseOverPoint.series.chart.dataLabel.destroy()
      }
      
      var seriesA = mouseOverPoint.series.chart.series[2].data[mouseOverPoint.index].plotY
      var y // calc middle point between 2 points
      var value // calc difference of 2 points 
      
      var seriesC = mouseOverPoint.series.chart.series[1].data[mouseOverPoint.index].plotY
      y = ((seriesA - seriesC) / 2) + seriesC
      value = mouseOverPoint.series.chart.series[1].data[mouseOverPoint.index].y - mouseOverPoint.series.chart.series[2].data[mouseOverPoint.index].y

      var color = (value > 0) ? 'green' : 'red'
      var html = `<div className='data-label' id=${color}>${(color == 'green' ? '+' : '')}${formatAmountKM(value)}</div>`
      var xAxisLabelPadding = 72

      if (mouseOverPoint.index == mouseOverPoint.series.points.length - 1) xAxisLabelPadding = 62

      // TODO styling, bolder
      mouseOverPoint.series.chart.dataLabel = mouseOverPoint.series.chart.renderer.label(
        html,
        mouseOverPoint.plotX + xAxisLabelPadding + xAxisPadding,
        y + 6.8, // TODO adjust this value depending on shape/size of label
        null, null, null, true
      ).attr({
        zIndex: 4
      }).add()

      //----------------------------------------------------
      // Update Main Chart Label
      //----------------------------------------------------

      //setLabelPoint(mouseOverPoint)
      const series = mouseOverPoint.series.chart.series
      //const length = series[1].points.length
      setHeaderCustom(series[1].points[mouseOverPoint.index].y)
      setHeaderCustomRange([series[0].points[mouseOverPoint.index].options.low, series[0].points[mouseOverPoint.index].options.high])
      setHeaderBenchmark(series[2].points[mouseOverPoint.index].y)
      setHeaderBenchmarkRange([series[3].points[mouseOverPoint.index].options.low, series[3].points[mouseOverPoint.index].options.high])
      setHeaderDiff(series[1].points[mouseOverPoint.index].y - series[2].points[mouseOverPoint.index].y)
      setHeaderYear(series[1].points[mouseOverPoint.index].x)
      setHeaderNumYears(series[1].points[mouseOverPoint.index].x - currentYear)

      //refreshReserveLabel(mouseOverPoint)

      //----------------------------------------------------
      // Sync All Charts
      //----------------------------------------------------

      onMouseOverSyncRF(mouseOverEvent, ['rates', 'expenditures'])

      //----------------------------------------------------
      // Draw X Axis Tick
      //----------------------------------------------------

      const tempReserveOptions = {
        xAxis: [{
          tickPositions: [dataMinYear, currentYear, mouseOverYear, dataMaxYear]
        }],
      }
      setChartOptionsReserve(tempReserveOptions)      
    }
  }, [mouseOverYear, auth.isContextSecured])

  // Mouse Out Chart Component Hook
  useEffect(() => {
    if (Object.keys(mouseOverPoint).length == 0)
    if (mouseOverPoint.index == null) return
    if (mouseOver == false)
    {
      //----------------------------------------------------
      // Destroy Drawn SVGs
      //----------------------------------------------------

      // destory box if already exists
      if (mouseOverPoint.series.chart.transparentBox != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.transparentBox).length > 0) mouseOverPoint.series.chart.transparentBox.destroy()
      }
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.marker1 != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.marker1).length > 0)
        {
          mouseOverPoint.series.chart.marker1.destroy()
          mouseOverPoint.series.chart.marker2.destroy()
        }
      }
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.dataLabel != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.dataLabel).length > 0) mouseOverPoint.series.chart.dataLabel.destroy()
      }
      
      //----------------------------------------------------
      // Reset Main Chart Label
      //----------------------------------------------------

      //resetReserveChart()
      onMouseOutSync(['closing', 'funding'])
      //var lastIndex = mouseOverPoint.series.points.length - 1
      //var point = mouseOverPoint.series.points[lastIndex]
      //setLabelPoint(point)
      const series = mouseOverPoint.series.chart.series
      const length = series[1].points.length
      setHeaderCustom(series[1].points[length-1].y)
      setHeaderCustomRange([series[0].points[length-1].options.low, series[0].points[length-1].options.high])
      setHeaderBenchmark(series[2].points[length-1].y)
      setHeaderBenchmarkRange([series[3].points[length-1].options.low, series[3].points[length-1].options.high])
      setHeaderDiff(series[1].points[length-1].y - series[2].points[length-1].y)
      setHeaderYear(series[1].points[length-1].x)
      setHeaderNumYears(series[1].points[length-1].x - currentYear)
  
      //----------------------------------------------------
      // Remove X Tick
      //----------------------------------------------------

      const tempReserveOptions = {
        xAxis: [{
          tickPositions: [dataMinYear, currentYear, dataMaxYear]
        }],
      }
      setChartOptionsReserve(tempReserveOptions)
    }
  }, [mouseOver])
    
  function ReportCardMeter() {
    var score = dataGrade[1].split('(')[1].split('%')[0]
    var word
    var description
    
    if (score < 50) 
    {
      word = "Fail"
      description = "This is a big red flag. You should be taking action immediately."
    }
    else if (score < 60) 
    {
      word = "Bad"
      description = "Things aren't looking good. Prioritize and act now."
    }
    else if (score < 70)
    {
      word = "Fair"
      description = "Not bad, but could easily get there. You probably want to prioritize this."
    }
    else if (score < 90)
    {
      word = "Good"
      description = "Looks stable but some tweaks might be in order."
    }
    else if (score < 100)
    {
      word = "Incredible"
      description = "Keep doing what you're doing."
    }
    else if (score == 100)
    {
      word = "Unicorn"
      description = "This is probably a bug."
    }

    if (dataGradeBreakdown.length==0) return <></>
    dataGradeBreakdown[0][0] = 'Cash Holdback Test'
    dataGradeBreakdown[2][0] = 'Expense Variance Test'
    dataGradeBreakdown[3][0] = 'One Time Expense Test'
    return (
      <div className='flex'>
        <div className='component-container' id="meter" style={{'width':'60%', 'margin': '0px 5px', 'padding': '20px 0px'}}>
          <div style={{'width': '90%'}}>
            <div className='progressbar-semicircle flex-center' style={{'--value': score.toString(), 'margin': 'auto'}}>
              <h1>{score}</h1>
              <h5>{word}</h5>
              <p style={{width:'75%', 'textAlign': 'center', 'lineHeight': '1'}}>{description}</p>
            </div>
          </div>
        </div>
        <div className='component-container' id="meter" style={{'width':'40%', 'margin': '0px 5px'}} >
          <div style={{'width':'90%', 'margin': '6px 0px'}}>
            <p>{dataGradeBreakdown[0][0]}</p>
            <ProgressBar variant="primary" now={getWeight(dataGradeBreakdown[0][1])} style={{'marginTop': '5px'}} />
          </div>
          <div style={{'width':'90%', 'margin': '6px 0px'}}>
            <p>{dataGradeBreakdown[1][0]}</p>
            <ProgressBar variant="primary" now={getWeight(dataGradeBreakdown[1][1])} style={{'marginTop': '5px'}} />
          </div>
          <div style={{'width':'90%', 'margin': '6px 0px'}}>
            <p>{dataGradeBreakdown[2][0]}</p>
            <ProgressBar variant="primary" now={getWeight(dataGradeBreakdown[2][1])} style={{'marginTop': '5px'}} />
          </div>
          <div style={{'width':'90%', 'margin': '6px 0px'}}>
            <p>{dataGradeBreakdown[3][0]}</p>
            <ProgressBar variant="primary" now={getWeight(dataGradeBreakdown[3][1])} style={{'marginTop': '5px'}} />
          </div>
        </div>
      </div>
    )
  }

  function handleChangeRiskLevel(e) {
    setRiskLevel(e.target.value)
  }

  // function TotalsHeader() {
  //   if (Object.keys(labelPoint).length === 0 ) return <></>
  //   const chart = labelPoint.series.chart;
  //   const index = labelPoint.index
  //   const year = labelPoint.x

  //   var benchmark = null
  //   var benchmarkRange = null
  //   var vc = null
  //   var vcRange = null

  //   // sets label data
  //   chart.series.forEach(s => {
  //     if (s.name === "True Benchmark")
  //     {
  //       benchmark = s.yData[index]
  //     }
  //     else if (s.name === "True Benchmark Range")
  //     {
  //       benchmarkRange = s.yData[index]
  //     }
  //     else if (s.name === "Vertical City")
  //     {
  //       vc = s.yData[index]
  //     }
  //     else if (s.name === "Vertical City Range")
  //     {
  //       vcRange = s.yData[index]
  //     }
  //   })

  //   var difference = vc - benchmark
  //   var pos = false
  //   if (difference > 0) pos = true
  //   var numYears = year - currentYear

  //   function TrueBenchmark() {
  //     return (
  //       <div className='component-container' style={{'width':'33%', 'margin-right': '5px', 'padding-top': '0px', 'padding-bottom': '10px'}}>
  //         <div className='space-between'>
  //           <h5 style={{'margin-top': '20px', 'color': '#5d98d1', 'width': '20px'}}>True Benchmark</h5>
  //           <div className='vertical'>
  //             <div  className='space-between'>
  //               <div className='circle-legend' id='true-bm' />
  //               <div className='amount medium'>{formatAmount(benchmark, 0)}</div>
  //             </div>
  //             <div style={{'text-align': 'top'}}>
  //               <p id='portfolio-date' style={{'margin-left': '2px'}}>As of {year}</p>
  //               <p className='smaller right' style={{'margin-top': '-28px'}}>${formatAmountKM(benchmarkRange[0], 0) + " to $" + formatAmountKM(benchmarkRange[1], 0)}</p>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     )
  //   }

  //   function CustomBenchmark() {
  //     return (
  //       <div className='component-container' style={{'width':'33%', 'margin': '0px 5px', 'padding-top': '0px', 'padding-bottom': '10px'}}>
  //         <div className='space-between'>
  //           <h5 style={{'margin-top': '20px', 'color': '#a7b661', 'width': '20px'}}>Custom Benchmark</h5>
  //           <div className='vertical'>
  //             <div  className='space-between'>
  //               <div className='circle-legend' id='vc-bm' />
  //               <div className='amount medium'>{formatAmount(VC, 0)}</div>
  //             </div>
  //             <div style={{'text-align': 'top'}}>
  //               <p id='portfolio-date' style={{'margin-left': '2px'}}>As of {year}</p>
  //               <p className='smaller right' style={{'margin-top': '-28px'}}>${formatAmountKM(vcRange[0], 0) + " to $" + formatAmountKM(vcRange[1], 0)}</p>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     )
  //   }

  //   function Difference() {
  //     return (
  //       <div className='component-container' style={{'width':'33%', 'margin-left': '5px', 'padding-top': '0px', 'padding-bottom': '10px'}}>
  //         <div className='space-between'>
  //           <h5 id='difference'>Difference</h5>
  //           <div className='vertical'>
  //             <div  className='space-between'>
  //               <div className={'amount medium ' + (pos ? 'positive': 'negative')}>{pos ? '+': ''}{formatAmount(difference, 0)}</div>
  //             </div>
  //             <p className='smaller right' style={{'margin-top': '-12px'}}>In {numYears} years</p>
  //           </div>
  //         </div>
  //       </div>
  //     )
  //   }

  //   return (
  //     <div className='flex totals' style={{'margin-bottom': '15px', 'margin-top': '5px'}}>
  //       <TrueBenchmark />
  //       <CustomBenchmark />
  //       <Difference />
  //     </div>
  //  )
  // }

  // syncs all charts onMouseOver
  function onMouseOverSyncRF(e, charts) {
    // looping through all charts
    Highcharts.charts.forEach(chart => {
      // only apply code to given charts
      if (chart && charts.includes(chart.title.textStr))
      {
        // collects each point at current location on x-axis e.g. for rates chart, there would be 3 points (because there are 3 series)
        let pointsTooltip = []
        // looping through series
        chart.series.forEach(s => {
          // looping through points
          s.points.forEach(p => {
            // since mouseOut behavior is unpredictable and laggy for reserve chart, same functionality is included here
            // (if marker is currently over point, remove it)
            if (p.state == 'hover') p.setState('')

            // syncs points between the graphs
            if (p.index === e.target.index)
            {
              // sets point state to "hover" (dots appear on graph at given point)
              p.setState('hover')
              pointsTooltip.push(p)

              // refreshing crosshair of given charts
              chart.xAxis[0].drawCrosshair(e, p)

              // if mouse over on support charts, then manually update main chart label
              if (chart.title.textStr === "reserve-fund-benchmark" || chart.title.textStr === "reserve-fund-comparison")
              {
                setLabelPoint(p)
                console.log('p', p)
                // const series = p.series.length
                // setHeaderCustom(series[1].points[length-1].y)
                // setHeaderCustomRange([series[0].points[length-1].options.low, series[0].points[length-1].options.high])
                // setHeaderBenchmark(series[2].points[length-1].y)
                // setHeaderBenchmarkRange([series[3].points[length-1].options.low, series[3].points[length-1].options.high])
                // setHeaderDiff(series[1].points[length-1].y - series[2].points[length-1].y)
                // setHeaderYear(series[1].points[length-1].x)
                // setHeaderNumYears(series[1].points[length-1].x - currentYear)
          
                //refreshReserveLabel(p)
                // Deprecated TODO validate this is not too computationally heavy
                // drawing markers on main chart when hovering over Detail Charts 
                // setMouseOverPoint(p)
                // setMouseOverEvent(e)
                // setMouseOverYear(e.target.category)
              }    
            }
          })
        })
        // refreshing tooltip of synced charts
        if (pointsTooltip.length > 0)
        {
          // this method takes an array of point objects that it uses to display the tooltip
          chart.tooltip.refresh(pointsTooltip)
        }
      }
    })
  }
    
  return (
    <div>
      {showMissingData && <MissingData text="There are no Reserve Fund Studies linked to your account." pageSource="cashflow" />}
      {dataExists && (
        <div className='content'>
          <div className='hello'>
            <h1 className='title'>The Vertical City Intro Offer</h1>
            <p className='information'>
              It's time to get started with Vertical City Institute!
              <br></br><br></br>
              To gain access to this page, you were required to send us your <a target="_blank" href="https://www.condoauthorityontario.ca/before-you-buy-or-rent-a-condo/how-condos-work/condo-operations/reserve-funds/">Reserve Fund Study</a> (RFS). Every owner has access to a RFS and every building in Toronto is required to have a new one created for them
              by an engineering firm specializing in building assessments, every 3 years. What goes in a RFS? Basically, engineers do a bunch of research on the health and integrity of the different components of your 
              building, and will produce a 30 year projection of the costs they expect a given building will need to pay and the contributions that owners should be charged in order to account for the costs. This is a 
              super simple version, but as long as you know the engineers are decided how much you need to be saving, the rest will make sense.
            </p>

            {(availableRFStudies.length>0) && (
            <p className='information'>
              <b>Your Building:</b> {auth.addresses[0]} ({auth.buildings[0]})
              <br></br>
              <b>Your Reserve Fund Study:</b> Produced in {availableRFStudies[0]["year"]} by the firm {availableRFStudies[0]["engineer"]}
            </p>
            )}

            <h2 className='title'>Reserve Fund Study</h2>
            <p className='information'>
              This is a true representation of how your Reserve Fund should look across a 30 year window compared to what the RFS predicts. There are a few key reasons for the differences and they
              are unfortunately less complex than one would hope.
            </p>
            
            <div className='component-container' id='detail-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
              <HighchartsReact highcharts={Highcharts} options={chartOptionsClosing} />
            </div>

            <h2 className='title'>Cashflow</h2>
            <p className='information'>
              This is a true representation of how your Reserve Fund should look across a 30 year window compared to what the RFS predicts. There are a few key reasons for the differences and they
              are unfortunately less complex than one would hope.
            </p>

            <div className='component-container' id='detail-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
              <HighchartsReact highcharts={Highcharts} options={chartOptionsNetFunding} />
            </div>

            <h2 className='title'>Report Card</h2>
            <p className='information'>
              This grade represents the health of your reserve fund. We calculate it using thousands of simulations.
            </p>
            {dataGrade[1] && <ReportCardMeter />}

            <p className='information' style={{'marginTop': '20px'}}>
              Some text leading into our next component... 
            </p>

            <p className='information' style={{'marginTop': '20px'}}>
              See how much you can save. 
            </p>

            <h2 className='title'>Savings</h2>
            <p className='information'>
              Some information about how Optimal is calculated. The backend system etc. Play with the risk slider below to see how increasing equity proportion affects the expected performance.
            </p>

            <div className='flex totals' style={{'marginBottom': '15px', 'marginTop': '5px'}}>
              <div className='component-container' style={{'width':'33%', 'marginRight': '5px', 'paddingTop': '0px', 'paddingBottom': '10px'}}>
                <div className='space-between'>
                  <h5 style={{'marginTop': '20px', 'color': '#5d98d1', 'width': '20px'}}>True Benchmark</h5>
                  <div className='vertical'>
                    <div  className='space-between'>
                      <div className='circle-legend' id='true-bm' />
                      <div className='amount medium'>{formatAmount(headerBenchmark, 0)}</div>
                    </div>
                    <div style={{'textAlign': 'top'}}>
                      <p id='portfolio-date' style={{'marginLeft': '2px'}}>As of {headerYear}</p>
                      <p className='smaller right' style={{'marginTop': '-28px'}}>${formatAmountKM(headerBenchmarkRange[0], 0) + " to $" + formatAmountKM(headerBenchmarkRange[1], 0)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='component-container' style={{'width':'33%', 'margin': '0px 5px', 'paddingTop': '0px', 'paddingBottom': '10px'}}>
                <div className='space-between'>
                  <h5 style={{'marginTop': '20px', 'color': '#a7b661', 'width': '20px'}}>Custom Benchmark</h5>
                  <div className='vertical'>
                    <div  className='space-between'>
                      <div className='circle-legend' id='vc-bm' />
                      <div className='amount medium'>{formatAmount(headerCustom, 0)}</div>
                    </div>
                    <div style={{'textAlign': 'top'}}>
                      <p id='portfolio-date' style={{'marginLeft': '2px'}}>As of {headerYear}</p>
                      <p className='smaller right' style={{'marginTop': '-28px'}}>${formatAmountKM(headerCustomRange[0], 0) + " to $" + formatAmountKM(headerCustomRange[1], 0)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='component-container' style={{'width':'33%', 'marginLeft': '5px', 'paddingTop': '0px', 'paddingBottom': '10px'}}>
                <div className='space-between'>
                  <h5 id='difference'>Difference</h5>
                  <div className='vertical'>
                    <div  className='space-between'>
                      <div className={'amount medium ' + (headerDiff > 0 ? 'positive': 'negative')}>{headerDiff > 0 ? '+': ''}{formatAmount(headerDiff, 0)}</div>
                    </div>
                    <p className='smaller right' style={{'marginTop': '-12px'}}>In {headerNumYears} years</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='component' id='reserve-chart'>
              <div className="component-container" id='reserve-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                {dataVCRange && <HighchartsReact highcharts={Highcharts} options={chartOptionsReserve} />}
              </div>
            </div>

            <div className='component-container' id='settings'>
              <div className='risk-slider' id='optimal'>
                <p>Risk Level</p>
                <RangeSlider value={riskLevel} tooltip={'off'} onChange={handleChangeRiskLevel} min={1} max={5} size='lg'/>
              </div>
            </div>
            
            <h2 className='title'>Call to Action</h2>
            <p className='information'>
              Feel free to put us in touch with your board by writing an email with us cc'd (support@verticalcityinstitute.com) or we can get your email started by clicking this.
            </p>
            
            <div className='disclaimer'>
              Please note that Vertical City Toolkit is intended for informational purposes only and should not be construed as investment advice. 
              Condominium Boards should seek the advice of qualified professionals when making investment decisions for their Reserve Funds.
              Vertical City Toolkit does not provide financial, legal, tax or investment advice or recommendations.
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
