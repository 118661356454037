import { useState, useContext } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

// auth
import { authContext } from './authContext.js'

export default function Signup() {
  const navigate = useNavigate()
  const auth = useContext(authContext)

  const [formState, setFormState] = useState("signup")
  const [alertContactSupport, setContactSupport] = useState(false)
  const [alertPasswordMismatch, setAlertPasswordMismatch] = useState(false)

  // storing form input for batch request because /signup and /create/user are called independantly
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  // not sending request until both forms have collected their respective requried inputs
  function handleSignup(event) {
    event.preventDefault();

    // DEPRECATED until user creates account without admin
    const temp_email = event.target[0].value
    const temp_password = event.target[1].value
    const temp_confirm_password = event.target[2].value
    // // store for later
    // setEmail(temp_email)
    // setPassword(temp_password)    
    if (temp_email.length === 0 || temp_password.length === 0) return
    if (temp_confirm_password !== temp_password)
    {
      setAlertPasswordMismatch(true)
      return
    }

    //setFormState("create-user")

    // construct signup request
    const url_query = `${process.env.REACT_APP_FLASK_IP}/signup`
    let body = {email: temp_email, password: temp_password}

    axios.defaults.withCredentials = true
    axios.post(url_query, body)
      .then(function (resp) {

        // TODO will change in production - for now we are only allowing users to create an account through admin
        if (resp.data["user_exists_backend"] === false)
        {
          setContactSupport(true)
          return
        }

        // if user already exists, navigate user to login
        if (resp.data["user_exists_flask"])
        {
          navigate("/login", {state: {userExists: true} })
          return
        }

        // if account creation successful, login user
        body = {email: temp_email, password: temp_password, remember: false}
        auth.login(body).then(() => {
          navigate("/app", {state: {signup: true} });
        });
      })
  }

  function handleCreateUser(event) {
    event.preventDefault();

    const firstName = event.target[0].value;
    const lastName = event.target[1].value;

    // prevent api request if no email or password are given
    if (firstName.length === 0 || lastName.length === 0) return null

    // construct POST request url
    let url_query = `${process.env.REACT_APP_FLASK_IP}/create/user`
    let body = {email: email, first_name: firstName, last_name: lastName, "is_admin": false}

    axios.defaults.withCredentials = true
    axios.post(url_query, body)
      .then(function (resp) {
        // TODO will change in production - for now we are only allowing users to create an account through admin
        if (resp.data["already_exists"] === false)
        {
          // go back to signup page with contact support team alert
          setFormState("signup") 
          setContactSupport(true)
          return
        }
        
        // construct signup request
        url_query = `${process.env.REACT_APP_FLASK_IP}/signup`
        body = {email: email, password: password}

        axios.defaults.withCredentials = true
        axios.post(url_query, body)
          .then(function (resp) {
            // if user already exists, navigate user to login
            if (resp.data["error"]) navigate("/login", {state: {userExists: true} })

            // if account creation successful, login user
            body = {email: email, password: password, remember: false}
            auth.login(body).then(() => {
              navigate("/app");
            });
          })
      })
  }

  function EmailForm() {
    return (
      <Form onSubmit={handleSignup}>
        <h2>Get started</h2>
        <p>Create your Vertical City account here</p>
        {(alertContactSupport) && (<Alert variant='warning'>We're sorry, but we are not yet accessible to the public. Please get in touch with our <a href="mailto:support@verticalcityinstitute.com">Support team</a> to get signed up.</Alert>)}
        {(alertPasswordMismatch) && <Alert variant='warning'>The passwords you entered do not match. Please try again.</Alert>}
        <Form.Group size="lg" controlId="email">
          <Form.Control
            type="email"
            placeholder="Email"
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Control
            type="password"
            placeholder="Password"
          />
        </Form.Group>
        <Form.Group size="lg" controlId="confirmpassword">
          <Form.Control
            type="password"
            placeholder="Confirm Password"
          />
        </Form.Group>
        <Button block size="lg" type="submit">
          Sign Up
        </Button>
        <p>Already have an account? <Link id="login" to="/login"> Log in</Link></p>
      </Form>
    );
  }

  function NameForm() {
    return (
      <Form onSubmit={handleCreateUser}>
        <h2>Please enter some basic info!</h2>
        <Form.Group size="lg" controlId="firstname">
          <Form.Control placeholder="First name"/>
        </Form.Group>
        <Form.Group size="lg" controlId="lastname">
          <Form.Control placeholder="Last name" />
        </Form.Group>
        <Button block size="lg" type="submit">
          Submit Info
        </Button>
      </Form>
    );
  }

  return (
    <div className="Login">
      {formState === "signup" && (<EmailForm />)}
      {formState === "create-user" && (<NameForm />)}
    </div>
  );
}
