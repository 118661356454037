import { Modal } from 'react-bootstrap'
import { useState, memo } from 'react'

// svgs
import onboardingCashflow1 from '../svg/onboarding-cashflow-01.svg'
import onboardingCashflow2 from '../svg/onboarding-cashflow-02.svg'
import onboardingCashflow3 from '../svg/onboarding-cashflow-03.svg'
import onboardingCashflow4 from '../svg/onboarding-cashflow-04.svg'
import onboardingCashflow5 from '../svg/onboarding-cashflow-05.svg'
import onboardingCashflow6 from '../svg/onboarding-cashflow-06.svg'
import onboardingCashflow7 from '../svg/onboarding-cashflow-07.svg'
import onboardingCashflow8 from '../svg/onboarding-cashflow-08.svg'
import onboardingCashflow9 from '../svg/onboarding-cashflow-09.svg'
import onboardingCashflow10 from '../svg/onboarding-cashflow-10.svg'

const onboarding_svgs = {
  0: {img: onboardingCashflow10, text: "In order to understand this app you will have to be familiar with charting and specifically line plotting. If you are already familiar with plotting lines you can skip this introduction but if not, this introduction will explain how we get from a cash flow table to the charts that you see in this page."},
  1: {img: onboardingCashflow1, text: "This is an example of a Cash Flow Table with made up numbers. We expect that you are used to seeing Reserve Fund Forecasts in this format from all the Reserve Fund Studies your building has conducted. It's the standard way of representing a reserve fund forecast. We will now introduce you to a new way of representing a forecast."},
  2: {img: onboardingCashflow2, text: "This is the same Cash Flow Table but with the following two columns highlighted: 'Year' and 'Closing Balance'. Remember, the 'Closing Balance' is the amount forecasted to be in the Reserve Fund at the end of the fiscal year (after Expenditures, Contributions and Interest is added or substracted)."},
  3: {img: onboardingCashflow3, text: "Here we see the same 'Year' and 'Closing Balance' columns with the other columns removed."},
  4: {img: onboardingCashflow4, text: "Next, we rotate the previous image 90 degrees counter clockwise with no other changes. You can see the Years are now increasing from left to right instead of from top to bottom."},
  5: {img: onboardingCashflow5, text: "We see a bigger jump here. From the previous image, we have taken the dollar amounts in the 'Closing Balance' column and given them each a yellow bar with a height corresponding to the amount. The bigger the amount, the taller the bar is and vice versa."},
  6: {img: onboardingCashflow6, text: "This is where we see our first plotted line. All that means is that we've drawn a line along the top of each of the yellow bars. We are now representing the Closing Balance in two ways: with a line and with bars. In graphing terms, we would call these a line chart and a bar chart."},
  7: {img: onboardingCashflow7, text: "For this image we remove the bars with the numbers inside, but keep the same black line which we changed to a yellow line. The horizontal dotted lines are used to show you how the vertical space in this chart (i.e. the Y axis) represent the dollar values of the graphing space. Similarly, the horizontal space (i.e. the X axis) is being used to represent time in Years."},
  8: {img: onboardingCashflow8, text: "Now that the basic idea behind a line plot has been shown we are able to visually compare two sets of numbers. This was not possible in the original cash flow format which showed dollar amounts as numbers only. The yellow line here is still the original 'Closing Balance' but an additional blue line was added that represents what we call the 'Simulated' forecast. We can easily compare these lines and see that the blue line grows slower than the yellow one."},
  9: {img: onboardingCashflow9, text: "This final image shows how we represent uncertainty using a plotted line. The shaded light blue area that you see around the blue line, also called a cone, is used to represent the possible range of outcomes for the Simulated forecast. In reality, we can never forecast perfectly, so we use this cone to show what is possible where the solid blue line is the most likely outcome of what might happen."}
}

const OnboardingLinePlotModal = memo(({show, onClose}) => {
  const [pageNumber, setPageNumber] = useState(0)

  var disableLeftArrow=false
  if (pageNumber == 0) disableLeftArrow = true
  var disableRightArrow=false
  if (pageNumber == 9) disableRightArrow = true

  function handleClickArrowLeft() {
    if (!disableLeftArrow) setPageNumber(prev => prev-1)
  }
  function handleClickArrowRight() {
    if (!disableRightArrow) setPageNumber(prev => prev+1)
  }
  
  var imgMargin = "0px"
  var textHeight = "auto"
  if (pageNumber > 3)
  {
    imgMargin = '-20px'
    textHeight = '50px'
  }
  return (
    <Modal className="onboarding-modal" dialogClassName="modal-width" show={show} onHide={onClose} backdrop="static" size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Plotting the Cash Flow Table</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className='flex space-between'>
        <button style={{'padding': '21px 14px', 'height': '30px'}} className="arrow-button" id={disableLeftArrow?"disable":""} onClick={handleClickArrowLeft}><span style={{'width': '8px', 'height': '8px'}} className="arrow" id="left"></span></button>
        <div>
          <div style={{'textAlign': 'center', 'height': textHeight}}>{onboarding_svgs[pageNumber].text}</div>
          <div className='flex-center' style={{'marginTop': imgMargin}}>
            <img src={onboarding_svgs[pageNumber].img} alt="Onboarding Cashflow 1" width="850" height="700" />
          </div>
        </div>
        <button style={{'padding': '21px 14px', 'height': '30px'}} className="arrow-button" id={disableRightArrow?"disable":""} onClick={handleClickArrowRight}><span style={{'width': '8px', 'height': '8px'}} className="arrow" id="right"></span></button>
      </div>
      <a style={{'position': 'absolute', 'bottom': '10px', 'right': '20px'}} target="_blank" href='https://www.verticalcity.ca/post/plotting-the-cash-flow-table'>See article version</a>
      </Modal.Body>
    </Modal>
  )
})

export default OnboardingLinePlotModal;