import { render } from 'react-dom';
// import './index.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import './index.scss';
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from './components/authContext.js';
import SessionTimeout from './components/SessionTimeout.js';

// components
import App from './components/App.js';

require('dotenv').config()

render(
  <div id="index">
    <BrowserRouter>
      <AuthProvider>
        <App />
        <SessionTimeout />
      </AuthProvider>
    </BrowserRouter>
  </div>,
  document.getElementById('root')
);
