import { useEffect, useState, useContext, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Dropdown, DropdownButton, Button, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap"
import axios from 'axios'

// context
import { authContext } from '../authContext.js'

import wave from '../../svg/wave.svg'
import dashboard from '../../svg/icon-dashboard.svg'
import reportcard from '../../svg/icon-reportcard.svg'
import calculator from '../../svg/icon-calculator.svg'
import utilities from '../../svg/icon-utilities.svg'
import edit from '../../svg/edit.svg'
import budget from '../../svg/icon-budget.svg'
import chat from '../../svg/icon-chat.svg'

export default function Landing() {
  const auth = useContext(authContext)
  const [savedConfig, setSavedConfig] = useState(true)
  const [dashboardOverlayPlacement, setDashboardOverlayPlacement] = useState("right")
  const [buildingDropdownText, setBuildingDropdownText] = useState([])
  const [buildingDisplay, setBuildingDisplay] = useState([])
  const [isDemo, setIsDemo] = useState(false)
  const { state } = useLocation()
  const navigate = useNavigate()

  // refs
  const labelReserveFundRef = useRef(null)
  const labelToolsRef = useRef(null)
  const dashboardOverlayRef = useRef(null)

  const labelRefs = {
    "Reserve Fund": labelReserveFundRef,
    "Tools": labelToolsRef
  }

  function handleSelectBuilding(e) {
    // "TSCC 2155 (80 John Street)" -> "TSCC 2155"
    setBuildingDisplay(e.target.textContent)
    auth.selectBuilding(e.target.textContent.split(" (")[0])
  }

  // TODO change boolean logic after backend database is reflecting true state of subscriptions
  useEffect(() => {
    if (auth.isAdmin)
    {
      setIsDemo(false)
      return
    }

    auth.buildings.forEach((shortname, index) => {
      if (shortname == auth.selectedBuilding)
      {
        if (auth.roles[index].slice(0,4) == "Demo") setIsDemo(true)
        else setIsDemo(false)
      }
    })
  }, [auth.roles, auth.selectedBuilding])

  function handleNewDefaultBuilding() {
    const url_query = `${process.env.REACT_APP_FLASK_IP}/modify/primarybuilding`
    const body = { "email": auth.email, "building_short_name": auth.selectedBuilding }

    axios.defaults.withCredentials = true
    axios.post(url_query, body)
      .then(function (resp) {
        const dataFromServer = resp.data

        // handle no data
        if (Object.keys(dataFromServer).length === 0 || dataFromServer["error"]) return

        auth.setPrimaryBuilding(dataFromServer["building_short_name"])
      })
  }

  // construct text for the select building dropdown button
  useEffect(() => {
    if (auth.addresses == []) return
    const temp_array = []
    auth.buildings.forEach((shortname, index) => {
      temp_array.push(shortname + " (" + auth.addresses[index] + ")")
      if (shortname == auth.selectedBuilding)
      {
        setBuildingDisplay(shortname + ' (' + auth.addresses[index] + ')')
      }
    })

    setBuildingDropdownText(temp_array)
  }, [auth.addresses])

  useEffect(() => {
    if (auth.isContextSecured)
    {
      // fetch building settings to see if the Dashboard page can be enabled or not
      let url_query = `${process.env.REACT_APP_FLASK_IP}/data/settings`
      let body = { "email": auth.email, "building_short_name": auth.selectedBuilding }

      axios.defaults.withCredentials = true
      axios.post(url_query, body)
        .then(function (resp) {
          if (resp.data["default_settings"] == 1) setSavedConfig(false)
        })
    }
  }, [auth.isContextSecured])

  function createButtonGroupLabel(content, selector) {
    const element = document.querySelector(selector)
    const position = element.getBoundingClientRect()
    if (position == null) return

    const labelRef = labelRefs[content].current
    labelRef.style.top = `${position.top}px`
    labelRef.style.right = `${position.right + 20}px`
    labelRef.style.position = 'absolute'
    labelRef.innerHTML = `<p id="label">${content}</p>`
    labelRef.style.display = 'inline'
    // mobile version
    if (window.innerWidth < 786) labelRef.style.display = 'none'
  }

  function createDashboardOverlay() {
    // if dashboard page not disabled, ignore this function
    if (savedConfig) return
    if (dashboardOverlayRef.current == null) return

    const overlay = dashboardOverlayRef.current

    // mobile version
    if (window.innerWidth < 560)
    {
      overlay.style.width = '0px'
      overlay.style.height = '0px'
      return
    }
    else
    {
      if (window.innerWidth < 1100) setDashboardOverlayPlacement("bottom")
      else setDashboardOverlayPlacement("right")
      
      const element = document.querySelector("#DashboardButtonTour")
      const position = element.getBoundingClientRect()
  
      overlay.style.position = 'absolute'
      overlay.style.top = `${position.top}px`
      overlay.style.left = `${position.left}px`
      overlay.style.width = `${position.width}px`
      overlay.style.height = `${position.height}px`
    }
  }

  function createPageElements() {
    createDashboardOverlay()
    createButtonGroupLabel("Reserve Fund", "#ReportCardButtonTour")
    createButtonGroupLabel("Tools", "#EnergyButtonTour")
  }

  useEffect(() => {
    if (auth.buildings.length>0)
    {
      createPageElements()
      window.addEventListener('resize', createPageElements)

      return () => {
        window.removeEventListener('resize', createPageElements);
      }
    }
  }, [auth.buildings, savedConfig])
  
  // TODO implement user version - isPaid, free report v1/2/3, demo budget analytics, demo condoGPT
  return (
    <>
      {auth.firstName && auth.buildings && (
        <>
          {(auth.buildings.length === 0) && (
            <div className="missing-building flex-center">
              <h1>There are no buildings linked to your account.<br></br><br></br>Please contact our <a href="mailto:support@verticalcityinstitute.com">Support team</a>.</h1>
            </div>
          )}
          {auth.buildings.length > 0 &&
          <div className="landing" id="LandingTour">
            <div className="landing-summary">
              <h1>Hi {auth.firstName}, welcome {state?.signup ? "to Vertical City" : "back"}!</h1>
              {(auth.buildings.length > 1) && <h5 style={{'fontWeight': '400'}}>There are {auth.buildings.length} building{auth.buildings.length > 1 && "s"} linked to your account.</h5>}
              {(auth.buildings.length > 1) &&
              <DropdownButton className={auth.tourStatus == 'SelectBuilding' ? 'tour-highlight':''} id='SelectBuildingTour' drop='down-centered' size='lg' title={buildingDisplay}>
                {buildingDropdownText.map((text) => <Dropdown.Item onClick={handleSelectBuilding}>{text}</Dropdown.Item>)}
              </DropdownButton>}
              {(auth.buildings.length == 1) && <h5 id='SelectBuildingTour' style={{'fontWeight': '400'}}>Your account is linked to {auth.selectedBuilding} ({auth.addresses[0]})</h5>}
              {(auth.selectedBuilding !== !auth.isAdmin) && (auth.buildings.length !== 1) && <Button size='sm' id="default-button" onClick={handleNewDefaultBuilding} disabled={(auth.selectedBuilding===auth.primaryBuilding) && (auth.tourStatus == "Ignored")}>Set as default</Button>}
            </div>
            <div ref={labelReserveFundRef} />
            <div className="button-group">
              {isDemo &&
              <Button onClick={() => navigate("/app/hello")} style={{'marginTop': '0px'}}>
                <img src={wave} alt="Report Card Emoji" width="64" height="64" />
                <div className="button-text">
                  <b>Free Report</b>
                  <p>Get started here!</p>
                </div>
              </Button>}
              <Button className={auth.tourStatus == 'ReportCardButton' ? 'tour-highlight':''} id="ReportCardButtonTour" onClick={() => navigate("/app/report-card")} disabled={isDemo} style={{'marginTop': isDemo ? '20px' : '0px'}}>
                <img src={reportcard} alt="Report Card Emoji" width="64" height="64" />
                <div className="button-text">
                  <b>Report Card</b>
                  <p>See the overall health of your Reserve Fund.</p>
                </div>
              </Button>
              <Button id="CalculatorButtonTour" onClick={() => navigate("/app/calculator")} disabled={isDemo} style={auth.tourStatus == "CalculatorButton" ? {zIndex: 1} : {}}>
                <img src={calculator} alt="Calculator Emoji" width="60" height="60" style={{margin: '2px 0px'}}/>
                <div className="button-text">
                  <b>Forecast Calculator</b>
                  <p>Simulate different scenarios using the calculator.</p>
                </div>
              </Button>
              {auth.tourStatus == "Ignored" && <OverlayTrigger placement={dashboardOverlayPlacement} overlay={<Popover><PopoverBody>The Dashboard page disabeld until you save a configuration in the Forecast Calculator page.</PopoverBody></Popover>}><div ref={dashboardOverlayRef} /></OverlayTrigger>}
              <Button id={(savedConfig && auth.tourStatus == 'SelectBuilding') ? "DashboardButton2Tour" : "DashboardButtonTour"} onClick={() => navigate("/app/dashboard")} disabled={(isDemo || !savedConfig) && (auth.tourStatus == "Ignored" || auth.tourStatus == "DashboardButton" || auth.tourStatus == "DashboardButton2")} style={auth.tourStatus == "DashboardButton2" ? {zIndex: 1} : {}}>
                <img src={dashboard} alt="Dashboard Emoji" width="64" height="64" />
                <div className="button-text">
                  <b>Dashboard</b>
                  <p>Manage your cash and investments.</p>
                </div>
              </Button>
            </div>
            <div ref={labelToolsRef} />
            <div className="button-group">
              <div className="divider"></div>
              <Button id="EnergyButtonTour" onClick={() => navigate("/app/energy")} disabled={isDemo}>
                  <img src={utilities} alt="Utilities Emoji" width="64" height="64" />
                  <div className="button-text">
                    <b>Energy Analytics</b>
                    <p>Monitor your energy consumption.</p>
                  </div>
                </Button>
              <Button onClick={() => navigate("/app/budget")} disabled={isDemo}>
                <img src={budget} alt="Budget Emoji" width="64" height="64" />
                <div className="button-text">
                  <b>Budget Tool</b>
                  <p>Visualize and track spending.</p>
                </div>
              </Button>
              <Button onClick={() => navigate("/app/chat")} disabled={isDemo}>
                <img src={chat} alt="Chat Emoji" width="64" height="64" />
                <div className="button-text">
                  <b>Chat with {auth.selectedBuilding}</b>
                  <p>AI trained on your condo documents.</p>
                </div>
              </Button>
            </div>
            {auth.isAdmin &&
            <div className="button-group">
              <div className="divider"></div>
              <Button onClick={() => navigate("/app/admin")}>
                <img src={edit} alt="Edit Emoji" width="60" height="60" />
                <div className="button-text">
                  <b>Admin Dashboard</b>
                  <p>Add, modify and/or delete users from the backend</p>
                </div>
              </Button>
            </div>}
          </div>
          }
        </>
      )}
    </>
  )
}

//<Button>Button</Button>{'Success'} // code for something

// const { userReserveExists, userStatus } = useAuth();

// const url_query = `http://${process.env.REACT_APP_FLASK_IP}:5000/@me`
// userStatus(url_query)
