import Highcharts from 'highcharts/highstock';
import { formatAmount, formatAmountKM } from './formattingHelpers';

// we aren't sure why we need this, but it needs to be imported once in app
require('highcharts/highcharts-more')(Highcharts);

// syncs all desired charts on mouseOver
export function onMouseOverSync(point, e, charts = ["default"]) {
  // looping through all charts
  Highcharts.charts.forEach(chart => {
    // syncing up only provided desired charts (or all if no type is provided)
    if (chart && (charts.includes(chart.title.textStr) || charts.includes("default")))
    {
      let points = []
      // looping through series
      chart.series.forEach(s => {
        // syncs points between the graphs
        const syncedPoint = s.data[point.index]
        // sets point to 'hover' (have a highlighted marker over it)
        syncedPoint.setState('hover')
        points.push(syncedPoint)
        // draws crosshair for point
        syncedPoint.series.chart.xAxis[0].drawCrosshair(e, syncedPoint)
      })
      // syncing tooltip
      chart.tooltip.refresh(points)
    }
  })
}

// hides tooltip and hover state of other charts, we have to do this manually because onMouseOverSync functions are manually snycing points in respective pages
export function onMouseOutSync(charts = ["default"]) {
  Highcharts.charts.forEach(chart => {
    // if no chart names given, apply to all charts
    if (chart && (charts.includes(chart.title.textStr) || charts.includes("default")))
    {
      chart.series.forEach(s => {
        s.points.forEach(p => {
          // ensuring that when chart is changed, error isn't thrown
          if (p.series && p.state === "hover")
          {
            p.setState('')
            chart.tooltip.hide()
            chart.xAxis[0].hideCrosshair()
            chart.yAxis[0].hideCrosshair()
          }
        })
      })
    }
  })
}

// converts YYYYMMDD to date object
export function dateConversion(date) {
  var year = date.toString().substring(0, 4);
  var month = date.toString().substring(4, 6) - 1;
  var day = date.toString().substring(6, 8);
  var dateObj = new Date(year, month, day)
  return dateObj
}

// helps organize data for highcharts to plot
// if date is true, x values are converted to date objects, if sort is true, data is sorted
export function helperPlot(x, y, date = false, sort = false) {
  var output = []
  for (let i = 0; i < x.length; ++i)
  {
    if (date)
    {
      output.push([dateConversion(x[i]).getTime(), y[i]])
    }
    else
    {
      output.push([x[i], y[i]])
    }
  }

  if (sort)
  {
    output.sort(function (a, b) { return a[0] - b[0] })
  }
  return output
}

export function negativeCash(x, remove_negative=false) {
  var output = []
  if (remove_negative)
  {
    for (let i in x)
    {
      if (x[i] < 0) output.push(0)
      else output.push(x[i])
    }
  }
  else
  {
    for (let i in x)
    {
      if (x[i] > 0) output.push(0)
      else output.push(-x[i])
    }
  }
  return output
}

// expects a series of lists of 3 elements
export function getIndex(input, idx) {
  var output = []
  for (let i = 0; i < input.length; ++i)
  {
    output.push(input[i][idx])
  }
  return output
}

// expects a series
export function increaseByPct(input, pct) {
  var output = []
  for (let i = 0; i < input.length; ++i)
  {
    output.push(input[i] * (1 + pct))
  }
  return output
}


// Helper to convert for plotting
export function makeChartPlotData(x, y, date = false, sort = false) {
  var output = []
  for (let i = 0; i < x.length; ++i)
  {
    let found = false
    for (let j = 0; j < output.length; j++)
    {
      if (output[j][0] === (dateConversion(x[i]).getFullYear()))
      {
        output[j][1] += y[i];
        found = true
      }
    }

    if (!found)
    {
      output.push([dateConversion(x[i]).getFullYear(), y[i]])
    }
  }

  if (sort)
  {
    output.sort(function (a, b) { return a[0] - b[0] })
  }
  return output
}

// function to add amounts if dates are same
// TODO: Handle fiscal year?
export function maturityChartPlot(x, y, date = false, sort = false) {
  var output = []
  for (let i = 0; i < x.length; ++i)
  {
    let found = false
    for (let j = 0; j < output.length; j++)
    {
      if (output[j][0] === (dateConversion(x[i]).getFullYear()))
      {
        output[j][1] += y[i];
        found = true
      }
    }

    if (!found)
    {
      output.push([dateConversion(x[i]).getFullYear(), y[i]])
    }
  }

  if (sort)
  {
    output.sort(function (a, b) { return a[0] - b[0] })
  }
  return output
}


export function cumulativeMaturityChartPlot(x, y, date = false, sort = false) {
  var getOutput = []
  getOutput = maturityChartPlot(x, y, date = false, sort = false)
  var finalOutput = []
  let sum = 0
  for (let i = 0; i < getOutput.length; i++)
  {
    finalOutput.push([getOutput[i][0], (getOutput[i][1] + sum)])
    sum += getOutput[i][1]
  }

  return finalOutput
}


// helps organize range data for highcharts to plot
export function helperPlotRange(x, low, high, date = false, sort = false) {
  var output = []
  for (let i = 0; i < x.length; ++i)
  {
    if (date)
    {
      output.push([dateConversion(x[i]).getTime(), low[i], high[i]])
    }
    else
    {
      output.push([x[i], low[i], high[i]])
    }
  }

  if (sort)
  {
    output.sort(function (a, b) { return a[0] - b[0] })
  }
  return output
}

// finds minimum and maximum y values from a given array of chart data, with 2% offset applied
export function chartExtremes(data, offset = 0.02) {
  let max
  let min
  for (const series of data)
  {
    for (const dataPoint of series)
    {
      let point
      // for reserve fund chart
      if (typeof dataPoint != "object")
      {
        point = dataPoint
      }
      // for cost chart
      else if (dataPoint[1] == Infinity)
      {
        continue
      }
      else if (dataPoint.y)
      {
        point = dataPoint.y
      }
      // if series is a range
      else if (dataPoint.length > 2)
      {
        if (dataPoint[2] > max || max == undefined)
        {
          max = dataPoint[2]
        }
        if (dataPoint[1] < min || min == undefined)
        {
          min = dataPoint[1]
        }
        continue
      }
      else
      {
        point = dataPoint[1]
      }

      if (point > max || max == undefined)
      {
        max = point
      }
      if (point < min || min == undefined)
      {
        min = point
      }
    }
  }

  const range = Math.abs(max - min)
  const pointOffset = range * offset
  max = max + pointOffset
  min = min - pointOffset

  // sets minimum to be 0 if within 3% range from zero, either before or after tick offset is applied
  if (range * 0.03 > Math.abs(min) || range * 0.03 > Math.abs(min - pointOffset))
  {
    min = 0
  }

  return { max: max, min: min }
}

export function refreshReserveLabel(point) {
    const chart = point.series.chart;
    const index = point.index

    var studyBenchmark = null
    var benchmark = null
    var benchmarkRange = null
    var vc = null
    var vcRange = null

    // sets label data
    chart.series.forEach(s => {
      if (s.name === "Study Benchmark")
      {
        studyBenchmark = s.yData[index]
      }
      else if (s.name === "True Benchmark")
      {
        benchmark = s.yData[index]
      }
      else if (s.name === "True Benchmark Range")
      {
        benchmarkRange = s.yData[index]
      }
      else if (s.name === "Vertical City")
      {
        vc = s.yData[index]
      }
      else if (s.name === "Vertical City Range")
      {
        vcRange = s.yData[index]
      }
    })

    // This will only be the case on benchmark view, otherwise show vertical city label
    if (studyBenchmark)
    {
      var htmlHeader = "<h2>Study vs. True Benchmark</h2>"
      var htmlDetails = "<div className='label-details'>\
                        <div>Year " + point.x + "</div>\
                        <div className='label-group'>\
                          <div className='circle-legend' id='study-bm'></div>\
                          <div>Study Benchmark: " + formatAmount(studyBenchmark, 0) + "</div>\
                        </div>\
                        <div className='label-group'>\
                          <div className='circle-legend' id='true-bm'></div>\
                          <div>True Benchmark mean: " + formatAmount(benchmark, 0) + "</div>\
                        </div>\
                        <div className='label-group'>\
                          <div className='square-legend' id='true-bm-range'></div>\
                          <div>True Bechmark range: " + formatAmount(benchmarkRange[0], 0) + " to " + formatAmount(benchmarkRange[1], 0) + "</div>\
                        </div>\
                      </div>"
    }
    else
    {
        var performance = vc - benchmark
        var performanceStr = formatAmountKM(performance)
        var today = new Date()
        var year = today.getFullYear()
        var yearDiff = year - point.x

        // if (yearDiff < -1) htmlHeader = "<h2>In " + -yearDiff + " years, we expect you'll save " + performanceStr + "</h2>"
        // else if (yearDiff == -1) htmlHeader = "<h2>Next year, we expect you'll save " + performanceStr + "</h2>"
        // else if (yearDiff == 0) htmlHeader = "<h2>This year, we expect you'll save " + performanceStr + "</h2>"
        // else if (yearDiff == 1) htmlHeader = "<h2>Last year, you would have saved " + performanceStr + "</h2>"
        // else htmlHeader = "<h2>" + yearDiff + " years ago, you would have saved " + performanceStr + "</h2>"
        htmlHeader = "<h2>True Benchmark vs. Custom</h2>"

        htmlDetails = "<div className='label-details'>\
                        <div>Year " + point.x + "</div>\
                        <div className='label-group'>\
                          <div className='circle-legend' id='true-bm'></div>\
                          <div>True Benchmark mean: " + formatAmount(benchmark, 0) + "</div>\
                        </div>\
                        <div className='label-group'>\
                          <div className='square-legend' id='true-bm-range'></div>\
                          <div>True Bechmark range: " + formatAmount(benchmarkRange[0], 0) + " to " + formatAmount(benchmarkRange[1], 0) + "</div>\
                        </div>\
                        <div className='label-group'>\
                          <div className='circle-legend' id='vc-bm'></div>\
                          <div>Custom mean: " + formatAmount(vc, 0) + "</div>\
                        </div>\
                        <div className='label-group'>\
                          <div className='square-legend' id='vc-bm-range'></div>\
                          <div>Custom range: " + formatAmount(vcRange[0], 0) + " to " + formatAmount(vcRange[1], 0) + "</div>\
                        </div>\
                      </div>"
    }

    // tooltip display div html
    var html = `<div className='chart-label'>${htmlHeader}${htmlDetails}</div>`

    // destory markers if already exists
    if (chart.mainLabel != undefined)
    {
      chart.mainLabel.destroy()
    }

    // draw it
    chart.mainLabel = chart.renderer.label(
      html,
      50,
      60,
      null, null, null, true
    ).attr({
      zIndex: 4
    }).add()
}

export function resetReserveChart() {
  Highcharts.charts.forEach(chart => {
    // if chart is on Reserve Fund page
    if (chart && (chart.title.textStr === "reserve-fund-comparison" || chart.title.textStr === "reserve-fund-benchmark")) // || chart.title.textStr === "reserve-fund-optimal"
    {
      let length = chart.series[2].points.length
      // looping through series
      chart.series.forEach(s => {
        if (s.points.length > 0)
        {
          // remove hover (marker) state from each point
          s.points.forEach(p => {
            if (p.state == 'hover') p.setState('')
          })

          // sets point state to 'hover' (highlights them with a marker)
          const endpoint = s.points[s.points.length - 1]
          endpoint.setState('hover')
          refreshReserveLabel(endpoint)
        }
      })

      // drawing crosshair
      chart.xAxis[0].drawCrosshair(null, chart.series[2].points[length - 1])
    }
  })
}

// sets tooltip thousands separator to be a comma for all highcharts
Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});
