//import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';

// context
import { authContext } from './authContext.js'

// bootstrap
import Alert from 'react-bootstrap/Alert'

export default function Login() {
  // routing and auth
  const navigate = useNavigate()
  const { state } = useLocation()
  const auth = useContext(authContext)
  
  const [rememberedEmail, setRememberedEmail] = useState(null)

  // request rememberme on default
  useEffect(() => {
    // navigate user to landing page if they are already authorized i.e. if they go to the login url while being logged in
    if (auth.authed) navigate("/app")

    let url_query = `${process.env.REACT_APP_FLASK_IP}/rememberme`

    axios.defaults.withCredentials = true
    axios.get(url_query)
      .then(function (resp) {
        setRememberedEmail(resp.data["email"])
      })
  }, [])
  
  function handleSubmit(event) {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;
    const remember = event.target.remember.checked;

    // prevent api request if no email or password are given
    if (email.length === 0 || password.length === 0) return null

    // construct POST request url - Manjet 192.168.1.73
    const form_data = {"email": email, "password": password, "remember": remember}

    // pass api request as url string w credentials to our login promise
    auth.login(form_data).then(() => {
      navigate(state?.path || "/app");
    });
  };

  return (rememberedEmail != null && 
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <h2>Welcome back</h2>
        <p>Please log in to continue</p>
        {(auth.authFailed) && (<Alert variant='warning'>The login credentials you entered are invalid.</Alert>)}
        {(state?.newPassword) && <Alert variant='success'>Your password has been updated! Log in with your new pasword.</Alert>}
        {(state?.userExists) && <Alert variant='warning'>Looks like you may already have an account with us. Use your credentials to log in instead.</Alert>}
        <Form.Group size="lg">
          <Form.Control 
            defaultValue={rememberedEmail !== "" ? rememberedEmail : ""}
            type="email" 
            placeholder="Email"
            name="email"
          />
        </Form.Group>
        <Form.Group size="lg">
          <Form.Control
            type="password"
            placeholder="Password"
            name="password"
          />
        </Form.Group>
        <div className="details space-between">
          <Form.Check type="checkbox" label="Remember me" defaultChecked={rememberedEmail !== "" ? true : false} name="remember" />
          <Link to="/forgot-password"> Forgot password?</Link>
        </div>
        <Button block size="lg" type="submit">
          Log In
        </Button>
        <p>Don't have an account? <Link to="/signup" onClick={() => auth.setAuthFailed(false)}> Sign up</Link></p>
      </Form>
    </div>
  );
}
