import { useState, useEffect, memo } from 'react';
import { Offcanvas } from 'react-bootstrap'
import { formatAmount } from '../lib/helpers/formattingHelpers.js';

import handPointer from '../svg/hand-pointer.svg'

// ------------------------------------------------------------------------
// Preventing re-rendering child components 
// (React.memo and useCallback)
// ------------------------------------------------------------------------

// In this scenario, we have a parent component ReportCard and a child component OffCanvasCashflow. 
// The parent component holds a state variable (labelPoint), and the child component updates this state. 
// We want to prevent the OffCanvasCashflow from re-rendering every time the parent's state is updated.

// Using React.memo() and useCallback()
 
// By memoizing this child component (only updates this component if the props its passed change) and passing a callback (onSyncCharts)
// that is able to execute state changes to the parent from within the parent (but called from the child component), we are able
// to prevent re-rendering this component

// Note: this child component must be in a seperate file for this functionality to work as expected.

const OffCanvasCashflow = memo(({ show, selectedYear, selectedBuilding, studyString, onSyncCharts, onClose, inflation, interest, dataOpening, dataContribution, dataExpenditures, dataInterestIncome, dataClosing, mouseOverYear, mouseOver}) => {
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [hoveredColIndex, setHoveredColIndex] = useState(null);
  
  // highlight row of cashflow table when user hovers over chart on parent component (Report Card / Calculator)
  useEffect(() => {
    if (mouseOverYear) setHoveredRowIndex(mouseOverYear - selectedYear)
    if (!mouseOver) setHoveredRowIndex(null)
  }, [mouseOverYear, mouseOver])

  const columns = ["Year", "Opening", "Contribution", "Expenditures", "Interest", "Closing"]
  const engineer = studyString.substring(studyString.search('by')+3, studyString.length)

  function handleRowMouseOver(rowIndex) {
    setHoveredRowIndex(rowIndex)
    var e = {target: {index: rowIndex}}
    onSyncCharts(e, [], true)
  }

  function Column(props) {
    var array30 = []
    for (let i = 0; i < 30; i++) {
      if (props.name === "Year") array30.push(Number(selectedYear)+i)
      else if (props.name === "Opening") array30.push(formatAmount(dataOpening[i],0))
      else if (props.name === "Contribution") array30.push(formatAmount(dataContribution[i][1],0))
      else if (props.name === "Expenditures") array30.push(formatAmount(dataExpenditures[i][1],0))
      else if (props.name === "Interest") array30.push(formatAmount(dataInterestIncome[i],0))
      else if (props.name === "Closing") array30.push(formatAmount(dataClosing[i],0))
      else array30.push('$')
    }

    return (
      <div key={props.colIndex} id={[0].includes(props.colIndex) ? 'thin':''} className={'column' + (hoveredColIndex === props.colIndex ? ' hovered' : '')} onMouseEnter={() => setHoveredColIndex(props.colIndex)} onMouseLeave={() => setHoveredColIndex(null)}>
        <u>{props.name}</u>
        {array30.map((value, rowIndex) => 
        <div key={rowIndex} className={'cell' + (hoveredRowIndex === rowIndex ? ' hovered' : '')} onMouseEnter={() => handleRowMouseOver(rowIndex)} onMouseLeave={() => setHoveredRowIndex(null)}>
          {value}
        </div>
        )}
      </div>
    )
  }

  function CellDescription() {
    
    var year = ""
    if (hoveredRowIndex !== null) year = (Number(selectedYear) + Number(hoveredRowIndex)).toString()

    var col = ""
    var description = ""
    if (hoveredColIndex !== null)
    {
      col = columns[hoveredColIndex]
      if (col === "Opening") description = "This column shows the starting balance of the reserve fund at the beginning of each year. For the first year, this would be the current balance of the fund. For subsequent years, it equals the closing balance of the previous year."
      if (col === "Contribution") description = "This column reflects the annual contributions made to the reserve fund. These are the funds collected from the condominium owners, typically as part of their common element fees. The amount is determined based on the funding plan developed in the Reserve Fund Study to ensure adequate funds for future repairs and replacements."
      if (col === "Expenditures") description = "This column lists the anticipated expenses for the year. It includes the cost of major repairs and replacements of the condominium's common elements as they come due. These expenditures are based on the life cycle analysis and cost estimation for each component in the study."
      if (col === "Interest") description = "This column shows the interest earned on the reserve fund's balance over the year. The interest rate used for these calculations is generally conservative, reflecting the low-risk investment strategy typically employed for reserve funds."
      if (col === "Closing") description = "The closing balance is the reserve fund's ending balance at the year's end. It's calculated by taking the opening balance, adding the contributions and interest for the year, and then subtracting the expenditures. This closing balance then becomes the opening balance for the next year."
    }

    var value = ""
    if (hoveredRowIndex !== null)
    {
      if (col === "Opening") value = formatAmount(dataOpening[hoveredRowIndex],0)
      if (col === "Contribution") value = formatAmount(dataContribution[hoveredRowIndex][1],0)
      if (col === "Expenditures") value = formatAmount(dataExpenditures[hoveredRowIndex][1],0)
      if (col === "Interest") value = formatAmount(dataInterestIncome[hoveredRowIndex],0)
      if (col === "Closing") value = formatAmount(dataClosing[hoveredRowIndex],0)
    }

    if (col === "Opening" || col === "Closing") col += " Balance"
    else if (col === "Interest") col += " Income"
    return (
      <div className='cell-description component-container'>
        {col === "" && 
        <div style={{'textAlign': 'center'}}>
          <b>Hover over the Cash Flow Table above!</b>
          <img style={{'marginTop': '-2px', 'marginLeft': '4px'}} src={handPointer} alt="Hand Pointer" width="20" height="20" />
        </div>}
        {col && <b>Column: </b>}{col}
        {col !== "" && <br></br>}
        {col && <b>Year: </b>}{col && year}
        {col !== "" && <br></br>}
        {value && <b>Value: </b>}{value}
        {value && <div style={{'paddingTop': '2%'}}>{description}</div>}
      </div>
    )
  }

  return (
    <Offcanvas className="offcanvas-tutorial" show={show} onHide={onClose} scroll={true} backdrop={false}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Understanding this Page</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div style={{'paddingBottom': '2%'}}>
          From the selected Reserve Fund Study ({studyString}), we capture the data from the Cash Flow Table to create the different charts in this page.
        </div>
        <div style={{'paddingBottom': '2%'}}>
          This tab should help give you an understanding of how the uploaded reserve fund study is used to make these charts.
        </div>
        <div style={{'paddingBottom': '5%'}}>
          Hover over the Cash Flow Table below to learn more...
        </div>
        <div className='component-container cashflow-table'>
          <div className='space-between'>
            <div style={{'width': '20%'}}>
              <div style={{'text-decoration': 'underline'}}>Prepared by</div>
              <div>{engineer}</div>
            </div>
            <div style={{'width': '60%', 'textAlign': 'center'}}>
              <div style={{'fontWeight': '600'}}>Cash Flow Table</div>
              <div>Toronto Standard Condominium Corporation No. {selectedBuilding.substring(selectedBuilding.length-4, selectedBuilding.length)}</div>
            </div>
            <div style={{'width':'20%', 'textAlign':'right'}}>
              <div style={{'text-decoration': 'underline'}}>Assumptions</div>
              <div>Inflation: {(inflation * 100).toFixed(1) + '%'}</div>
              <div>Interest: {(interest * 100).toFixed(1) + '%'}</div>
            </div>
          </div>
          <br></br>
          <div className='flex' style={{'overflow':'auto'}}>
            {columns.map((col, i) => <Column colIndex={i} name={col} />)}
          </div>
        </div>
        <CellDescription />
      </Offcanvas.Body>
    </Offcanvas>
  );
})

export default OffCanvasCashflow;
