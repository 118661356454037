import axios from "axios";
import { Form, Button, Alert } from "react-bootstrap";
import { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

export default function ResetPassword() {
  const navigate = useNavigate()
  const { state } = useLocation();

  const [samePasswords, setSamePasswords] = useState(true)
  const [formData, setFormData] = useState(null)

  // set flag to indicate if passwords are the same
  function handleChange(e) {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    })

    if (e.target.id === "password1")
    {
      if (e.target.value === formData?.password2)
      {
          setSamePasswords(true)
      }
      else
      {
          setSamePasswords(false)
      }
    }
    else if (e.target.id === "password2")
    {
      if (e.target.value === formData?.password1)
      {
          setSamePasswords(true)
      }
      else
      {
          setSamePasswords(false)
      }
    }
  };

  function handleSubmit(event) {
    event.preventDefault();

    if (!samePasswords || !formData || formData.password1.length === 0 ) return

    const form_data = { "password": formData.password1, "token": state?.token }
    const url_query = `${process.env.REACT_APP_FLASK_IP}/resetpassword`

    axios.defaults.withCredentials = true
    axios.post(url_query, form_data)
        .then(function (resp) {

          if (resp.data["error"]) return

          setSamePasswords(true)
          
          navigate("/login", {state: {newPassword: true} })
        })
  };

  return (
    <div className="ResetPassword">
      <Form onSubmit={handleSubmit}>
        <div className="password-title">
          <b>Change your password</b>
        </div>
        {(!samePasswords) && (<Alert variant='warning'>The password fields must match.</Alert>)}
        <div className="password-group space-between">
          <p>New password</p>
          <Form.Group size="lg" controlId="password1">
            <Form.Control
              type="password"
              onChange={handleChange}
            />
          </Form.Group>
        </div>          
        <div className="password-group space-between">
          <p>Confirm password</p>
          <Form.Group size="lg" controlId="password2">
            <Form.Control
              type="password"
              onChange={handleChange} 
            />
          </Form.Group>
        </div>
        <Button block size="lg" type="submit" disabled={!samePasswords || formData?.password1.length === 0}>
          Submit
        </Button>
      </Form>
    </div>
  );
}