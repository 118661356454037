import { memo, useMemo, useEffect, useContext } from 'react'
import { useTable } from 'react-table'
import { Modal, Table } from 'react-bootstrap'
import { formatDate, YYYYMMDDtoDate } from '../lib/helpers/formattingHelpers.js'
import { authContext } from './authContext.js'

const SelectStudyModal = memo(({show, availableRFStudies, onSelectStudy}) => {
  const auth = useContext(authContext)

  const header = [
    {Header: 'Year', accessor: 'year'},
    {Header: 'Engineer', accessor: 'engineer'},
    {Header: 'Publish Date', accessor: 'publish_date'}
  ]
  const dateHeaders = ['Publish Date']

  // useEffect(() => {
  //     auth.setTourStatus("Ignored")
  //     return () => {auth.}
  // }, [])

  // copy from reactableBuilder.js - removed special formating for date and amount cells
  // this was copied because row click functionality needs to change state on this page
  function ReactTableBasic(props) {
    const data = useMemo(() => props.data, [])
    const columns = useMemo(() => props.columns, [])
    const formatDateHeaders = props.formatDateHeaders ? props.formatDateHeaders : []
    
    const {
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    } = useTable({columns, data})
  
    return (
      <Table hover className='react-table'>
        <thead className='stick-header-style'>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((header) => (
            <th className='header-hover'>
              <div {...header.getHeaderProps({ title: undefined })}>
                {header.render('Header')}
              </div>
            </th>
          ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map(row => {
        prepareRow(row)
        return (
          <tr {...row.getRowProps()} onClick={() => onSelectStudy(row.values.year)}>
          {row.cells.map((cell) => {
          if (formatDateHeaders.includes(cell.column.Header))
          {
            return <td {...cell.getCellProps()}>{formatDate(YYYYMMDDtoDate(cell.value))}</td>
          }
          else return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
          })}
          </tr>
        )
        })}
        </tbody>
      </Table>
    )
  }
  
  return (
    <Modal className="select-study-modal" dialogClassName="modal-width" show={show} backdrop="static" size="lg" centered>
      <Modal.Header>
        <Modal.Title>Reserve Fund Study</Modal.Title>
        <a>Before continuing you must select one of the following uploaded reserve fund studies to use reserve fund data from.</a>
      </Modal.Header>
      <Modal.Body id="SelectStudyModalTour" style={{width: '100%'}}>
        {availableRFStudies && <ReactTableBasic data={availableRFStudies} columns={header} formatDateHeaders={dateHeaders}/>}
      </Modal.Body>
    </Modal>
  )
})

export default SelectStudyModal;
