import { useState, createContext, useEffect } from "react";
import axios from 'axios';

// doesn't need default context– in this case we define it when AuthProvider is created when App starts
export const authContext = createContext();

function getLocalStorage(itemName, boolean=false) {
   const storedItem = localStorage.getItem(itemName);
   if (boolean) return JSON.parse(storedItem);
   return storedItem
}

function useAuth() {
   const [authed, setAuthed] = useState(getLocalStorage("isUserLogged", true))
   const [authFailed, setAuthFailed] = useState(false)
   const [email, setEmail] = useState("")
   const [buildings, setBuildings] = useState([])
   const [addresses, setAddresses] = useState([])
   const [roles, setRoles] = useState([])
   const [selectedBuilding, setSelectedBuilding] = useState(getLocalStorage("selectedBuilding"))
   const [selectedStudyYear, setSelectedStudyYear] = useState(getLocalStorage("selectedStudyYear"))
   const [primaryBuilding, setPrimaryBuilding] = useState("")
   const [firstName, setFirstName] = useState("")
   const [isAdmin, setIsAdmin] = useState(false)
   const [tourStatus, setTourStatus] = useState("Ignored")
   const [configSaved, setConfigSaved] = useState(false)
   const [chatId, setchatId] = useState(getLocalStorage("chatId"))
   const [isContextSecured, setIsContextSecured] = useState(false)

   function fetchLogout() {
      const url_query = `${process.env.REACT_APP_FLASK_IP}/logout`
      setAuthed(false)
      localStorage.removeItem('isUserLogged')
      localStorage.removeItem('selectedBuilding')
      localStorage.removeItem('selectedStudyYear')
      localStorage.removeItem('chatId')
      //localStorage.removeItem('tourStatus')
      setSelectedStudyYear(null)
      setchatId(null)
      axios.defaults.withCredentials = true
      axios.post(url_query)
      .then(function (resp) {
         //console.log(resp)
      })
   }

   function fetchContext() {
      if (authed)
      {  
         console.log("updating context from backend...")
         const url_query = `${process.env.REACT_APP_FLASK_IP}/context`

         axios.defaults.withCredentials = true
         axios.get(url_query)
         .then(function (resp) {
            setEmail(resp.data["email"])
            setFirstName(resp.data["first_name"])
            setIsAdmin(resp.data["is_admin"] === 1 ? true : false)
            setBuildings(resp.data["building_short_names"])
            setAddresses(resp.data["building_addresses"])
            setRoles(resp.data["building_roles"])
            // for initial login, if localStorage is empty, set selected building as primary from backend, otherwise set to what is in localStorage
            if (getLocalStorage("selectedBuilding") === null) setSelectedBuilding(resp.data["building_primary"])
            setTourStatus(resp.data["tour_status"])
            setPrimaryBuilding(resp.data["building_primary"])
            setIsContextSecured(true)
         }).catch(function (error) {
            if (error.response.status == 401) fetchLogout()
         })
      }
   }

   function fetchSettings() {
      if (email == "") return
      console.log('auth')
      console.log('email', email)
      console.log('selectedBuilding', selectedBuilding)
      let url_query = `${process.env.REACT_APP_FLASK_IP}/data/settings`
      let body = { "email": email, "building_short_name": selectedBuilding }
  
      axios.defaults.withCredentials = true
      axios.post(url_query, body)
        .then(function (resp) {
          console.log("fire data")
          if (resp.data["default_settings"] == 1) setConfigSaved(false)
          else setConfigSaved(true)
        })
   }

   // on page refresh, fetch context from server side session storage using cookies
   useEffect(() => {
      fetchContext()
      fetchSettings()
   }, [])

   return {
     authed,
     authFailed,
     setAuthFailed,
     email,
     buildings,
     addresses,
     roles,
     selectedBuilding,
     selectedStudyYear,
     primaryBuilding,
     setPrimaryBuilding,
     selectBuilding(building_short_name) {
      setSelectedStudyYear(null)
      localStorage.removeItem('selectedStudyYear')
      setchatId(null)
      localStorage.removeItem('chatId')
      setSelectedBuilding(building_short_name)
      localStorage.setItem('selectedBuilding', building_short_name)
     },
     selectStudy(year) {
      setSelectedStudyYear(year)
      localStorage.setItem('selectedStudyYear', year)
     },
     firstName,
     isAdmin,
     configSaved,
     setConfigSaved,
     tourStatus,
     setTourStatus,
     chatId,
     updatechatId(id) {
      setchatId(id)
      localStorage.setItem('chatId', id)
     },
     isContextSecured,
     async login(post_data) {
      const url_query = `${process.env.REACT_APP_FLASK_IP}/login`;
      axios.defaults.withCredentials = true
      return axios.post(url_query, post_data)
         .then(function (resp) {
            setAuthed(true)
            setAuthFailed(false)
            localStorage.setItem('isUserLogged', true)
            setEmail(post_data["email"])
            setBuildings(resp.data["building_short_names"])
            setAddresses(resp.data["building_addresses"])
            setRoles(resp.data["building_roles"])
            // for initial login, if localStorage is empty, set selected building as primary from backend, otherwise set to what is in localStorage
            if (getLocalStorage("selectedBuilding") === null) 
            {
               setSelectedBuilding(resp.data["building_primary"])
               localStorage.setItem('selectedBuilding', resp.data["building_primary"])
            }
            setTourStatus(resp.data["tour_status"])
            setPrimaryBuilding(resp.data["building_primary"])
            setFirstName(resp.data["first_name"])
            setIsAdmin(resp.data["is_admin"] === 1 ? true : false)
            setIsContextSecured(true)
         }).catch(function (error) {
            setAuthFailed(true)
         })
     },
     logout() {
      fetchLogout()
     }
   };
}

// I added this as a parent component to rest of App components 
export function AuthProvider({ children }) { 
  const auth = useAuth()
  
  // this is providing the AuthContext data to all child components
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// still don't fully understand why this doesn't need to be called anywhere
// (only works if you do: export default)
// export default function AuthConsumer() {
//   return useContext(authContext);
// }
