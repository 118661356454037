import { useEffect, useState, useCallback, useContext } from 'react'
import { useNavigate } from "react-router-dom"
import axios from 'axios'
import { Dropdown, DropdownButton, Button, Modal, OverlayTrigger, Popover, PopoverBody, Spinner, Form, Table, ToggleButton } from 'react-bootstrap'

// context
import { authContext } from '../authContext.js'

// Components
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import RangeSlider from 'react-bootstrap-range-slider'
import MissingData from '../MissingData.js'
import OffCanvasCashflow from '../OffCanvasCashflow.js'
import SelectStudyModal from '../SelectStudyModal.js'
import OnboardingLinePlotModal from '../OnboardingLinePlotModal.js'

// Helper Functions
import { onMouseOutSync, helperPlot, negativeCash, helperPlotRange, chartExtremes, getIndex } from '../../lib/helpers/highchartsHelpers.js';
import { formatAmount, formatAmountKM } from '../../lib/helpers/formattingHelpers.js';

// logo svgs
import logoBlue from '../../svg/mini-logo-blue.svg'
import logoGreen from '../../svg/mini-logo-green.svg'
import logoYellow from '../../svg/mini-logo-yellow.svg'
import greyQuestion from '../../svg/grey-question.svg'
import handPointer from '../../svg/hand-pointer.svg'

// significant expense interface
import { getExpenseFlag } from '../../lib/helpers/tooltipFlags.js'

export default function Calculator() {
  // context and navigation
  const auth = useContext(authContext)

  const currentYear = new Date().getFullYear()

  //------------------------------------------------------------------------------------------------------------------
  // Component State
  //------------------------------------------------------------------------------------------------------------------

  const [historical, setHistorical] = useState(false)

  // get available rf studies
  const [availableRFStudies, setAvailableRFStudies] = useState([])
  const [selectedYear, setSelectedYear] = useState(null)

  // min/max data
  const [dataBaseLow, setDataBaseLow] = useState([]);
  const [dataSystemHigh, setDataSystemHigh] = useState([]);
  const [dataInterestIncome, setDataInterestIncome] = useState([]);
  const [dataOpening, setDataOpening] = useState([]);
  const [dataClosing, setDataClosing] = useState([]);

  // chart options
  const [chartOptionsReserve, setChartOptionsReserve] = useState({title: { text: ''}})
  const [chartOptionsExpenditures, setChartOptionsExpenditures] = useState({title: { text: ''}})
  const [chartOptionsRate, setChartOptionsRate] = useState({title: { text: ''}})
  const [chartOptionsComposition, setChartOptionsComposition] = useState({title: { text: ''}})
  const [chartOptionsRateCalcs, setChartOptionsRateCalcs] = useState({title: { text: ''}})
  const [chartOptionsPercentMLGIC, setChartOptionsPercentMLGIC] = useState({title: { text: ''}})
  const [chartOptionsMinCashBalance, setChartOptionsMinCashBalance] = useState({title: { text: ''}})
  const [chartOptionsVolatilityCone, setChartOptionsVolatilityCone] = useState({title: { text: ''}})

  const [detailCharts, setDetailCharts] = useState(false)

  // for mobile screens
  const [mobileScreen, setMobileScreen] = useState(true)
  const [labelRotation, setLabelRotation] = useState(0)
  const [chartSpacing, setChartSpacing] = useState([20,20,20,20])

  // chart interactive drawing setMouseOver
  const [mouseOver, setMouseOver] = useState(true)
  const [mouseOverEvent, setMouseOverEvent] = useState({})
  const [mouseOverPoint, setMouseOverPoint] = useState({})
  const [mouseOverYear, setMouseOverYear] = useState(null)
  
  // data - reserve fund chart
  const [dataVCRange, setDataVCRange] = useState([]) // single array of x,y pair arrays
  const [dataVC, setDataVC] = useState([]) // single array for chart
  const [dataTrueBenchmark, setDataTrueBenchmark] = useState([]) // single array
  const [dataTrueBenchmarkRange, setDataTrueBenchmarkRange] = useState([]) // single array
  const [dataExtremes, setDataExtremes] = useState({})
  const [dataMinYear, setDataMinYear] = useState(null)
  const [dataMaxYear, setDataMaxYear] = useState(null)

  // data - contributions chart
  const [dataContribution, setDataContribution] = useState([])
  const [dataExpenditures, setDataExpenditures] = useState([])

  // data - rates chart
  const [dataInterestRate, setDataInterestRate] = useState([])
  const [dataInflationRate, setDataInflationRate] = useState([])
  const [dataContributionRate, setDataContributionRate] = useState([])

  // data - composition chart
  const [dataCompositionCash, setDataCompositionCash] = useState([])
  const [dataCompositionNegCash, setDataCompositionNegCash] = useState([])
  const [dataCompositionGIC, setDataCompositionGIC] = useState([])
  const [dataCompositionMLGIC, setDataCompositionMLGIC] = useState([])
  
  // data - calc rate chart
  const [dataCalcInterestRate, setDataCalcInterestRate] = useState([])
  const [dataCalcInflationRate, setDataCalcInflationRate] = useState([])
  
  // buffering
  const [recalculationBuffering, setRecalculationBuffering] = useState(false)
  const [cashflowBuffering, setCashflowBuffering] = useState(true)

  // calculator inputs 
  const [savedRiskLevel, setSavedRiskLevel] = useState(0)
  const [savedMinCash, setSavedMinCash] = useState(0)
  const [savedInflationYield, setSavedInflationYield] = useState(0)
  const [savedInflationVol, setSavedInflationVol] = useState(0)
  const [savedGICYield, setSavedGICYield] = useState(0)
  const [savedGICVol, setSavedGICVol] = useState(0)
  const [savedMLGICYield, setSavedMLGICYield] = useState(0)
  const [savedMLGICVol, setSavedMLGICVol] = useState(0)
  const [savedCashIntRate, setSavedCashIntRate] = useState(0)

  const [tempRiskLevel, setTempRiskLevel] = useState(0)
  const [tempMinCash, setTempMinCash] = useState(0)
  const [tempInflationYield, setTempInflationYield] = useState(0)
  const [tempInflationVol, setTempInflationVol] = useState(0)
  const [tempGICYield, setTempGICYield] = useState(0)
  const [tempGICVol, setTempGICVol] = useState(0)
  const [tempMLGICYield, setTempMLGICYield] = useState(0)
  const [tempMLGICVol, setTempMLGICVol] = useState(0)
  const [tempCashIntRate, setTempCashIntRate] = useState(0)
  const [tempWindow, setTempWindow] = useState(null)

  // flag for if there are any saved configs
  const [savedConfig, setSavedConfig] = useState(false)

  const mlgic_yield = 0.071617
  const mlgic_vol = 0.158502

  const [showSubmitSettingsModal, setShowSubmitSettingsModal] = useState(false)
  const [showSubmitSettingsWarningModal, setShowSubmitSettingsWarningModal] = useState(false)

  // show flags
  const [dataExists, setDataExists] = useState(false)
  const [showMissingData, setShowMissingData] = useState(false)
  const [showProcessingData, setShowProcessingData] = useState(false)
  const [showHeaderModal, setShowHeaderModal] = useState(0)
  // const [showMiniDiagram, setShowMiniDiagram] = useState(false)

  // totals header
  const [labelPoint, setLabelPoint] = useState({})
  const [showSelectStudyModal, setShowSelectStudyModal] = useState(false)
  const [bypassRequest, setBypassRequest] = useState(false)
  const [showOffCanvas, setShowOffCanvas] = useState(false)
  const [showInputModal, setShowInputModal] = useState(0)
  const [showOnboardingModal, setShowOnboardingModal] = useState(false)

  const navigate = useNavigate();

  //------------------------------------------------------------------------------------------------------------------
  // Helper Functions
  //------------------------------------------------------------------------------------------------------------------

  // TODO make dataContext.js component like Firefly - for keeping all data centralized
  // TODO same as ReportCard page
  // for updating all state variables for storing chart data (this is only meant to reduce lines of code because it's called multiple times)
  function helperUpdateChartData(dataFromServer) {
    setDataMinYear(dataFromServer['year'][0])
    setDataMaxYear(dataFromServer['year'][dataFromServer['year'].length-1])

    setTempWindow(dataFromServer['year'][0])

    setDataBaseLow(dataFromServer['base_low'])
    setDataSystemHigh(dataFromServer['system_high']) // TODO calibrate setDataSystemHigh(increaseByPct(dataFromServer['system_high'], .15))
    setDataInterestIncome(dataFromServer['interestincome'])
    setDataOpening(dataFromServer['openingbalance'])
    setDataClosing(dataFromServer['closingbalance'])

    setDataContribution(helperPlot(dataFromServer['year'], dataFromServer['contribution']))
    setDataExpenditures(helperPlot(dataFromServer['year'], dataFromServer['expenditureia']))

    setDataCompositionCash(helperPlot(dataFromServer['year'], negativeCash(dataFromServer['system_cash'], true)))
    setDataCompositionNegCash(helperPlot(dataFromServer['year'], negativeCash(dataFromServer['system_cash'])))
    setDataCompositionGIC(helperPlot(dataFromServer['year'], dataFromServer['system_gic']))
    setDataCompositionMLGIC(helperPlot(dataFromServer['year'], dataFromServer['system_mlgic']))

    setDataCalcInflationRate(helperPlot(dataFromServer['year'], dataFromServer['system_inflationrate']))
    setDataCalcInterestRate(helperPlot(dataFromServer['year'], dataFromServer['system_interestrate']))

    setDataInterestRate(helperPlot(dataFromServer['year'], dataFromServer['interestrate']))
    setDataInflationRate(helperPlot(dataFromServer['year'], dataFromServer['inflationrate']))
    setDataContributionRate(helperPlot(dataFromServer['year'], dataFromServer['contributionrate']))

    // Benchmark Projections
    setDataTrueBenchmark(helperPlot(dataFromServer['year'], dataFromServer['base']))
    setDataTrueBenchmarkRange(helperPlotRange(dataFromServer['year'], dataFromServer['base_low'], dataFromServer['base_high']))

    // Vertical City Projections
    setDataVC(helperPlot(dataFromServer['year'], dataFromServer['system']))
    setDataVCRange(helperPlotRange(dataFromServer['year'], dataFromServer['system_low'], dataFromServer['system_high']))
  }

  function toPercentString(decimalNum) {
    if (decimalNum == 0) return "0%"
    else if (decimalNum < 0.01) return (decimalNum * 100).toPrecision(1).toString() + "%"
    else if (decimalNum < 0.1) return (decimalNum * 100).toPrecision(2).toString() + "%"
    else return (decimalNum * 100).toPrecision(3).toString() + "%"
  }

  function getStudyFromYear(year) {
    for (let i = 0; i < availableRFStudies.length; i++) {
      if (availableRFStudies[i]['year'].toString() == year) return year + " Study by " + availableRFStudies[i]['engineer']
    }
  }

  // syncs all charts onMouseOver
  function onMouseOverSyncRF(e, chartNames, all=false) {
    // looping through all charts
    Highcharts.charts.forEach(chart => {
      // only apply code to given charts
      if (chart && (all || chartNames.includes(chart.title.textStr)))
      {
        // collects each point at current location on x-axis e.g. for rates chart, there would be 3 points (because there are 3 series)
        let pointsTooltip = []
        // looping through series
        chart.series.forEach(s => {
          // looping through points
          s.points.forEach(p => {
            // since mouseOut behavior is unpredictable and laggy for reserve chart, same functionality is included here
            // (if marker is currently over point, remove it)
            if (p.state == 'hover') p.setState('')

            // syncs points between the graphs
            if (p.index === e.target.index)
            {
              // sets point state to "hover" (dots appear on graph at given point)
              p.setState('hover')
              pointsTooltip.push(p)

              // refreshing crosshair of given charts
              chart.xAxis[0].drawCrosshair(e, p)

              // if mouse over on support charts, then manually update main chart label
              if (chart.title.textStr === "reserve-fund-benchmark" || chart.title.textStr === "reserve-fund-comparison")
              {
                setLabelPoint(p)
                //refreshReserveLabel(p)
                // Deprecated TODO validate this is not too computationally heavy
                // drawing markers on main chart when hovering over Detail Charts 
                // setMouseOverPoint(p)
                // setMouseOverEvent(e)
                // setMouseOverYear(e.target.category)
              }    
            }
          })
        })
        // refreshing tooltip of synced charts
        if (pointsTooltip.length > 0)
        {
          // this method takes an array of point objects that it uses to display the tooltip
          chart.tooltip.refresh(pointsTooltip)
        }
      }
    })
  }

  // updates chart properties based on window size
  const resizeMobile = () => {
    if (window.matchMedia("(max-width: 560px)").matches) 
    {
      setLabelRotation(-35)
      setChartSpacing([10,0,0,0])
      setMobileScreen(true)
    } 
    else if(!window.matchMedia("(max-width: 560px)").matches ) 
    {
      setLabelRotation(0)
      setChartSpacing([20,20,20,20])
      setMobileScreen(false)
    }
  }

  //------------------------------------------------------------------------------------------------------------------
  // Component Hooks
  //------------------------------------------------------------------------------------------------------------------

  // Page Load Hook
  useEffect(() => {
    if (availableRFStudies.length === 0 && auth.isContextSecured)
    {
      // fetch building settings - requires data from previous fetch
      let url_query = `${process.env.REACT_APP_FLASK_IP}/data/settings`
      let body = { "email": auth.email, "building_short_name": auth.selectedBuilding }

      // TODO I think we need to remove this /data/settings request from this hook to stop selectStudyModal from flashing (it only happens sometimes)
      axios.defaults.withCredentials = true
      axios.post(url_query, body)
        .then(function (resp) {
          setSavedRiskLevel(resp.data["max_pct_mlgic"] * 10)
          setSavedMinCash(resp.data["min_cash_holdback"])
          setSavedMLGICYield(resp.data['mlgic_yield'])
          setSavedMLGICVol(resp.data['mlgic_vol'])
          setSavedGICYield(resp.data['int_rate_yield'])
          setSavedGICVol(resp.data['int_rate_vol'])
          setSavedInflationYield(resp.data['inf_rate_yield'])
          setSavedInflationVol(resp.data['inf_rate_vol'])
          setSavedCashIntRate(resp.data['cash_int_rate'])

          setTempRiskLevel(resp.data["max_pct_mlgic"] * 10)
          setTempMinCash(resp.data["min_cash_holdback"])
          setTempMLGICYield(resp.data['mlgic_yield'])
          setTempMLGICVol(resp.data['mlgic_vol'])
          setTempGICYield(resp.data['int_rate_yield'])
          setTempGICVol(resp.data['int_rate_vol'])
          setTempInflationYield(resp.data['inf_rate_yield'])
          setTempInflationVol(resp.data['inf_rate_vol'])
          setTempCashIntRate(resp.data['cash_int_rate'])

          if (resp.data["default_settings"] === 0)
          {
            setSavedConfig(true)
          }
          else
          {
            setSavedConfig(false)
            setDetailCharts(true)
          }
          
        })
      
      url_query = `${process.env.REACT_APP_FLASK_IP}/data/rfstudies`
      body = { "email": auth.email, "building_short_name": auth.selectedBuilding }

      axios.defaults.withCredentials = true
      axios.post(url_query, body)
        .then(function (resp) {
          // handle no RF studies - show MissingData Component
          if (Object.keys(resp.data).length === 0 || resp.data["error"])
          {
            setShowMissingData(true)
            return
          }
          setDataExists(true)

          setAvailableRFStudies(resp.data)
          if (auth.selectedStudyYear) 
          {
            setSelectedYear(auth.selectedStudyYear)
          }
          else
          {
            if (resp.data.length == 1) setSelectedYear(resp.data[0].year)
            else setShowSelectStudyModal(true)
          }
      })
    }
  }, [auth.isContextSecured])
  
  // fire after study year is selected
  useEffect(() => {
    if (selectedYear)
    {
      if (bypassRequest)
      {
        setBypassRequest(false)
        return
      }

      // fetch cashflow performance - requires data from previous fetch
      let url_query = `${process.env.REACT_APP_FLASK_IP}/data/cashflow1`
      let body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "year": selectedYear }

      axios.defaults.withCredentials = true
      axios.post(url_query, body)
        .then(function (resp) {
          setCashflowBuffering(false)
          if (resp.data["error"])
          {
            setShowProcessingData(true)
            setDataExists(false)
            return
          }
          helperUpdateChartData(resp.data)
        })
    }
  }, [selectedYear, auth.isContextSecured]) // only trigger if user selects the year, otherwise this effect will be run twice on startup

  //Set up risize event listener for chart mobile views
  useEffect(() => {

    //Initialize state variables
    resizeMobile()

    // Add event listener for window resize
    window.addEventListener('resize', resizeMobile);

  }, []);

  // Highcharts Chart Options Hook
  useEffect(() => {
    //resetReserveChart()
    if (dataVCRange.length > 0)
    {
      var tempDataStudyBenchmark = []
      
      // Find Chart Extremes (y axis boundary)
      var extremes = chartExtremes([dataBaseLow, dataSystemHigh, dataClosing])
      var tempExtremes = chartExtremes([dataVCRange])
      if (extremes.max < tempExtremes.max)
      {
        extremes.max = tempExtremes.max
        setDataSystemHigh(getIndex(dataVCRange, 2)) // for next time hook is fired
      }
      setDataExtremes(extremes)

      // setting chart options - this is done to ensure that each chart is not rerendered when risk level is changed, improving efficiency + preventing page from snapping to top
      setChartOptionsReserve({
        chart: {
          type: "line",
          height: '40%',
          borderRadius: 10, // same as other divs in index.css  
          backgroundColor: "#ffffff",
          spacing: chartSpacing,
          events: {
            // update main chart label for some non-mouseover edge cases
            render: function () {
              // if mouse is over chart, exit code
              if (this.xAxis[0].paddedTicks.length == 4) return
              
              var point
              var iSeries = 2 // True Benchmark Series              
              var lastIndex = this.series[iSeries].points.length - 1

              // on page load or recalculate request
              if (this.mainLabel == undefined)
              {
                point = this.series[iSeries].points[lastIndex]
                setLabelPoint(point)
                //refreshReserveLabel(point)
              }
            }
          }
        },
        title: {
          text: "reserve-fund-comparison",
          style: {
            display: 'none'
          }
        },
        tooltip: {
          // crosshairs: true,
          shared: true,
          // split: false,
          formatter: function () {
            return false
          }
        },
        series: [
          {
            name: 'Vertical City Range',
            type: 'areasplinerange',
            data: dataVCRange,
            color: savedConfig ? '#a7b661':'#ffffff00',
            lineWidth: 0,
            fillOpacity: 0.3,
            marker: {
              enabled: false
            }
          },
          {
            name: 'Vertical City',
            type: 'spline',
            data: dataVC,
            color: savedConfig ? '#a7b661':'#ffffff00',
            lineWidth: 2.5,
            marker: {
              enabled: false,
            }
          },
          {
            name: 'True Benchmark',
            type: 'spline',
            data: dataTrueBenchmark,
            color: '#5d98d1',
            lineWidth: 2,
            marker: {
              enabled: false,
            }
          },
          {
            name: 'True Benchmark Range',
            type: 'areasplinerange',
            data: dataTrueBenchmarkRange,
            color: '#5d98d1',
            lineWidth: 0,
            fillOpacity: 0.3,
            marker: {
              enabled: false
            }
          },
          {
            name: 'Study Benchmark',
            type: 'spline',
            data: tempDataStudyBenchmark,
            color: '#fbd57e',
            lineWidth: 2,
            marker: {
              enabled: false,
            }
          },
        ],
        legend: {
          enabled: false
        },
        plotOptions: {
          // disable markers for all series
          series: {
            marker: {
              states: {
                hover: {
                  enabled: false
                }
              }
            },
            // mouse over chart event
            point: {
              events: {
                mouseOver(e) {
                  // mouseOverYear now triggers useEffect which does chart drawing
                  setMouseOverPoint(this)
                  setMouseOverEvent(e)
                  setMouseOverYear(e.target.category) // 2048
                }
              }
            },
            events: {  
              legendItemClick: function () {
                return false
              }
            }
          }
        },
        xAxis: [{
          // visible: false,
          crosshair: true,
          lineWidth: 0,
          visible: true,
          startOnTick: true,
          endOnTick: false,
          maxPadding: 0.03,
          // draws a line where the current year is
          plotLines: [{
            color: '#000000',
            width: 2,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick positions to be start of graph, end of graph, and current year
          tickPositions: [dataMinYear, currentYear, dataMaxYear],
          labels: {rotation: labelRotation}
        }],
        yAxis: [{
          visible: true,
          title: { enabled: false },
          max: extremes.max,
          // makes minimum always 0, unless minimum point is less than 0
          min: extremes.min > 0 ? 0 : extremes.min,
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            formatter: function () {
              if (this.value != null)
              {
                return formatAmountKM(this.value)
              }
            }
          },
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [extremes.min, 0, extremes.max]
            }
          }
        }],
        credits: {
          enabled: false
        }
      })

      setChartOptionsExpenditures({
        chart: {
          borderRadius: 10, // same as other divs in index.css 
          spacing: chartSpacing,
          backgroundColor: "#ffffff",
          height: '44%'
        },
        title: {
          text: 'expenditures',
          style: {
            display: 'none'
          }
        },
        legend: {
          itemStyle: {
            'cursor': 'default'
          },
          enabled: !mobileScreen
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          split: false,
          formatter: function () {
            const expense = getExpenseFlag(auth.selectedBuilding, this.points[0].x)
            const htmlTooltip = 
            `<div>\
              <b>${this.points[0].x}</b><br/>\
              <span style='color:#5d98d1'>●</span> \
              Contribution: <b>${formatAmount(this.points[0].y, 0)}</b><br/>\
              <span style='color:#f1c21ad0'>●</span>\
              Expenditures: <b>${formatAmount(this.points[1].y, 0)}</b><br/>\
              ${(expense != undefined) 
                ? 
                `<div style='color:#66000000'>-</div>\
                <span style='color:#000000; font-size:6px; text-indent: 3px'>●</span>\
                <p style="text-indent: 40px">${expense}</p>`
                : ""
              }
            </div>`
            return mobileScreen ? false:htmlTooltip
          }
        },
        xAxis: [{
          visible: true,
          // draws line for current year
          lineWidth: 0,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick to be on current year
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [currentYear]
            }
          },
          tickPositions: [dataMinYear, currentYear, dataMaxYear],
          labels: {rotation: labelRotation}
        }],
        yAxis: [{
          visible: true,          
          lineWidth: 0,
          title: { enabled: false, text: "Canadian Dollars" },
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            formatter: function () {
              if (this.value != null)
              {
                return formatAmountKM(this.value)
              }
            }
          },
        }],
        series: [
          {
            name: 'Contribution',
            type: 'column',
            data: dataContribution,
            color: '#5d98d1',
            marker: {
              enabled: false
            }
          },
          {
            name: 'Expenditures',
            type: 'column',
            data: dataExpenditures,
            lineWidth: 0,
            color: '#f1c21ad0',
            marker: {
              enabled: false
            }
          }
        ],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncRF(e, ['reserve-fund-benchmark', 'reserve-fund-comparison', 'rates'])
                },
                // mouseOut() {
                //   onMouseOutSync()
                // }
              }
            },
            events: {
              legendItemClick: function () {
                return false;
              }
            },
            stickyTracking: true
          }
        },
        credits: {
          enabled: false
        }
      })

      setChartOptionsRate({
        chart: {
          borderRadius: 10, // same as other divs in index.css 
          spacing: chartSpacing,
          backgroundColor: "#ffffff",
          height: '44%'
        },
        title: {
          text: 'rates',
          style: {
            display: 'none'
          }
        },
        legend: {
          itemStyle: {
            'cursor': 'default'
          },
          enabled: !mobileScreen
        },
        tooltip: {
          split: false,
          crosshairs: true,
          shared: true,
          formatter: function () {
            var html_tooltip = '<b>Year ' + this.x + '</b><br/>'
            for (let i in this.points)
            {
              html_tooltip += '<span style="color:' + this.points[i].series.color + ';">\u25CF </span>' + this.points[i].series.name + ': ' + '<b>' + formatAmount(this.points[i].y * 100, 2, false, true) + '%' + '</b><br/>'
            }
            return mobileScreen ? false:html_tooltip
          },
        },
        xAxis: [{
          visible: true,
          gridLineWidth: 1,
          gridLineColor: "#5d98d1",
          lineWidth: 0,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick to be current year
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [currentYear]
            }
          },
          tickPositions: [dataMinYear, currentYear, dataMaxYear],
          labels: {rotation: labelRotation}
        }],
        yAxis: [{
          visible: true,
          // max: .1,
          min: 0,
          // tickPositions: [0],
          title: { enabled: false, text: "Canadian Dollars" },
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            formatter: function () {              
              return this.value * 100 + '%'              
            }
          }
        }],
        series: [
          {
            name: 'Contribution Rate',
            data: dataContributionRate,
            color: '#5d98d1',
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          },
          {
            name: 'Interest Rate',
            data: dataInterestRate,
            color: '#a7b661',
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          },
          {
            name: 'Inflation Rate',
            data: dataInflationRate,
            color: '#f1c21ad0',
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          }
        ],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncRF(e, ['reserve-fund-benchmark', 'reserve-fund-comparison', 'expenditures'])
                },
                // mouseOut() {
                //   onMouseOutSync()
                // }
              }
            },
            events: {
              legendItemClick: function () {
                return false;
              }
            }
          }
        },
        credits: {
          enabled: false
        }
      })

      setChartOptionsComposition({
        chart: {
          type: 'area',
          borderRadius: 10, // same as other divs in index.css 
          spacing: chartSpacing,
          backgroundColor: "#ffffff",
          height: '44%'
        },
        title: {
          text: 'composition',
          style: {
            display: 'none'
          }
        },
        legend: {
          itemStyle: {
            'cursor': 'default'
          },
          enabled: !mobileScreen
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          split: false,
          formatter: function () {
            const expense = getExpenseFlag(auth.selectedBuilding, this.points[0].x)
            const htmlTooltip = 
            `<div>\
              <b>${this.points[0].x}</b>\
              <br/>\
              <span style='color:#5d98d1'>●</span> \
              Cash: <b>${(this.points[0].percentage).toFixed(1)}%</b> (${formatAmountKM(this.points[0].y, 0)}) <br/>\
              <span style='color:#f55656'>●</span> \
              Neg. Cash: <b>${(this.points[1].percentage).toFixed(1)}%</b> (${formatAmountKM(this.points[1].y, 0)}) <br/>\
              <span style='color:#a7b661'>●</span> \
              GIC: <b>${(this.points[2].percentage).toFixed(1)}%</b> (${formatAmountKM(this.points[2].y, 0)}) <br/>\
              <span style='color:#f1c21ad0'>●</span>\
              MLGIC: <b>${(this.points[3].percentage).toFixed(1)}%</b> (${formatAmountKM(this.points[3].y, 0)}) <br/>\
            </div>`
            return mobileScreen ? false:htmlTooltip
          }
        },
        xAxis: [{
          visible: true,
          // draws line for current year
          lineWidth: 0,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick to be on current year
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [currentYear]
            }
          },
          tickPositions: [dataMinYear, currentYear, dataMaxYear],
          labels: {rotation: labelRotation}
        }],
        yAxis: [{
          title: { enabled: false},
          visible: true,          
          lineWidth: 0,
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            format: '{value}%'
        }
        }],
        series: [
          {
            name: 'Cash',
            data: dataCompositionCash,
            color: '#5d98d1',
            marker: {
              enabled: false
            }
          },
          {
            name: 'Negative Cash',
            data: dataCompositionNegCash,
            color: '#f55656',
            marker: {
              enabled: false
            }
          },
          {
            name: 'GIC',
            data: dataCompositionGIC,
            color: '#a7b661',
            marker: {
              enabled: false
            }
          },
          {
            name: 'MLGIC',
            data: dataCompositionMLGIC,
            lineWidth: 0,
            color: '#f1c21ad0',
            marker: {
              enabled: false
            }
          }
        ],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncRF(e, ['reserve-fund-benchmark', 'reserve-fund-comparison', 'rates'])
                },
                // mouseOut() {
                //   onMouseOutSync()
                // }
              }
            },
            events: {
              legendItemClick: function () {
                return false;
              }
            },
            stickyTracking: true
          },
          area: {
            stacking: 'percent',
            marker: {
                enabled: false
            }
          }
        },
        credits: {
          enabled: false
        }
      })

      setChartOptionsRateCalcs({
        chart: {
          borderRadius: 10, // same as other divs in index.css 
          spacing: chartSpacing,
          backgroundColor: "#ffffff",
          height: '44%'
        },
        title: {
          text: 'calculated-rates',
          style: {
            display: 'none'
          }
        },
        legend: {
          itemStyle: {
            'cursor': 'default'
          },
          enabled: !mobileScreen
        },
        tooltip: {
          split: false,
          crosshairs: true,
          shared: true,
          formatter: function () {
            var html_tooltip = '<b>Year ' + this.x + '</b><br/>'
            for (let i in this.points)
            {
              html_tooltip += '<span style="color:' + this.points[i].series.color + ';">\u25CF </span>' + this.points[i].series.name + ': ' + '<b>' + formatAmount(this.points[i].y * 100, 2, false, true) + '%' + '</b><br/>'
            }
            return mobileScreen ? false:html_tooltip
          },
        },
        xAxis: [{
          visible: true,
          gridLineWidth: 1,
          gridLineColor: "#5d98d1",
          lineWidth: 0,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick to be current year
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [currentYear]
            }
          },
          tickPositions: [dataMinYear, currentYear, dataMaxYear],
          labels: {rotation: labelRotation}
        }],
        yAxis: [{
          visible: true,
          // max: .1,
          min: 0,
          // tickPositions: [0],
          title: { enabled: false, text: "Canadian Dollars" },
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            formatter: function () {              
              return this.value * 100 + '%'              
            }
          }
        }],
        series: [
          {
            name: 'Simulated Interest Rate',
            data: dataCalcInterestRate,
            color: '#a7b661',
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          },
          {
            name: 'Simulated Inflation Rate',
            data: dataCalcInflationRate,
            color: '#fbd57e',
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          }
        ],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncRF(e, ['reserve-fund-benchmark', 'reserve-fund-comparison', 'expenditures'])
                },
                // mouseOut() {
                //   onMouseOutSync()
                // }
              }
            },
            events: {
              legendItemClick: function () {
                return false;
              }
            }
          }
        },
        credits: {
          enabled: false
        }
      })

      setChartOptionsMinCashBalance({
        chart: {
          borderRadius: 10, // same as other divs in index.css 
          spacing: chartSpacing,
          backgroundColor: "#ffffff",
          height: '40%',
        },
        title: {
          text: 'Min Cash Balance',
          style: {
            display: 'none'
          }
        },
        legend: {
          itemStyle: {
            'cursor': 'default'
          },
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          split: false,
          formatter: function () {
            const expense = getExpenseFlag(auth.selectedBuilding, this.points[0].x)
            const htmlTooltip = 
            `<div>\
              <b>${this.points[0].x}</b><br/>\
              <span style='color:#5d98d1'>●</span> \
              Cash Balance: <b>${formatAmount(this.points[0].y, 0)}</b><br/>\
              <span style='color:#ff0000'>●</span> \
              Min Cash Balance: <b>${formatAmountKM(tempMinCash)}</b><br/>\
            </div>`
            return htmlTooltip
          }
        },
        xAxis: [{
          visible: true,
          lineWidth: 0,
          labels: {rotation: labelRotation}
        }],
        yAxis: [{
          visible: true,          
          lineWidth: 0,
          title: { enabled: false, text: "Canadian Dollars" },
          startOnTick: false,
          endOnTick: false,
          plotLines: [
            {
              color: '#FF0000',
              width: 2,
              value: tempMinCash,
              zIndex: 5,
              label: 'Min Cash Balance'
            },
            {
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
            }
          ],
          labels: {
            formatter: function () {
              if (this.value != null)
              {
                return formatAmountKM(this.value)
              }
            }
          },
        }],
        series: [
          {
            name: 'Cash Balance',
            type: 'column',
            data: dataCompositionCash,
            color: '#5d98d1',
            marker: {
              enabled: false
            }
          },
          {
            name: 'Min Cash Balance',
            type: 'line',
            color: '#FF0000',
            marker: {
              enabled: false
            }
          }
        ],
        plotOptions: {
          series: {
            events: {
              legendItemClick: function () {
                return false;
              }
            },
            stickyTracking: true
          }
        },
        credits: {
          enabled: false
        }
      })

    }
  }, [dataVCRange, mobileScreen, savedConfig])

  // For configuration input modal charts
  useEffect(() => {

    var averageData = Array(8).fill(Number((1).toFixed(1)))
    var varianceData = []
    var lineName = ''
    var rangeName = ''
    var stepSize = 0;
    var maxY = 0;
    var minY = 0;

    // set appropriate values based on chart that is displaying
    if (showInputModal === 3)
    {
      lineName = 'Average'
      rangeName = 'Effect of Varience'
      stepSize = tempInflationVol
      maxY = 10
      minY = -10
    }
    else if (showInputModal === 4)
    {
      lineName = 'Yield'
      rangeName = 'Effect of Volatility'
      stepSize = tempGICVol
      maxY = 10
      minY = -10
    }
    else
    {
      lineName = 'Yield'
      rangeName = 'Effect of Volatility'
      stepSize = tempMLGICVol
      maxY = 20
      minY = -20
    }

    // Creates varianceData for cone chart
    for (let i = 0; i < 8; i++) 
    {
      const row = [];
      for (let j = 0; j < 2; j++) 
      {
          if (j == 0)
          {
            row.push(Number((1 - i*stepSize*10).toFixed(1)))
          }
          else
          {
            row.push(Number((1 + i*stepSize*10).toFixed(1)))
          }
          
      }
      varianceData.push(row)
  }


    setChartOptionsPercentMLGIC({
      chart: {
        type: 'bar',
        height: '100vh',
        borderRadius: 10, // same as other divs in index.css 
        spacing: chartSpacing,
        margin: [10, 10, 40, 10],
      },
      title: {
        text: 'Investment Composition',
        style: {
          display: 'none'
        }
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
        reversed: true,
        min: 0,
        max: 100,
        title: {
          text: 'Precent of total funds invested',
        },
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          dataLabels: {
            enabled: true, 
            format: '{point.y}%', 
            style: {
              textOutline: 'transparent',
            },
          },
        },
      },
      series: [
        {
          name: 'Market Linked GICs',
          data: [Number(tempRiskLevel)*10],
          color: '#A7B661',
        },
        {
          name: 'Traditional GICs',
          data: [100 - Number(tempRiskLevel)*10],
          color: '#5d98d1',
        },
      ],
      credits: {
        enabled: false
      }
    })

    setChartOptionsVolatilityCone({
      chart: {
        height: '40%',
        borderRadius: 10, // same as other divs in index.css 
        spacing: chartSpacing,
        margin: [10, 10, 20, 10],
      },
      title: {
        text: 'effects of yield and volalility',
        style: {
          display: 'none'
        }
      },
      xAxis: {
          title: {
              text: 'Years',
          },
          visible: false,
      },
      yAxis: {
          title: {
              text: 'Value Range',
          },
          min: minY,
          max: maxY,
          visible: false
      },
      tooltip: {
        // crosshairs: true,
        shared: true,
        // split: false,
        formatter: function () {
          return false
        }
      },
      series: [ 
        {
          name: lineName,
          data: averageData,
          type: 'spline',
          color: '#a7b661',
          marker: {
            enabled: false,
            symbol: 'circle'
          }
        }, 
        {
          name: rangeName,
          data: varianceData,
          type: 'areasplinerange',
          lineWidth: 0,
          color: '#a7b661',
          fillOpacity: 0.3,
          marker: {
            enabled: false,
            symbol: 'circle'
          },
        }
      ],
      credits: {
        enabled: false
      }
    })

  }, [showInputModal])

  // Mouse Over X-Axis Tick Hook - triggered when mouse over main chart point changes
  useEffect(() => {
    if (mouseOverYear)
    {
      //----------------------------------------------------
      // Transparent box to right of hovered point
      //----------------------------------------------------

      var width = mouseOverPoint.series.chart.plotWidth - mouseOverPoint.plotX,
          height = mouseOverPoint.series.chart.plotHeight
      
      // destory box if already exists
      if (mouseOverPoint.series.chart.transparentBox != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.transparentBox).length > 0) mouseOverPoint.series.chart.transparentBox.destroy()
      }

      // adjust x coordinates of box and markers depending on starting position of chart (varies depending on y-axis ticks)
      var xAxisPadding = 0
      if (dataExtremes.max < 10000000)
      {
        if (dataExtremes.min < 0) xAxisPadding = 10
        else xAxisPadding = 5
      }
      else if (dataExtremes.min < 0)
      {
        if (dataExtremes.min < -10000000) xAxisPadding = 8
        else xAxisPadding = 10
      }

      // draw transparent box to right of hovered point
      mouseOverPoint.series.chart.transparentBox = mouseOverPoint.series.chart.renderer.rect(mouseOverPoint.plotX + 65 + xAxisPadding + 1, 0, width + 15, height + 19, 1, 0)
        .attr({
          fill: "rgba(255,255,255,0.75)",
          zIndex: 3
        })
        .add()

      //----------------------------------------------------
      // Draw Markers
      //----------------------------------------------------
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.marker1 != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.marker1).length > 0)
        {
          mouseOverPoint.series.chart.marker1.destroy()
          if (savedConfig) mouseOverPoint.series.chart.marker2.destroy()
        }
      }

      // True Benchmark (blue marker)
      mouseOverPoint.series.chart.marker1 = mouseOverPoint.series.chart.renderer.rect(
        mouseOverPoint.plotX + xAxisPadding  + (mobileScreen ? 39:59.9), 
        mouseOverPoint.series.chart.series[2].data[mouseOverPoint.index].plotY + (mobileScreen?5.5:15.5), 
        10, 10, 5, 0
      ).attr({
        fill: "#5d98d1", 
        zIndex: 4
      }).add()

      // Vertical City (green marker)
      if(savedConfig)
      {
        mouseOverPoint.series.chart.marker2 = mouseOverPoint.series.chart.renderer.rect(
          mouseOverPoint.plotX + xAxisPadding + (mobileScreen ? 39:59.9), 
          mouseOverPoint.series.chart.series[1].data[mouseOverPoint.index].plotY + (mobileScreen?5.5:15.5), 
          10, 10, 5, 0
        ).attr({
          fill: "#a7b661", 
          zIndex: 4
        }).add()   
      }
           

      //----------------------------------------------------
      // Draw Data Label (next to points)
      //----------------------------------------------------
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.dataLabel != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.dataLabel).length > 0) mouseOverPoint.series.chart.dataLabel.destroy()
      }
      

      if (savedConfig)
      {
        var seriesA = mouseOverPoint.series.chart.series[2].data[mouseOverPoint.index].plotY
        var y // calc middle point between 2 points
        var value // calc difference of 2 points 
        var seriesC = mouseOverPoint.series.chart.series[1].data[mouseOverPoint.index].plotY
        y = ((seriesA - seriesC) / 2) + seriesC
        value = mouseOverPoint.series.chart.series[1].data[mouseOverPoint.index].y - mouseOverPoint.series.chart.series[2].data[mouseOverPoint.index].y

        var color = (value > 0) ? 'green' : 'red'
        var html = `<div className='data-label' id=${color}>${(color == 'green' ? '+' : '')}${formatAmountKM(value)}</div>`
        var xAxisLabelPadding = 72

        if (mouseOverPoint.index == mouseOverPoint.series.points.length - 1) xAxisLabelPadding = 62

        // TODO styling, bolder
        if (value != 0) mouseOverPoint.series.chart.dataLabel = mouseOverPoint.series.chart.renderer.label(
          mobileScreen ? null:html,
          mouseOverPoint.plotX + xAxisLabelPadding + xAxisPadding,
          y + 6.8, // TODO adjust this value depending on shape/size of label
          null, null, null, true
        ).attr({
          zIndex: 4
        }).add()
      }

      //----------------------------------------------------
      // Update Main Chart Label
      //----------------------------------------------------
      
      setLabelPoint(mouseOverPoint)
      //refreshReserveLabel(mouseOverPoint)

      //----------------------------------------------------
      // Sync All Charts
      //----------------------------------------------------

      onMouseOverSyncRF(mouseOverEvent, ['rates', 'expenditures'])

      //----------------------------------------------------
      // Draw X Axis Tick
      //----------------------------------------------------

      const tempReserveOptions = {
        xAxis: [{
          tickPositions: [dataMinYear, currentYear, mouseOverYear, dataMaxYear]
        }],
      }
      setChartOptionsReserve(tempReserveOptions)      
    }
  }, [mouseOverYear, auth.isContextSecured])

  // Mouse Out Chart Component Hook
  useEffect(() => {
    if (Object.keys(mouseOverPoint).length == 0) return
    if (mouseOverPoint.index == null) return
    if (recalculationBuffering) return
    if (mouseOver == false)
    {
      
      //----------------------------------------------------
      // Destroy Drawn SVGs
      //----------------------------------------------------

      // destory box if already exists
      if (mouseOverPoint.series.chart.transparentBox != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.transparentBox).length > 0) mouseOverPoint.series.chart.transparentBox.destroy()
      }
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.marker1 != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.marker1).length > 0)
        {
          mouseOverPoint.series.chart.marker1.destroy()
          if (savedConfig) mouseOverPoint.series.chart.marker2.destroy()
        }
      }
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.dataLabel != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.dataLabel).length > 0) mouseOverPoint.series.chart.dataLabel.destroy()
      }
      
      //----------------------------------------------------
      // Reset Main Chart Label
      //----------------------------------------------------

      //resetReserveChart()
      onMouseOutSync(['rates', 'expenditures'])

      //----------------------------------------------------
      // Remove X Tick
      //----------------------------------------------------

      const tempReserveOptions = {
        xAxis: [{
          tickPositions: [dataMinYear, currentYear, dataMaxYear]
        }],
      }
      setChartOptionsReserve(tempReserveOptions)
    }
  }, [mouseOver])

  //------------------------------------------------------------------------------------------------------------------
  // Handle Function
  //------------------------------------------------------------------------------------------------------------------

  function handleSelectYear(year) {
    setMouseOverPoint({}) // this is necessary to prevent onMouseOut useEffect hook from throwing error on null chart object
    setSelectedYear(year)
    auth.selectStudy(year.toString())

    // if (currentYear - year > 0) setShowMiniDiagram(true)
    // else setShowMiniDiagram(false)
  }

  function handleCalculate(year) {
    // check if called from button click or not
    if (typeof year != 'number') year = selectedYear

    var url_query
    var body

    if (historical)
    {
      url_query = `${process.env.REACT_APP_FLASK_IP}/data/cashflow1history`
      body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "year": year, "window": tempWindow, "max_pct_mlgic": tempRiskLevel,
        "min_cash_holdback": tempMinCash, "inf_rate_yield": tempInflationYield, "inf_rate_vol": tempInflationVol,
        "int_rate_yield": tempGICYield, "int_rate_vol": tempGICVol, "mlgic_yield": tempMLGICYield, "mlgic_vol": tempMLGICVol, 'cash_int_rate': tempCashIntRate}
      return // TODO remove this for testing
    }
    else
    {
      url_query = `${process.env.REACT_APP_FLASK_IP}/data/cashflow1custom`
      body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "year": year, "max_pct_mlgic": tempRiskLevel,
        "min_cash_holdback": tempMinCash, "inf_rate_yield": tempInflationYield, "inf_rate_vol": tempInflationVol,
        "int_rate_yield": tempGICYield, "int_rate_vol": tempGICVol, "mlgic_yield": tempMLGICYield, "mlgic_vol": tempMLGICVol, 'cash_int_rate': tempCashIntRate}
    }

    setRecalculationBuffering(true)
  
    console.log('handleCalculate request', body)
    axios.defaults.withCredentials = true
    axios.post(url_query, body)
      .then(function (resp) {
        console.log('handleCalculate', resp.data)
        setRecalculationBuffering(false)

        helperUpdateChartData(resp.data)
      })

    setSavedConfig(true)
    setDetailCharts(false)
  }

  function handleSaveConfiguration() {
    if (selectedYear != availableRFStudies[0].year)
    {
      setShowSubmitSettingsWarningModal(true)
    }
    else
    {
      setShowSubmitSettingsModal(true)
    }
  }

  //------------------------------------------------------------------------------------------------------------------
  // Callback Functions
  //------------------------------------------------------------------------------------------------------------------

  const handleSyncCharts = useCallback((e, chartNames, all=false) => {
    onMouseOverSyncRF(e, chartNames, all)
  }, [])
  
  const handleCloseOffCanvas = useCallback(() => {
    setShowOffCanvas(false)
  }, [])

  const handleCloseOnboarding = useCallback(() => {
    setShowOnboardingModal(false)
  }, [])

  const handleSelectStudy = useCallback((year) => {
    setShowSelectStudyModal(false)
    handleSelectYear(year)
  }, [])

  //------------------------------------------------------------------------------------------------------------------
  // Components
  //------------------------------------------------------------------------------------------------------------------

  function TotalsHeader() {
    if (Object.keys(labelPoint).length === 0 ) return <></>
    if (labelPoint.category == null) return <></>
    const chart = labelPoint.series.chart;
    const index = labelPoint.index
    const year = labelPoint.x

    var studyBenchmark = null
    var benchmark = null
    var benchmarkRange = null
    var vc = null
    var vcRange = null

    // sets label data
    chart.series.forEach(s => {
      if (s.name === "Study Benchmark")
      {
        studyBenchmark = s.yData[index]
      }
      else if (s.name === "True Benchmark")
      {
        benchmark = s.yData[index]
      }
      else if (s.name === "True Benchmark Range")
      {
        benchmarkRange = s.yData[index]
      }
      else if (s.name === "Vertical City")
      {
        vc = s.yData[index]
      }
      else if (s.name === "Vertical City Range")
      {
        vcRange = s.yData[index]
      }
    })

    var difference = vc - benchmark
    var pos = false
    if (difference > 0) pos = true
    var numYears = year - currentYear

    function Difference() {
      return (
        <button className='component-container' style={{'width':'100%', 'paddingTop': '0px', 'paddingBottom': '10px'}} onClick={() => setShowHeaderModal(1)}>
          <div id='difference' className='flex-column-breakpoint'>
            <div style={{'width':'90%', 'marginBottom':"0px"}}>
              <h5 id='difference'>Difference</h5>
              <p>(Custom - Simulated)</p>
            </div>
            <div className='vertical'>
              <div  className='flex'>
                <div className={'amount medium ' + (pos ? 'positive': (difference == 0 ? 'nill': 'negative'))}>{pos ? '+': ''}{formatAmount(difference, 0)}</div>
              </div>
              <p id='difference' className='smaller right' style={{'marginTop': '-10px'}}>In {numYears} years</p>
            </div>
          </div>
        </button>
      )
    }

    function TrueBenchmark() {
      return (
        <button className='component-container' style={{'width':'100%', 'padding': '0px 10px 10px','marginRight':'10px'}} onClick={() => setShowHeaderModal(2)}>
          <div className='flex-column-breakpoint'>
            <div style={{'width':'80%'}}>
              <h5 id='simulated' style={{'color':'#5d98d1'}}>Simulated</h5>
              <p id='portfolio-date' style={{'marginLeft': '2px', 'marginTop':'-5px'}}>As of {year}</p>
            </div>
            <div className='vertical'>
              <div  className='flex'>
                <div className='circle-legend' id='true-bm'/>
                <div className='amount medium'>{formatAmount(benchmark, 0)}</div>
              </div>
              <div >
                <p id='range' className='smaller right'>${formatAmountKM(benchmarkRange[0], 0) + " to $" + formatAmountKM(benchmarkRange[1], 0)}</p>
              </div>
            </div>
          </div>
        </button>
      )
    }

    function CustomBenchmark() {
      return (
        <button className='component-container' style={{'width':'100%', 'padding': '0px 10px 10px'}} onClick={() => setShowHeaderModal(3)}>
          <div className='flex-column-breakpoint'>
            <div style={{'width':'80%'}}>
              <h5 id='simulated' style={{'color': '#a7b661'}}>Custom</h5>
              <p id='portfolio-date' style={{'marginLeft': '2px','marginTop':'-5px'}}>Year {year}</p>
            </div>
            <div className='vertical'>
              <div  className='flex'>
                <div className='circle-legend' id='vc-bm' />
                <div className='amount medium'>{formatAmount(vc, 0)}</div>
              </div>
              <div >
                <p id='range' className='smaller right'>${formatAmountKM(vcRange[0], 0) + " to $" + formatAmountKM(vcRange[1], 0)}</p>
              </div>
            </div>
          </div>
        </button>
      )
    }

    function StudyBenchmark() {
      return (
        <button className='component-container' style={{'width':'37.5%', 'paddingTop': '0px'}} onClick={() => setShowHeaderModal(2)}>
            <div className='flex-column-breakpoint'>
              <div style={{'width':'90%'}}>
                <h5 style={{'marginTop': '25px', 'color': '#f1c31a'}}>Study</h5>
                <p id='portfolio-date' style={{'marginLeft': '2px'}}>As of {year}</p>
              </div>
              <div className='vertical'>
                <div  className='flex'>
                  <div className='circle-legend' id='study-bm' />
                  <div className='amount medium'>{formatAmount(studyBenchmark, 0)}</div>
                </div>
              </div>
            </div>
        </button>
      )
    }

    function NoInput() {
      return (
        <div className='cell-description component-container' style={{'width':'100%', 'padding':'10px'}}>
          <div className='flex-column-breakpoint' style={{'justifyContent': 'center','alignItems':'center', 'textAlign':'center', 'height':'100%',}}>
            <h5 id='noinput'>Enter Configurations Below!</h5>
            <img style={{'marginLeft': '10px', 'transform':'scaleY(-1)'}} src={handPointer} alt="Hand Pointer"/>
          </div>
        </div>
      ) 
    }

    if (studyBenchmark)
    {
      return (
        <div className='flex-column-mobile totals' style={{'marginBottom': '10px', 'marginTop': '5px', 'gridGap': '10px'}}>
          <div className='totals' style={{'width':'33%','display':'flex', 'marginTop':'0px', 'marginBottom':'0px'}}>
            <StudyBenchmark />
          </div>
          <div className='totals' style={{'width':'66%','display':'flex', 'marginTop':'0px', 'marginBottom':'0px'}}>
            <TrueBenchmark />
            <Difference />
          </div>
        </div>
     )
    }
    else
    {
      return (
        <div className='flex-column-mobile totals' style={{'marginBottom': '10px', 'marginTop': '5px', 'gridGap': '10px'}}>
          <div className='totals' style={{'width':'33%','display':'flex', 'marginTop':'0px', 'marginBottom':'0px'}}>
            <Difference />
          </div>
          <div className='totals' style={{'width':'66%','display':'flex', 'marginTop':'0px', 'marginBottom':'0px'}}>
            <TrueBenchmark />
            {savedConfig && <CustomBenchmark />}
            {!savedConfig && <NoInput />}
          </div>
        </div>
     )
    }
  }

  // TODO add more content to each modal; draw diagrams for absolute beginers
  // TODO improve writing of each modal v2
  function HeaderModal(props) {
    var title
    var text
    var idCircleLegend = ""
    var subheader
    if (showHeaderModal==1)
    {
      title = "Difference"
      text = <span>This is the difference between the <span style={{'color': '#a7b661', 'font-weight': '600'}}>Custom</span> line and the <span style={{'color': '#5d98d1', 'font-weight': '600'}}>Simulated</span> line. If the number is <span style={{'color': '#f55656'}}>negative</span> it means that your saved configuration will preform worse than if didn't change anything. If it is <span style={{'color': '#98b02c'}}>positive</span>, it means your saved configuration will improve the fund compared to not changing anything.</span>
      subheader = <div>(<span style={{'color': '#a7b661'}}>Custom</span> - <span style={{'color': '#5d98d1'}}>Simulated</span>)</div>
    }
    else if (showHeaderModal==2)
    {
      title = 'Simulated'
      text = "This is a more accurate forecast of the reserve fund using the same expenditures and contributions as the reserve fund study. It's basically the 2.0 version of the Cashflow Table that you are used to using. This line is simulated using forecasted interest and inflation rates as well as by using a more realistic forecasting process than that of a Reserve Fund Study."
      idCircleLegend = "true-bm"
      subheader = <div>The better forecast</div>
    }
    else if (showHeaderModal==3)
    {
      title = 'Custom'
      text = <span>This is the line showing what your reserve fund would look like under the new configurations that you have saved below. For more information on how this process works, you can read more <a target="_blank" href='https://www.verticalcity.ca/post/vertical-city-toolkit-reserve-fund-forecasting#viewer-c7p47'>here</a>.</span>
      idCircleLegend = "vc-bm"
      subheader = <div>Saved Configuration</div>
    }

    return (
      <Modal className="totals-modal totals" dialogClassName="modal-width" show={props.show} onHide={() => setShowHeaderModal(0)} size="lg" centered>
        <Modal.Header>
          <div>
            <div className='flex'>
              <Modal.Title>{title}</Modal.Title>
              {idCircleLegend && <div className='circle-legend' id={idCircleLegend} style={{'margin-right': '0', 'margin-left': '10px'}} />}
            </div>
            {subheader}
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className='space-between'>
          {text}
        </div>
        </Modal.Body>
      </Modal>
    )
  }

  function SubmitSettingsModal(props) {

    // variables to keep track of what confics have been changed
    var changedPerMLGIC = true
    var changedMinCash = true
    var changedInfAve = true
    var changedInfVar = true
    var changedGICYield = true
    var changedGICVol = true
    var changedMLGICYield = true
    var changedMLGICVol = true
    var changedConfigs = []

    function handleSaveConfig() {
      setShowSubmitSettingsModal(false)
      const url_query = `${process.env.REACT_APP_FLASK_IP}/upload/settings`
      const body = {
        "email": auth.email,
        "building_short_name": auth.selectedBuilding,
        "year": selectedYear,
        "max_pct_mlgic": tempRiskLevel,
        "min_cash_holdback": tempMinCash,
        "mlgic_yield": tempMLGICYield, 
        "mlgic_vol": tempMLGICVol, 
        "inf_rate_yield": tempInflationYield, 
        "inf_rate_vol": tempInflationVol,
        "int_rate_yield": tempGICYield, 
        "int_rate_vol": tempGICVol, 
        "cash_int_rate": tempCashIntRate
      }

      setRecalculationBuffering(true)

      axios.defaults.withCredentials = true
      axios.post(url_query, body)
        .then(function (resp) {
          helperUpdateChartData(resp.data)
          setRecalculationBuffering(false)

          setSavedRiskLevel(tempRiskLevel)
          setSavedMinCash(tempMinCash)
          setSavedMLGICYield(tempMLGICYield)
          setSavedMLGICVol(tempMLGICVol)
          setSavedGICYield(tempGICYield)
          setSavedGICVol(tempGICVol)
          setSavedInflationYield(tempInflationYield)
          setSavedInflationVol(tempInflationVol)
          setSavedCashIntRate(tempCashIntRate)
        })
      
      auth.setConfigSaved(true)
      setSavedConfig(true)
      setDetailCharts(false)
    }

    // set value of variables keeping track of what configs have changed
    if (savedRiskLevel === tempRiskLevel)
    {
      changedPerMLGIC = false 
      changedConfigs.push(' Market Linked GICs Percentage')
    }
    if (savedMinCash === tempMinCash)
    {
      changedMinCash = false
      changedConfigs.push(' Min Cash Balance')
    }
    if (savedInflationYield === tempInflationYield)
    {
      changedInfAve = false
      changedConfigs.push(' InflationRate Average')
    }
    if (savedInflationVol === tempInflationVol)
    {
      changedInfVar = false
      changedConfigs.push(' Inflation Rate Variance')
    }
    if (savedGICYield === tempGICYield)
    {
      changedGICYield = false
      changedConfigs.push(' GIC Interest Rate Yield')
    }
    if (savedGICVol === tempGICVol)
    {
      changedGICVol = false
      changedConfigs.push(' GIC Interest Rate Volatility')
    }
    if (savedMLGICYield === tempMLGICYield)
    {
      changedMLGICYield = false
      changedConfigs.push(' Market Linked GIC Yield')
    }
    if (savedMLGICVol === tempMLGICVol)
    {
      changedMLGICVol = false
      changedConfigs.push(' Market Linked GIC Volatility')
    }

    // TODO make this modal look more organized - don't have multiple titles for setting; use table format
    return (
      <Modal show={props.show} onHide={() => setShowSubmitSettingsModal(false)} backdrop="static" size="md" className="submit-settings-modal" dialogClassName="modal-width">
        <Modal.Header closeButton>
            <Modal.Title>Save Configuration</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{'paddingBottom':'0px'}}>
        <div style={{'marginBottom': '10px'}}>Are you sure you want to save the following calculator configuration to your building's profile?</div>
        <Table className='save-config-table'>
            <thead>
              <tr>
                <th>Configuration</th>
                <th>Old</th>
                <th>New</th>
              </tr>
            </thead>
            <tbody>
              {(changedPerMLGIC) && (
              <tr>
                <td >Market Linked GIC Composition</td>
                <td>{(savedRiskLevel == 0) ? "0%" : savedRiskLevel.toString() + "0%"}</td>
                <td>{(tempRiskLevel == 0) ? "0%" : tempRiskLevel.toString() + "0%"}</td>
              </tr>
              )}
              {(changedMinCash) && (
              <tr>
                <td>Minimum Cash Balance</td>
                <td>{formatAmount(savedMinCash, 0)}</td>
                <td>{formatAmount(tempMinCash, 0)}</td>
              </tr>
              )}
              {((changedInfAve) || (changedInfVar)) && (
              <tr id='group-top' style={{'borderColor':'#ffffff'}}>
                <td style={{'paddingBottom':'0px'}}>
                  <b>Inflation Rate</b>
                </td>
                <td></td>
                <td></td>
              </tr>
              )}
              {(changedInfAve) && (
              <tr id={(changedInfVar) ? 'group-middle':'group-bottom'} style={(changedInfVar) ? {'borderColor':'#ffffff'}:{}}>
                <td >Average</td>
                <td>{toPercentString(savedInflationYield)}</td>
                <td>{toPercentString(tempInflationYield)}</td>
              </tr>
              )}
              {(changedInfVar) && (
              <tr id='group-bottom'> 
                <td>Variance</td>
                <td>{toPercentString(savedInflationVol)}</td>
                <td>{toPercentString(tempInflationVol)}</td>
              </tr>
              )}
              {((changedGICYield) || (changedGICVol)) &&(
              <tr id='group-top' style={{'borderColor':'#ffffff'}}>
                <td>
                  <b>GIC Interest Rate</b>
                </td>
                <td></td>
                <td></td>
              </tr>
              )}
              {(changedGICYield) && (
              <tr id={(changedGICVol) ? 'group-middle':'group-bottom'} style={(changedGICVol) ? {'borderColor':'#ffffff'}:{}}>
                <td>Yield</td>
                <td>{toPercentString(savedGICYield)}</td>
                <td>{toPercentString(tempGICYield)}</td>
              </tr>
              )}
              {(changedGICVol) && (
              <tr id='group-bottom'>
                <td>Volatility</td>
                <td>{toPercentString(savedGICVol)}</td>
                <td>{toPercentString(tempGICVol)}</td>
              </tr>
              )}
              {((changedMLGICYield) || (changedMLGICVol)) && (
              <tr id='group-top' style={{'borderColor':'#ffffff'}}>
                <td>
                  <b>Market Linked GIC</b>
                </td>
                <td></td>
                <td></td>
              </tr>
              )}
              {(changedMLGICYield) && (
              <tr id={(changedMLGICVol) ? 'group-middle':'group-bottom'} style={(changedMLGICVol) ? {'borderColor':'#ffffff'}:{}}>
                <td>Yeild</td>
                <td>{toPercentString(savedMLGICYield)}</td>
                <td>{toPercentString(tempMLGICYield)}</td>
              </tr>
              )}
              {(changedMLGICVol) && (
              <tr id='group-bottom' style={(changedConfigs.length === 0) ? {'borderColor':'#ffffff'}:{}}>
                <td>Volatility</td>
                <td>{toPercentString(savedMLGICVol)}</td>
                <td>{toPercentString(tempMLGICVol)}</td>
              </tr>
              )}
            </tbody>
          </Table>
          {(changedConfigs.length > 0) && (<p style={{'color':'#808080', 'fontSize':'12px','margin':'10px 0px'}}>
            The following {changedConfigs.length} inputs have not been changed: {changedConfigs.toString()}.
          </p>)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-success" onClick={() => setShowSubmitSettingsModal(false)}>Cancel</Button>
          <Button variant="success" onClick={handleSaveConfig}>Save</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  // TODO style, make bigger than other modal and positioned in the middle
  function SubmitSettingsWarningModal(props) {
    const newestYear = availableRFStudies[0].year
    function handleSaveAnyways() {
      setShowSubmitSettingsWarningModal(false)
      setShowSubmitSettingsModal(true)
    }
    function handleSeeOperating() {
      setBypassRequest(true) // skip default get cashflow request that is fired when selectedYear is changed
      setShowSubmitSettingsWarningModal(false)
      handleSelectYear(newestYear)
      handleCalculate(newestYear)
    }
    // TODO complete buttons
    return (
      <Modal show={props.show} onHide={() => setShowSubmitSettingsWarningModal(false)} size="md" className="submit-settings-modal" dialogClassName="modal-width">
        <Modal.Header closeButton>
            <Modal.Title>Warning: Old Study Selected</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          By saving the currently selected configuration, all reserve fund studies will be processed, including the operating study (the newest one - {newestYear} for this building) which is used for the other app features i.e. Next Actions and Report Card.
          <br></br><br></br>
          You may want to see how these changes affect the operating study before saving them.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-success" onClick={() => setShowSubmitSettingsWarningModal(false)}>Cancel</Button>
          <Button variant="success" onClick={handleSeeOperating}>See Operating</Button>
          <Button variant="success" onClick={handleSaveAnyways}>Save Anyways</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  function InputsModal(props) {

    var title
    var text
    var mobileText
    var chart

    if (showInputModal === 1)
    {
      title = "Market Linked GICs"
      chart = chartOptionsPercentMLGIC
      mobileText = "This input slider allows users to control the percentage of their investment allocated to Market-Linked GICs compaired to regular GICs. Regular GICs offer fixed interest rates for predictable returns, while Market-Linked GICs combine the security of GICs with variable returns linked to market performance, providing potential for higher gains along with some level of risk."
      text = "This input slider allows users to control the percentage of their investment allocated to Market-Linked GICs compaired to regular GICs. Regular GICs offer fixed interest rates for predictable returns, while Market-Linked GICs combine the security of GICs with variable returns linked to market performance, providing potential for higher gains along with some level of risk. The graph below is a distribution of the total funds invested, showing the percentage invested in Market-Linked GICs and traditional GICs."
    }
    else if (showInputModal === 2)
    {
      title = "Min Cash Balance"
      chart = chartOptionsMinCashBalance
      mobileText = "This input slider allows users to control the minimum cash balance of the reserve fund. The minimum cash balance refers to the amount of cash that an investor holds as a reserve without being invested. The reserve fund typically maintains a cash balance above the set minimum amount; however, occasional optimizations may result in temporary dips below this threshold."
      text = "This input slider allows users to control the minimum cash balance of the reserve fund. The minimum cash balance refers to the amount of cash that an investor holds as a reserve without being invested. The reserve fund typically maintains a cash balance above the set minimum amount; however, occasional optimizations may result in temporary dips below this threshold. The graph below shows the minimum cash balance compared to the total cash balance of the reserve fund. "
    }
    else if (showInputModal === 3)
    {
      title = "Inflation Rate"
      chart = chartOptionsVolatilityCone
      mobileText = "These input sliders allow users to control the average and variance of the inflation rate. Average refers to the average value of the inflation rate, typically expressed as a percentage. Variance measures the degree of variation in the inflation rate, indicating the extent of fluctuations and unpredictability in its market performance."
      text = "These input sliders allow users to control the average and variance of the inflation rate. Average refers to the average value of the inflation rate, typically expressed as a percentage. Variance measures the degree of variation in the inflation rate, indicating the extent of fluctuations and unpredictability in its market performance. The graph bellow shows the effects variance and average have on the inflation rate."
    }
    else if (showInputModal === 4)
    {
      title = "GIC Interest Rate"
      chart = chartOptionsVolatilityCone
      mobileText = "These input sliders allow users to control the yield and volatility of the interest rate of the GICs. Yield refers to the income generated by an investment, typically expressed as a percentage, representing the return on the investment relative to its cost or current value. Volatility measures the degree of variation in the interest rate, indicating the extent of fluctuations and unpredictability in its market performance."
      text = "These input sliders allow users to control the yield and volatility of the interest rate of the GICs. Yield refers to the income generated by an investment, typically expressed as a percentage. Volatility measures the degree of variation in the interest rate, indicating the extent of fluctuations and unpredictability in its market performance. The graph bellow shows the effects variance and average have on the GIC interest rate."
    }
    else if (showInputModal === 5)
    {
      title = "Market Linked GIC"
      chart = chartOptionsVolatilityCone
      mobileText = "These input sliders allow users to control the yield and volatility of the Market Linked GICs. Yield refers to the income generated by an investment, typically expressed as a percentage, representing the return on the investment relative to its cost or current value. Volatility measures the degree of variation in the Market Linked GICs, indicating the extent of fluctuations and unpredictability in its market performance."
      text = "These input sliders allow users to control the yield and volatility of the Market Linked GICs. Yield refers to the income generated by an investment, typically expressed as a percentage. Volatility measures the degree of variation in the Market Linked GICs, indicating the extent of fluctuations and unpredictability in its market performance. The graph bellow shows the effects variance and average have on the Market Linked GIC interest rate."
    }

    return (
      <Modal className="config-modal totals" dialogClassName="modal-width" show={props.show} onHide={() => setShowInputModal(0)} size="lg"  centered>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!mobileScreen && text}
          {mobileScreen && mobileText}
          {!mobileScreen && (showInputModal === 1 || showInputModal === 2) && (
            <div className="flex" style={{'flexDirection':'column','width':'70%', 'margin':'auto'}}>
              <HighchartsReact highcharts={Highcharts} options={chart} />
            </div>
          )}
          { !mobileScreen && !(showInputModal === 1 || showInputModal === 2) && (
            <div className="flex" style={{'flexDirection':'column','width':'50%', 'margin':'auto'}}>
              <HighchartsReact highcharts={Highcharts} options={chart} />
            </div>
          )}
        </Modal.Body>
      </Modal>
    )
  }

  const configurationUnchanged = (
    tempRiskLevel == savedRiskLevel && 
    tempMinCash == savedMinCash && 
    tempInflationVol == savedInflationVol &&
    tempInflationYield == savedInflationYield &&
    tempGICVol == savedGICVol &&
    tempGICYield == savedGICYield &&
    tempMLGICVol == savedMLGICVol &&
    tempMLGICYield == savedMLGICYield
  )

  const handleInnerClick = (e) => {
    e.stopPropagation();
  }

  return (
    <div>
      {showOffCanvas && <OffCanvasCashflow show={showOffCanvas} selectedYear={selectedYear} selectedBuilding={auth.selectedBuilding} studyString={getStudyFromYear(selectedYear)} onSyncCharts={handleSyncCharts} onClose={handleCloseOffCanvas} inflation={dataInflationRate[0][1]} interest={dataInterestRate[0][1]} dataOpening={dataOpening} dataContribution={dataContribution} dataExpenditures={dataExpenditures} dataInterestIncome={dataInterestIncome} dataClosing={dataClosing} mouseOverYear={mouseOverYear} mouseOver={mouseOver} />}
      {showMissingData && <MissingData text="There are no Reserve Fund Studies linked to your account." pageSource="cashflow" />}
      {showProcessingData && <MissingData text="Please wait for your Reserve Fund Study to finish being processed. Refresh this page to check if it is." pageSource="cashflow" showUpload={false} />}
      {/* {showSelectStudyModal && <SelectStudyModal show={showSelectStudyModal} />} */}
      {showSelectStudyModal && <SelectStudyModal show={showSelectStudyModal} availableRFStudies={availableRFStudies} onSelectStudy={handleSelectStudy}/>}
      {showHeaderModal>0 && <HeaderModal show={showHeaderModal>0} />}
      {(showInputModal > 0) && <InputsModal show={showInputModal > 0}/>}
      {showOnboardingModal && <OnboardingLinePlotModal show={showOnboardingModal} onClose={handleCloseOnboarding} />}
      {dataExists && !showSelectStudyModal && (
        <div className={'content' + (showOffCanvas?' show-offcanvas':'')}>
          <div className='reserve-fund'>
            <div className='intro' id="CalculatorIntroTour">
              <div className='flex-center'>
              <h1 className='title'>Forecast Calculator</h1>
              </div>
              <p className='information'>
                Use our forecast calculator to play-out hypothetical market scenarios and investment strategies, allowing you to make better investment decisions based on your findings. <a target="_blank" href="https://www.verticalcityinstitute.com/post/vertical-city-toolkit-reserve-fund-forecasting">How this works »</a>
              </p>
            </div>

            <div className='flex-center study-dropdown' style={{'flexDirection': 'column', 'marginTop': '20px'}}>
              <OverlayTrigger placement="top" overlay={<Popover><PopoverBody>Select a Reserve Fund Study to be used for the forecast from the list of the uploaded studies for {auth.selectedBuilding}.</PopoverBody></Popover>}>
              <DropdownButton id='study-dropdown' title={getStudyFromYear(selectedYear)} size='lg'>
                {availableRFStudies.map((study) => <Dropdown.Item onClick={() => handleSelectYear(study.year)}>{study["year"]} Study by {study["engineer"]}</Dropdown.Item>)}
              </DropdownButton>
              </OverlayTrigger>
            </div>

            <div id="CalculatorForecastTour">
            <div className='header' id='projected-reserve-fund' style={{'paddingTop': '0px'}}>
              <div className='left'>
                <img src={logoBlue} alt="Blue Logo" width="60" height="60" />
                  <p className='section-header'>Reserve Fund Forecast</p>
              </div>
              <div className='divider-1'></div>
            </div>

            {cashflowBuffering && (
            <div className= 'flex-center'>
              <Spinner animation="border" style={{ width: "7rem", height: "7rem", margin: '60px 0px'}}/>
            </div>
            )} 

            {!cashflowBuffering && <div className='section-description'>This chart allows you to compare our Simulated benchmark forecast to a Custom simulation forecast. You can set the parameters of the Custom forecast by adjusting the sliders in the Calculation Inputs section below. When you are finished selecting your desired inputs, you can either click the 'Apply' button to update the chart or the 'Save' button which will save your selected inputs in the system to be used in the rest of the app, namely in the Dashboard page where your Next Actions (based on  your saved settings forecast) are displayed.</div>}

            <TotalsHeader />
            
            {!cashflowBuffering && (
            <div className='component' id='reserve-chart'>
              {recalculationBuffering && 
              <div className= 'flex-center' id="recalculating">
                <Spinner animation="border" style={{ width: "7rem", height: "7rem", margin: '60px 0px'}}/>
              </div>} 
              {!recalculationBuffering && 
              <div className="component-container" id='reserve-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                {dataVCRange && <HighchartsReact highcharts={Highcharts} options={chartOptionsReserve} />}
              </div>}
            </div>
            )}
            </div>

            {cashflowBuffering && (
            <div className= 'flex-center'>
              <Spinner animation="border" style={{ width: "7rem", height: "7rem", margin: '60px 0px'}}/>
            </div>
            )} 

            {showSubmitSettingsModal && <SubmitSettingsModal show={showSubmitSettingsModal}></SubmitSettingsModal>}
            {showSubmitSettingsWarningModal && <SubmitSettingsWarningModal show={showSubmitSettingsWarningModal}></SubmitSettingsWarningModal>}

            {!cashflowBuffering && (
            <div id="CalculatorInputsTour" className="inputs" style={{'marginTop':'15px'}}>
              <div className='section-description'>Strategy Inputs. These sliders control how the Reserve Fund is invested during the simulation process. The forecast configuration can be saved to your building settings by clicking the Save button below. Then the <a style={{'color': '#5d98d1', 'cursor': 'pointer'}} onClick={() => navigate('/app/dashboard')}>Dashboard</a> page will show the Next Actions (based on the saved configuration) in order to match the forecast that you see on this page.</div>
              <div className='flex' style={{"marginBottom": '7.5px', 'gap': '10px', 'flexWrap':'wrap'}}>
                <button className='input flex-grow-1 component-container space-between' style={{'color':'#000000'}} onClick={() => setShowInputModal(1)}>
                  <div>
                    <h5>Market Linked GICs</h5>
                    <p id='range'>(0-100%)</p>
                  </div>
                  <div className='slider' onClick={handleInnerClick}>
                    <RangeSlider value={tempRiskLevel} tooltip={'off'} onChange={(e) => setTempRiskLevel(e.target.value)} min={0} max={10} />
                    <div className='input space-between'>
                      <div></div>
                      <p>{(tempRiskLevel == savedRiskLevel) ? "(saved) ": ""}{(tempRiskLevel == 0) ? "0%" : tempRiskLevel.toString() + "0%"}</p>
                    </div>
                  </div>
                </button>
                <button className='input flex-grow-1 component-container space-between' style={{'color':'#000000'}} onClick={() => setShowInputModal(2)}>
                  <div>
                    <h5>Min Cash Balance</h5>
                    <p id='range'>(0-1mil.)</p>
                  </div>
                  <div className='slider' onClick={handleInnerClick}>
                    <RangeSlider value={tempMinCash} tooltip={'off'} onChange={(e) => setTempMinCash(e.target.value)} min={0} max={1000000} step={100000} />
                    <div className='input space-between'>
                      <p></p>
                      <p>{(tempMinCash == savedMinCash) ? "(saved) ": ""}{formatAmount(tempMinCash, 0)}</p>
                    </div>
                  </div>
                </button>
                {historical && (
                <div className='input flex-grow-1 component-container space-between'>
                  <OverlayTrigger placement="left" overlay={<Popover><PopoverBody>By default, the {dataMaxYear - dataMinYear + 1} Year Window is set to the currently selected Reserve Fund Study's window</PopoverBody></Popover>}>
                    <div>
                      <h5>{dataMaxYear - dataMinYear + 1} Year Window</h5>
                      <p id='range'>(1980-{dataMaxYear})</p>
                    </div>
                  </OverlayTrigger>
                  <div className='slider'>
                    <RangeSlider value={tempWindow} tooltip={'off'} onChange={(e) => setTempWindow(e.target.value)} min={1980} max={dataMinYear} step={1} />
                    <div className='input space-between'>
                      <p></p>
                      <p>{(tempWindow == dataMinYear) ? "(saved) ": ""}{tempWindow + " to "}{Number(tempWindow) + (dataMaxYear - dataMinYear)}</p>
                    </div>
                  </div>
                </div>
                )}
              </div>
              <div className='section-description'>Market Assumptions. These sliders are used to describe the assumed market conditions of the unknown future period that the Reserve Fund is being invested. Consider that a regular Reserve Fund Study will assume a fixed interest and inflation rate for the entire unknown period. Our calculator enables a more accurate forecast by evolving the assumed rates.</div>
              <div className='flex' style={{'gap': '10px','flexWrap':'wrap'}}>
                <button className='flex-grow-1 component-container' style={{'color':'#000000'}} id={historical && (Number(tempWindow) + dataMaxYear - dataMinYear < currentYear) ? 'disabled' : ''} onClick={() => setShowInputModal(3)}>
                  <div className='input space-between'>
                    <h5 id='bold'>Inflation Rate</h5>
                    {historical && (Number(tempWindow) + dataMaxYear - dataMinYear > currentYear) && <p style={{'margin': 'auto 0px'}}>Applied to {currentYear}-{Number(tempWindow) + dataMaxYear - dataMinYear} ({Number(tempWindow) + dataMaxYear - dataMinYear - currentYear} Years)</p>}
                  </div>
                  <div className='input space-between'>
                    <div>
                      <h5>Average</h5>
                      <p id='range'>(1-10%)</p>
                    </div>
                    <div className='slider' id={historical && (Number(tempWindow) + dataMaxYear - dataMinYear < dataMinYear) ? 'disabled' : ''} onClick={handleInnerClick}>
                      <RangeSlider value={tempInflationYield} tooltip={'off'} onChange={(e) => setTempInflationYield(e.target.value)} min={0.01} max={0.1} step={0.001} disabled={historical && (Number(tempWindow) + dataMaxYear - dataMinYear < currentYear)} />
                      <div className='input space-between'>
                        {!mobileScreen && (
                          <div style={{'marginTop': '-9px'}}>
                            <button className='increment' id='left' onClick={() => {(Number(tempInflationYield) > 0.01) ? setTempInflationYield(Number(tempInflationYield)-0.001):setTempInflationYield(tempInflationYield)}} disabled={Number(tempInflationYield) <= 0.01}>◀</button>
                            <button className='increment' id='right' onClick={() => {(Number(tempInflationYield) < 0.10) ? setTempInflationYield(Number(tempInflationYield)+0.001):setTempInflationYield(tempInflationYield)}} disabled={Number(tempInflationYield) >= 0.10}>▶</button>
                          </div>
                        )}
                        {mobileScreen && <p></p>}
                        <p>{(tempInflationYield == savedInflationYield) ? "(saved) ": ""}{toPercentString(tempInflationYield)}</p>
                      </div>
                    </div>
                  </div>
                  <div className='input space-between'>
                    <div>
                      <h5>Variance</h5>
                      <p id='range'>(0-10%)</p>
                    </div>
                    <div className='slider' id={historical && (Number(tempWindow) + dataMaxYear - dataMinYear < currentYear) ? 'disabled' : ''} onClick={handleInnerClick}>
                      <RangeSlider value={tempInflationVol} tooltip={'off'} onChange={(e) => setTempInflationVol(e.target.value)} min={0} max={0.1} step={0.001} disabled={historical && (Number(tempWindow) + dataMaxYear - dataMinYear < currentYear)} />
                      <div className='input space-between' onClick={handleInnerClick}>
                        {!mobileScreen && (
                          <div style={{'marginTop': '-9px'}}>
                            <button className='increment' id='left' onClick={() => {(Number(tempInflationVol) > 0) ? setTempInflationVol(Number(tempInflationVol)-0.001):setTempInflationVol(tempInflationVol)}} disabled={Number(tempInflationVol) <= 0}>◀</button>
                            <button className='increment' id='right' onClick={() => {(Number(tempInflationVol) < 0.10) ? setTempInflationVol(Number(tempInflationVol)+0.001):setTempInflationVol(tempInflationVol)}} disabled={Number(tempInflationVol) >= 0.10}>▶</button>
                          </div>
                        )}
                        {mobileScreen && <p></p>}
                        <p>{(tempInflationVol == savedInflationVol) ? "(saved) ": ""}{toPercentString(tempInflationVol)}</p>
                      </div>
                    </div>
                  </div>
                </button>
                <button className='flex-grow-1 component-container' style={{'color':'#000000'}} id={historical && (Number(tempWindow) + dataMaxYear - dataMinYear < currentYear) ? 'disabled' : ''} onClick={() => setShowInputModal(4)}>
                  <div className='input space-between' >
                    <h5 id='bold'>GIC Interest Rate</h5>
                    {historical && (Number(tempWindow) + dataMaxYear - dataMinYear > currentYear) && <p style={{'margin': 'auto 0px'}}>Applied to {currentYear}-{Number(tempWindow) + dataMaxYear - dataMinYear} ({Number(tempWindow) + dataMaxYear - dataMinYear - currentYear} Years)</p>}
                  </div>
                  <div className='input space-between'>
                    <div>
                      <h5>Yield</h5>
                      <p id='range'>(1-10%)</p>
                    </div>
                    <div className='slider' id={historical && (Number(tempWindow) + dataMaxYear - dataMinYear < currentYear) ? 'disabled' : ''} onClick={handleInnerClick}>
                      <RangeSlider value={tempGICYield} tooltip={'off'} onChange={(e) => setTempGICYield(e.target.value)} min={0.01} max={0.1} step={0.001} disabled={historical && (Number(tempWindow) + dataMaxYear - dataMinYear < currentYear)} />
                      <div className='input space-between'>
                        {!mobileScreen && (
                          <div style={{'marginTop': '-9px'}}>
                            <button className='increment' id='left' onClick={() => {(Number(tempGICYield) > 0.01) ? setTempGICYield(Number(tempGICYield)-0.001):setTempGICYield(tempGICYield)}} disabled={Number(tempGICYield) <= 0.01}>◀</button>
                            <button className='increment' id='right' onClick={() => {(Number(tempGICYield) < 0.10) ? setTempGICYield(Number(tempGICYield)+0.001):setTempGICYield(tempGICYield)}} disabled={Number(tempGICYield) >= 0.10}>▶</button>
                          </div>
                        )}
                        {mobileScreen && <p></p>}
                        <p>{(tempGICYield == savedGICYield) ? "(saved) ": ""}{toPercentString(tempGICYield)}</p>
                      </div>
                    </div>
                  </div>
                  <div className='input space-between'>
                    <div>
                      <h5>Volatility</h5>
                      <p id='range'>(0-10%)</p>
                    </div>
                    <div className='slider' id={historical && (Number(tempWindow) + dataMaxYear - dataMinYear < currentYear) ? 'disabled' : ''} onClick={handleInnerClick}>
                      <RangeSlider value={tempGICVol} tooltip={'off'} onChange={(e) => setTempGICVol(e.target.value)} min={0} max={0.1} step={0.001} disabled={historical && (Number(tempWindow) + dataMaxYear - dataMinYear < currentYear)} />
                      <div className='input space-between'>
                        {!mobileScreen && (
                          <div style={{'marginTop': '-9px'}}>
                            <button className='increment' id='left' onClick={() => {(Number(tempGICVol) > 0) ? setTempGICVol(Number(tempGICVol)-0.001):setTempGICVol(tempGICVol)}} disabled={Number(tempGICVol) <= 0}>◀</button>
                            <button className='increment' id='right' onClick={() => {(Number(tempGICVol) < 0.10) ? setTempGICVol(Number(tempGICVol)+0.001):setTempGICVol(tempGICVol)}} disabled={Number(tempGICVol) >= 0.10}>▶</button>
                          </div>
                        )}
                        {mobileScreen && <p></p>}
                        <p>{(tempGICVol == savedGICVol) ? "(saved) ": ""}{toPercentString(tempGICVol)}</p>
                      </div>
                    </div>
                  </div>
                </button>
                <button className='flex-grow-1 component-container' style={{'color':'#000000'}} id={historical && (Number(tempWindow) + dataMaxYear - dataMinYear < currentYear) ? 'disabled' : ''} onClick={() => setShowInputModal(5)}>
                  <div className='input space-between'>
                    <h5 id='bold'>Market Linked GIC</h5>
                    {historical && (Number(tempWindow) + dataMaxYear - dataMinYear > currentYear) && <p style={{'margin': 'auto 0px'}}>Applied to {currentYear}-{Number(tempWindow) + dataMaxYear - dataMinYear} ({Number(tempWindow) + dataMaxYear - dataMinYear - currentYear} Years)</p>}
                  </div>
                  <div className='input space-between'>
                    <div>
                      <h5>Yield</h5>
                      <p id='range'>(1-10%)</p>
                    </div>
                    <div className='slider' id={historical && (Number(tempWindow) + dataMaxYear - dataMinYear < currentYear) ? 'disabled' : ''} onClick={handleInnerClick}>
                      <RangeSlider value={tempMLGICYield} tooltip={'off'} onChange={(e) => setTempMLGICYield(e.target.value)} min={0.01} max={0.1} step={0.001} disabled={historical} />
                      <div className='input space-between'>
                        {!mobileScreen && (
                          <div style={{'marginTop': '-9px'}}>
                            <button className='increment' id='left' onClick={() => {(Number(tempMLGICYield) > 0.01) ? setTempMLGICYield(Number(tempMLGICYield)-0.001):setTempMLGICYield(tempMLGICYield)}} disabled={Number(tempMLGICYield) <= 0.01}>◀</button>
                            <button className='increment' id='right' onClick={() => {(Number(tempMLGICYield) < 0.10) ? setTempMLGICYield(Number(tempMLGICYield)+0.001):setTempMLGICYield(tempMLGICYield)}} disabled={Number(tempMLGICYield) >= 0.10}>▶</button>
                          </div>
                        )}
                        {mobileScreen && <p></p>}
                        <p>{(tempMLGICYield == savedMLGICYield) ? "(saved) ": ""}{toPercentString(tempMLGICYield)}</p>
                      </div>
                    </div>
                  </div>
                  <div className='input space-between'>
                    <div>
                      <h5>Volatility</h5>
                      <p id='range'>(1-20%)</p>
                    </div>
                    <div className='slider' id={historical && (Number(tempWindow) + dataMaxYear - dataMinYear < currentYear) ? 'disabled' : ''} onClick={handleInnerClick}>
                      <RangeSlider value={tempMLGICVol} tooltip={'off'} onChange={(e) => setTempMLGICVol(e.target.value)} min={0.01} max={0.2} step={0.001} disabled={historical} />
                      <div className='input space-between'>
                        {!mobileScreen && (
                          <div style={{'marginTop': '-9px'}}>
                            <button className='increment' id='left' onClick={() => {(Number(tempMLGICVol) > 0.01) ? setTempMLGICVol(Number(tempMLGICVol)-0.001):setTempMLGICVol(tempMLGICVol)}} disabled={Number(tempMLGICVol) <= 0.01}>◀</button>
                            <button className='increment' id='right' onClick={() => {(Number(tempMLGICVol) < 0.20) ? setTempMLGICVol(Number(tempMLGICVol)+0.001):setTempMLGICVol(tempMLGICVol)}} disabled={Number(tempMLGICVol) >= 0.20}>▶</button>
                          </div>
                        )}
                        {mobileScreen && <p></p>}
                        <p>{(tempMLGICVol == savedMLGICVol) ? "(saved) ": ""}{toPercentString(tempMLGICVol)}</p>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
              <div style={{'display': 'flex', 'flexDirection': 'row-reverse', 'marginTop': '10px', 'gap': '6px', 'whiteSpace':'nowrap', 'position':'relative','flexWrap':'wrap'}}>
                <Button id="CalculatorSaveTour" className="header-button" variant="success" onClick={handleSaveConfiguration} style={{'maxWidth':'38vw'}} disabled={historical || configurationUnchanged}>Save Configuration</Button>
                <Button id="CalculatorCalculateTour" className="header-button" variant="outline-success" onClick={handleCalculate}>Calculate</Button>
                  {/* <div style={{'paddingTop':'0px', 'position':'absolute','left':'0'}}>
                    <ToggleButton
                      type="checkbox"
                      variant="outline-success"
                      checked={historical}
                      style={historical ? {'color': 'white','width':'125px','maxWidth':'24vw'}:{'color':'#A7B661','width':'125px','maxWidth':'24vw'}}
                      onClick={() => setHistorical(!historical)}
                      //disabled={true}
                    >
                      Historical
                    </ToggleButton>
                  </div> */}
              </div>
            </div>
            )}

            <div id="CalculatorDetailsTour">
            <div className='header' id='details' style={{'paddingTop': '0px'}}>
              <div className='left'>
                <img src={logoYellow} alt="Green Logo" width="60" height="60" />
                <p className='section-header'>{detailCharts ? "Reserve Fund Study Details" : "Custom Simulation Details"}</p>
              </div>
              <div className='divider-3'></div>
              {savedConfig && (
                <div className='switch'>
                  <Form>
                      <Form.Check
                        type="switch"
                        id="yellow"
                        disabled={!savedConfig}
                        label="See RFS Charts"
                        onClick={() => setDetailCharts(!detailCharts)}
                      />
                  </Form>
                </div>
              )}
            </div>

            {cashflowBuffering && (
            <div className= 'flex-center'>
              <Spinner animation="border" style={{ width: "7rem", height: "7rem", margin: '60px 0px'}}/>
            </div>
            )} 

            {!cashflowBuffering && (<>
            {detailCharts && <div className='section-description'>The following charts provide details of the currently selected Reserve Fund Study. Our system builds from the assumptions in these charts to get the forecasts above. The left chart shows projected cashflows between owner contributions and building expenditures. The right chart shows projected market assumptions and contribution increases.</div>}
            {!detailCharts && <div className='section-description'>The following charts provide details of the configured custom simulation above. The left chart shows the composion of Cash, GIC and MLGIC of the Reserve Fund based on a percentage. The right chart shows the calculated interest and inflation rates calculated using a probability density function which are used to simulate how investments preform.</div>}
            {detailCharts && (
            <div className='component space-between detail-charts'>
              <div className='component-container' id='detail-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                <HighchartsReact highcharts={Highcharts} options={chartOptionsExpenditures} />
              </div>
              <div className='component-container' id='detail-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                <HighchartsReact highcharts={Highcharts} options={chartOptionsRate} />
              </div>
            </div>
            )}
            {!detailCharts && (
            <div className='component space-between detail-charts'>
              <div className='component-container' id='detail-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                <HighchartsReact highcharts={Highcharts} options={chartOptionsComposition} />
              </div>
              <div className='component-container' id='detail-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                <HighchartsReact highcharts={Highcharts} options={chartOptionsRateCalcs} />
              </div>
            </div>
            )}
            </>)}
            </div>

            <OverlayTrigger placement="left" overlay={<Popover><PopoverBody>Learn how we made these charts with your cashflow table</PopoverBody></Popover>}>
            <button onClick={() => setShowOnboardingModal(!showOnboardingModal)} className='onboarding-buttom' style={{position: 'fixed', bottom: '85px', right: '20px'}}>
              <img src={greyQuestion} alt="Yellow Question Mark" width="50" height="50" />
            </button>
            </OverlayTrigger>

            <OverlayTrigger placement="left" overlay={<Popover><PopoverBody>Learn more about this page</PopoverBody></Popover>}>
            <button id="CalculatorHelpTour" onClick={() => setShowOffCanvas(!showOffCanvas)} className='understand-page-button' style={{position: 'fixed', bottom: '20px', right: '20px'}}>
                  <img src={greyQuestion} alt="Blue Question Mark" width="50" height="50" />
            </button>
            </OverlayTrigger>

            <div className='disclaimer'>
              Please note that Vertical City Toolkit is intended for informational purposes only and should not be construed as investment advice. 
              Condominium Boards should seek the advice of qualified professionals when making investment decisions for their Reserve Funds.
              Vertical City Toolkit does not provide financial, legal, tax or investment advice or recommendations.
            </div>

          </div>
        </div>
      )}
    </div>
  )
}
