import { Form, Button } from "react-bootstrap";
import { useState } from 'react';
import axios from 'axios';

// bootstrap
import Alert from 'react-bootstrap/Alert'

export default function ForgotPassword() {
  
  const [resetAttempted, setResetAttempted] = useState(false)
  const [attemptedEmail, setAttemptedEmail] = useState("")

  function handleSubmit(event) {
    event.preventDefault();

    // prevent api request if no email or password are given
    const email = event.target[0].value;
    if (email.length === 0) return null

    const form_data = {"email": event.target[0].value}
    const url_query = `${process.env.REACT_APP_FLASK_IP}/forgotpassword`

    axios.defaults.withCredentials = true
    axios.post(url_query, form_data)
      .then(function (resp) {
        setResetAttempted(true)
        setAttemptedEmail(email)
      })
  };

  return (
    <div className="ForgotPassword">
      <Form onSubmit={handleSubmit}>
        <h2>Reset your password</h2>
        <p>Enter the email address associated with your account to reset your password.</p>
        <Form.Group size="lg" controlId="email">
          <Form.Control 
            type="email" 
            placeholder="email"
          />
        </Form.Group>
        {(resetAttempted) && (<Alert variant='success'>If an account exists with your email address, <b>{attemptedEmail}</b>, we just sent you an email with instructions to reset your password.</Alert>)}
        <Button block size="lg" type="submit">
          {resetAttempted !== "" ? "Resend email" : "Continue"}
        </Button>
      </Form>
    </div>
  );
}
